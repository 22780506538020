import styled from "styled-components/macro";
import { motion } from "framer-motion";

export const Container = styled(motion.div)`
	position: relative;
	width: 60%;
	height: 60%;
	border-radius: 5px;
	background-color: #3c3940;
	box-shadow: 0 0 10px black;
	z-index: 11;
`;

export const Header = styled.h2`
	color: black;
	padding-left: 20px;
	padding-top: 5px;
`;

export const Label = styled.p`
	color: white;
	font-size: 20px;
`;

export const Navbar = styled.div`
	width: 100%;
	height: 15%;
	border-radius: 5px 5px 0 0;
	background-color: white;
	box-shadow: 0 1px 10px rgba(0, 0, 0, 0.5);
	overflow: hidden;
	display: flex;
	align-items: center;
`;

export const Button = styled(motion.button)`
	border: none;
	background-color: transparent;
	margin-left: auto;
	margin-right: 20px;

	&:focus {
		outline: none;
	}
`;

export const TextArea = styled.textarea`
	min-height: 200px;
	font-family: "Helvetica";
	width: 90%;
	margin-top: 20px;
	resize: none;
	box-sizing: border-box;
	padding: 10px;
	background-color: #29252b;
	border-radius: 3px;
	border: solid 1px #455059;
	color: white;

	::-webkit-input-placeholder {
		font-style: italic;
	}

	&:focus {
		outline: none;
	}
`;

export const SendButton = styled.button`
	height: 40px;
	margin-top: 20px;
	padding: 1vh 1vw 1vh;
	border-radius: 3px;
	border: none;
	transition: 0.3s;

	&:focus {
		outline: none;
	}

	&:hover {
		color: #efefef;
		background-color: #15a4f7;
	}
`;

export const Line = styled.div`
	background-color: #455059;
	width: 90%;
	height: 1px;
`;

export const Input = styled.input`
	font-family: "Helvetica";
	padding: 5px 5px 5px;
	background-color: #29252b;
	border-radius: 3px;
	border: solid 1px #455059;
	color: white;
	width: 90%;
	height: 20px;

	::-webkit-input-placeholder {
		font-style: italic;
	}

	&:focus {
		outline: none;
	}
`;
