import React, { useContext } from "react";
import { Account, Mail } from "../components";
import { FaSignOutAlt } from "react-icons/fa";
import userLogo from "../images/userLogo.png";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { Formik, Field, Form } from "formik";
import * as Yup from "yup";

import { UserContext } from "../context/UserContext";
import { useHistory } from "react-router-dom";

export default function MyAccount({ setCurrentNav }) {
	const history = useHistory();
	const { logout, user, changeRealName, changePassword, refreshInfo } = useContext(UserContext);

	const notifySuccess = () =>
		toast.success("İşlem başarılı!", {
			position: "bottom-center",
			autoClose: 3000,
			hideProgressBar: false,
			closeOnClick: true,
			pauseOnHover: true,
			draggable: true,
			progress: undefined,
		});

	const notifyError = () =>
		toast.error("Bir hata oluştu!", {
			position: "bottom-center",
			autoClose: 3000,
			hideProgressBar: false,
			closeOnClick: true,
			pauseOnHover: true,
			draggable: true,
			progress: undefined,
		});

	const handleNameSubmit = (data, { setSubmitting, setErrors, resetForm }) => {
		setSubmitting(true);
		changeRealName(data.realName)
			.then((data) => {
				refreshInfo();
				if (data.success) {
					resetForm({ realName: "" });
					refreshInfo();
					notifySuccess();
				} else {
					resetForm({ realName: "" });
					setErrors(data);
					notifyError();
				}
			})
			.catch((error) => {
				alert(error);
			});

		setSubmitting(false);
	};

	const handlePasswordSubmit = (data, { setSubmitting, setErrors, resetForm }) => {
		setSubmitting(true);
		changePassword(data.oldPassword, data.newPassword)
			.then((data) => {
				if (data.success) {
					resetForm({ oldPassword: "", newPassword: "", confirmPassword: "" });
					refreshInfo();
					notifySuccess();
				} else {
					resetForm({ oldPassword: "", newPassword: "", confirmPassword: "" });
					setErrors(data);
					notifyError();
				}
			})
			.catch((error) => {
				alert(error);
			});

		setSubmitting(false);
	};

	const NameSchema = Yup.object().shape({
		realName: Yup.string().min(3, "Ad veya soyad 2 karakterden az olamaz").required("Devam etmek için isminizi giriniz"),
	});

	const PasswordSchema = Yup.object().shape({
		oldPassword: Yup.string(),
		newPassword: Yup.string()
			.required("Şifre girmeniz zorunludur")
			.min(8, "Şifreniz çok kısa - şifreniz en az 8 karakterden oluşmalı"),
		confirmPassword: Yup.string().oneOf([Yup.ref("newPassword"), null], "Şifreniz eşleşmeli"),
	});

	const handleLogout = () => {
		history.push("/");
		setCurrentNav("");
		logout();
	};

	return (
		<>
			{user ? (
				<Account>
					<Account.MainWrapper>
						<Account.Navbar>
							<div className="navbar-wrapper">
								<div style={{ display: "flex", flexDirection: "column" }}>
									<div style={{ display: "flex", alignItems: "center" }}>
										<img src={userLogo} alt="user-logo" style={{ width: "72px", paddingRight: "10px" }} />
										<div
											style={{
												display: "flex",
												flexDirection: "column",
												marginLeft: "10px",
											}}
										>
											<Account.Label size="28px">Hesap Bilgileri</Account.Label>

											<Account.Label
												size="16px"
												style={{
													color: "white",
													marginTop: "5px",
													display: "flex",
													alignItems: "center",
													textShadow: "0 2px 2px black",
												}}
											>
												{user.name}
											</Account.Label>
										</div>
									</div>
								</div>

								<Account.Logout onClick={handleLogout}>
									Çıkış Yap <FaSignOutAlt style={{ marginLeft: "10px" }} size="1.3em" />
								</Account.Logout>
							</div>
						</Account.Navbar>
						<Account.InfoWrapper>
							<div className="row-wrapper">
								<Account.Label size="20px">Ad, Soyad Değiştir</Account.Label>
							</div>
							<div style={{ display: "flex", justifyContent: "center" }}>
								<Mail.Line />
							</div>
							<Formik
								initialValues={{ realName: "" }}
								validationSchema={NameSchema}
								onSubmit={handleNameSubmit}
								validateOnChange={true}
								validateOnBlur={false}
							>
								{({ isSubmitting, errors, touched }) => (
									<Form spellCheck={false}>
										<div className="row-wrapper">
											<Account.Label style={{ width: "200px" }} size="16px">
												Ad, Soyad:
											</Account.Label>
											<Field
												style={{ width: "100%", marginLeft: "auto" }}
												spellCheck="false"
												placeholder={user.realName}
												name="realName"
												type="realName"
												as={Account.InputField}
											/>
											<Account.Button
												type="submit"
												disabled={isSubmitting}
												button={"isim-guncelle"}
												style={{ marginBottom: 0, marginLeft: "10px" }}
											>
												Güncelle
											</Account.Button>
										</div>
										<div style={{ marginLeft: "30px" }}>
											{errors.realName && touched.realName ? (
												<div style={{ color: "red" }}>{errors.realName}</div>
											) : null}
										</div>
									</Form>
								)}
							</Formik>

							<div className="row-wrapper">
								<Account.Label size="20px">Şifre Değiştir</Account.Label>
							</div>
							<div style={{ display: "flex", justifyContent: "center" }}>
								<Mail.Line />
							</div>
							<Formik
								initialValues={{ oldPassword: "", newPassword: "", confirmPassword: "" }}
								validationSchema={PasswordSchema}
								onSubmit={handlePasswordSubmit}
								validateOnChange={true}
								validateOnBlur={false}
							>
								{({ isSubmitting, errors, touched }) => (
									<Form spellCheck={false}>
										<div className="row-wrapper">
											<Account.Label size="16px">Eski Şifre:</Account.Label>
											<Field
												spellCheck="false"
												name="oldPassword"
												type="password"
												as={Account.InputField}
											/>
										</div>
										<div style={{ display: "flex", justifyContent: "flex-eand", marginRight: "40px" }}>
											{errors.error ? <div style={{ color: "red" }}>{errors.error}</div> : null}
										</div>
										<div className="row-wrapper">
											<Account.Label size="16px">Yeni Şifre:</Account.Label>
											<Field
												spellCheck="false"
												name="newPassword"
												type="password"
												as={Account.InputField}
											/>
										</div>
										<div style={{ display: "flex", justifyContent: "flex-end", marginRight: "40px" }}>
											{errors.newPassword && touched.newPassword ? (
												<div style={{ color: "red" }}>{errors.newPassword}</div>
											) : null}
										</div>
										<div className="row-wrapper">
											<Account.Label size="16px">Yeni Şifre Tekrar:</Account.Label>
											<Field
												spellCheck="false"
												name="confirmPassword"
												type="password"
												as={Account.InputField}
											/>
										</div>
										<div style={{ display: "flex", justifyContent: "flex-end", marginRight: "40px" }}>
											{errors.confirmPassword && touched.confirmPassword ? (
												<div style={{ color: "red" }}>{errors.confirmPassword}</div>
											) : null}
										</div>

										<div className="text-center" style={{ marginTop: 0 }}>
											<Account.Button type="submit" disabled={isSubmitting} button={"sifre-guncelle"}>
												Güncelle
											</Account.Button>
										</div>
										<ToastContainer
											position="bottom-center"
											autoClose={3000}
											hideProgressBar={false}
											newestOnTop
											closeOnClick
											rtl={false}
											pauseOnFocusLoss
											draggable
											pauseOnHover
										/>
									</Form>
								)}
							</Formik>
						</Account.InfoWrapper>
					</Account.MainWrapper>
				</Account>
			) : null}
		</>
	);
}
