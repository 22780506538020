import styled from "styled-components/macro";

export const RowCenter = styled.div`
	display: flex;
	justify-content: center;
	width: 100%;
`;

export const LeftAlign = styled.div`
	margin-right: auto;
	margin-left: 3vw;
	margin-top: 5px;
	max-width: 50%;
`;

export const RightAlign = styled.div`
	margin-left: auto;
	margin-right: 3vw;
	margin-top: 5px;
	max-width: 50%;
`;
