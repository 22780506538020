import React, { useContext, useState } from "react";
import { motion } from "framer-motion";
import { UserContext } from "../context/UserContext";
import { NavBar, Invitation } from "../components";
import { useHistory } from "react-router-dom";
import { Signin, Signup } from "../pages";
import userLogo from "../images/userLogo.png";
import "./style.css";

const variants = {
  closed: {
    background:
      "linear-gradient(180deg, rgb(41, 39, 40) 100%, rgb(235, 57, 38) 101%)",
    width: "10vw",
    height: "9vh",
    padding: "1vh",
  },
  open: {
    background:
      "linear-gradient(180deg, rgb(41, 39, 40) -5%, rgb(235, 57, 38) 0%)",
    width: "10vw",
    height: "9vh",
    padding: "1vh",
  },
};

const kirmiziVariant = {
  closed: {
    background:
      "linear-gradient(180deg, rgb(41, 39, 40) 100%, rgb(235, 57, 38) 101%)",
    width: "10vw",
    height: "9vh",
    padding: "1vh",
    color: "white",
  },
  open: {
    background:
      "linear-gradient(180deg, rgb(41, 39, 40) -5%, rgb(235, 57, 38) 0%)",
    width: "10vw",
    height: "9vh",
    padding: "1vh",
    color: "white",
  },
};

const transition = { duration: 0.4, ease: [0.43, 0.13, 0.23, 0.96] };

export default function NavBarContainer({
  currentNav,
  setCurrentNav,
  setSignInClick,
  setSignUpClick,
}) {
  const { logged } = useContext(UserContext);

  const history = useHistory();

  return (
    <>
      {/* <Invitation>OnlineEtkinligimizeDavetlisiniz</Invitation> */}
      <NavBar>
        <NavBar.Logo
          onClick={() => {
            setSignUpClick(false);
            setSignInClick(false);
            history.push("/");
            setCurrentNav("");
          }}
        />
        <NavBar.ItemContainer>
          <NavBar.Label
            initial={{
              background:
                "linear-gradient(180deg, rgb(41, 39, 40) 100%, rgb(0,212,255) 101%)",
              width: "10vw",
              height: "9vh",
              padding: "1vh",
              color: "white",
            }}
            animate={currentNav === "" ? "open" : "closed"}
            variants={variants}
            transition={transition}
            onClick={() => {
              setSignUpClick(false);
              setSignInClick(false);
              history.push("#");
              setCurrentNav("");
            }}
          >
            GİRİŞİMLER
          </NavBar.Label>
          {/*
					<NavBar.Label
						initial={{
							background: "linear-gradient(180deg, rgb(41, 39, 40) 100%, rgb(0,212,255) 101%)",
							width: "10vw",
							height: "9vh",
							padding: "1vh",
							color: "white",
						}}
						animate={currentNav === "canli" ? "open" : "closed"}
						variants={variants}
						transition={transition}
						onClick={() => {
							setSignUpClick(false);
							setSignInClick(false);
							history.push("/sahne");
							setCurrentNav("canli");
						}}
					>
						BIG BANG SAHNE
					</NavBar.Label>
					<NavBar.Label
						initial={{
							background: "linear-gradient(180deg, rgb(41, 39, 40) 100%, rgb(0,212,255) 101%)",
							width: "10vw",
							height: "9vh",
							padding: "1vh",
							color: "white",
						}}
						animate={currentNav === "girisim" ? "open" : "closed"}
						variants={variants}
						transition={transition}
						onClick={() => {
							setSignUpClick(false);
							setSignInClick(false);
							history.push("/girisimler/standlar");
							setCurrentNav("girisim");
						}}
					>
						GİRİŞİMLER
					</NavBar.Label>

					<NavBar.Label
						initial={{
							background: "linear-gradient(180deg, rgb(41, 39, 40) 100%, rgb(0,212,255) 101%)",
							width: "10vw",
							height: "9vh",
							padding: "1vh",
							color: "white",
						}}
						animate={currentNav === "kirmizi" ? "open" : "closed"}
						variants={kirmiziVariant}
						transition={transition}
						onClick={() => {
							setSignUpClick(false);
							setSignInClick(false);
							history.push("/mavi-mikrofon");
							setCurrentNav("kirmizi");
						}}
					>
						MAVİ MİKROFON
					</NavBar.Label>

					<NavBar.Label
						initial={{
							background: "linear-gradient(180deg, rgb(41, 39, 40) 100%, rgb(0,212,255) 101%)",
							width: "10vw",
							height: "9vh",
							padding: "1vh",
							color: "white",
						}}
						animate={currentNav === "odul" ? "open" : "closed"}
						variants={variants}
						transition={transition}
						style={{ fontSize: "1.3vw" }}
						onClick={() => {
							setSignUpClick(false);
							setSignInClick(false);
							history.push("/odul-ve-yatirimlar");
							setCurrentNav("odul");
						}}
					>
						ÖDÜL VE YATIRIMLAR
					</NavBar.Label> */}

          {/* <NavBar.Label
							initial={{
								background: "linear-gradient(180deg, rgb(41, 39, 40) 100%, rgb(0,212,255) 101%)",
								width: "150px",
								height: "9vh",
								padding: "1vh",
								color: "white",
							}}
							animate={currentNav === "chat" ? "open" : "closed"}
							variants={variants}
							transition={transition}
							onClick={() => {
								setSignUpClick(false);
								history.push("/chat");
								setCurrentNav("chat");
							}}
						>
							CHAT
						</NavBar.Label> */}

          {/* <NavBar.Label
						initial={{
							background: "linear-gradient(180deg, rgb(41, 39, 40) 100%, rgb(0,212,255) 101%)",
							width: "10vw",
							height: "9vh",
							padding: "1vh",
							color: "white",
						}}
						animate={currentNav === "signup" ? "open" : "closed"}
						variants={variants}
						transition={transition}
						onClick={() => {
							logged ? history.push("/my-account") : setSignUpClick(true);
							setCurrentNav("signup");
						}}
					>
						<div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
							{logged ? (
								<img
									src={userLogo}
									alt="logo"
									style={{
										transform: "skew(15deg)",
										width: "70px",
										borderRadius: "50%",
										border: "solid 2px white",
									}}
								/>
							) : null}
							{logged ? "" : "ETKİNLİK KAYIT"}
						</div>
					</NavBar.Label>
					{!logged && (
						<NavBar.Label
							initial={{
								background: "linear-gradient(180deg, rgb(41, 39, 40) 100%, rgb(0,212,255) 101%)",
								width: "10vw",
								height: "9vh",
								padding: "1vh",
								color: "white",
							}}
							animate={currentNav === "signin" ? "open" : "closed"}
							variants={variants}
							transition={transition}
							onClick={() => {
								logged ? history.push("/my-account") : setSignInClick(true);
								setCurrentNav("signin");
							}}
						>
							{logged ? "HESABIM" : "GİRİŞ"}
						</NavBar.Label>
					)} */}
        </NavBar.ItemContainer>
      </NavBar>
    </>
  );
}
