import React, { useContext } from "react";
import bigLogo from "../../../images/bigbang_vektorel_logo.png";
import { FaSignOutAlt } from "react-icons/fa";
import { Logo, RightAlign, LogOut, NavbarContainer } from "./styles";
import { UserContext } from "../../../context/UserContext";
import styled from "styled-components/macro";
import { Link, useHistory } from "react-router-dom";

const Item = styled(Link)`
	color: white;
	margin-right: 10px;
	cursor: pointer;
`;

export default function AdminNavbar() {
	const { logout } = useContext(UserContext);
	const history = useHistory();

	return (
		<NavbarContainer>
			<Logo src={bigLogo} alt="logo" />
			<p style={{ color: "white", marginLeft: "15px", fontSize: "22px" }}>Admin Panel</p>
			<RightAlign>
				<Item to="/admin">Home</Item>
				<Item to="/admin/kullanicilar">Kullanıcılar</Item>
				<Item to="/admin/yatirimlar">Yatırımlar</Item>
				<LogOut onClick={logout}>
					Log Out <FaSignOutAlt style={{ marginLeft: "10px" }} size="1.3em" />
				</LogOut>
			</RightAlign>
		</NavbarContainer>
	);
}
