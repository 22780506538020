import React from "react";
import { Wrapper, Container, AddWrapper, Header } from "./styles";
import { createMuiTheme, ThemeProvider } from "@material-ui/core/styles";
import { UyelikGiris } from "./UyelikGiris";
import { EnhancedTable } from "./EnhancedTable";
import { UyelerTable } from "./UyelerTable";
import { AddFund } from "./AddFund";
import { LiveStreamUrlForm } from "./LiveStreamUrlForm";

const theme = createMuiTheme({
	palette: {
		primary: {
			main: "#c62828",
		},
		secondary: {
			main: "#b71c1c",
		},
	},
});

export default function MainWrapper() {
	return (
		<ThemeProvider theme={theme}>
			<Wrapper>
				<Container>
					<LiveStreamUrlForm />
				</Container>
				<Container>
					<UyelikGiris />
				</Container>
				<Container>
					<AddFund />
				</Container>
			</Wrapper>
		</ThemeProvider>
	);
}
