import { getToken } from "./auth-header";

// export const API_URL = "https://localhost:6001/";
export const API_URL = "https://api.bigbang2021.prod.cmosteknoloji.com/";

export const postAuthorized = async (payload, uri) => {
    const request = {
        method: "POST",
        mode: "cors",
        headers: { "Content-Type": "application/json", Authorization: `Bearer ${getToken()}` },
        body: JSON.stringify(payload),
    };
    return await fetch(API_URL + uri, request);
};

export const postAuthorizedForm = async (payload, uri) => {
    const request = {
        method: "POST",
        mode: "cors",
        headers: { Authorization: `Bearer ${getToken()}` },
        body: payload,
    };
    return await fetch(API_URL + uri, request);
};

export const post = async (payload, uri) => {
    const request = {
        method: "POST",
        mode: "cors",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(payload),
    };
    return await fetch(API_URL + uri, request);
};

export const getAuthorized = async (uri) => {
    const request = {
        method: "GET",
        mode: "cors",
        headers: { "Content-Type": "application/json", Authorization: `Bearer ${getToken()}` },
    };
    return await fetch(API_URL + uri, request);
};

export const get = async (uri) => {
    const request = {
        method: "GET",
        mode: "cors",
        headers: { "Content-Type": "application/json", Authorization: `Bearer ${getToken()}` },
    };
    return await fetch(API_URL + uri, request);
};

export const deleteAuthorized = async (uri) => {
    const request = {
        method: "DELETE",
        mode: "cors",
        headers: { "Content-Type": "application/json", Authorization: `Bearer ${getToken()}` },
    };
    return await fetch(API_URL + uri, request);
};
