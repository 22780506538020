import styled from "styled-components/macro";
import { motion } from "framer-motion";

export const Container = styled(motion.div)`
	display: flex;
	z-index: 0;
	height: 100%;
	max-height: 90vh;
	position: relative;
`;

export const ViewContainer = styled.div`
	height: 100%;
	background-color: #141517;
	display: flex;
	flex-direction: column;
	overflow-y: scroll;
	max-width: 80vw;

	@media (max-width: 1224px) {
		max-width: 100vw;
	}
`;

export const TextContainer = styled.div`
	display: flex;
	flex-direction: column;
	color: white;
`;

export const MainContainer = styled(motion.div)`
	font-family: "Nunito", sans-serif;
	width: 100%;
	max-width: ${({ sidebar }) => (sidebar ? "80vw" : "100vw")};
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	background-color: #181a1c;

	@media (max-width: 1224px) {
		height: 100vh;
	}
`;

export const SideContainer = styled(motion.div)`
	min-width: 20%;
	height: 90vh;
	display: flex;
	flex-direction: column;
	background-color: #202326;

	-webkit-touch-callout: none;
	-webkit-user-select: none;
	-khtml-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;

	@media (max-width: 1024px) {
		height: 100vh;
	}
`;

export const SearchContainer = styled.div`
	border-bottom: solid 1px #181a1c;
	padding: 20px;
	background-color: #3f464a;
`;

export const SearchBar = styled.input`
	padding: 10px 1vw 10px;
	border: solid 1px #333d40;
	border-radius: 5px;
	background-color: #202326;
	color: white;
	width: 100%;

	transition: 0.5s;

	&:focus {
		outline: none;
		border: solid 1px #7b8787;
	}
`;

export const SearchBarContainer = styled.div`
	display: flex;
	align-items: center;
	width: 100%;
`;

export const SearchLabel = styled.p`
	color: white;
	font-size: 22px;
	margin: 0px 3px 10px;
	// NOT GOOD MAN NOT GOOD!
`;

export const SearchButton = styled.button`
	background-color: transparent;
	border: none;
	color: white;
	transition: 0.1s;

	&:focus {
		outline: none;
	}

	&:active {
		transform: scale(0.9);
	}
`;

export const UsersContainer = styled.div`
	width: 100%;
	height: 79vh;
	overflow-y: scroll;
`;

export const UserContainer = styled(motion.div)`
	border-radius: 5px;
	border: solid 1px;
	border-color: ${({ borderColor }) => borderColor};
	display: flex;
	align-items: center;
	padding: 10px;
	margin: 10px 14px 10px 14px;
	transition: 0.3s;
	background-color: ${({ backgroundColor }) => backgroundColor};
	box-shadow: 0px 0px 5px black;

	&:hover {
		background-color: #2c3033;
	}
`;

export const UserLogo = styled.img`
	width: 50px;
`;

export const UserLogoContainer = styled.div`
	min-width: 60px;
	min-height: 60px;
	max-width: 60px;
	max-height: 60px;

	overflow: hidden;

	display: flex;
	justify-content: center;
	align-items: center;
	border: solid 1px #333d40;
	border-radius: 50%;
	background-color: #dae7e8;
`;

export const UserName = styled.p`
	font-family: "Poppins", sans-serif;
	font-size: 14px;
	color: white;
	margin: 0 15px 0;
	cursor: default;
	overflow-x: hidden;
`;

export const UserLastTime = styled.p`
	font-size: 12px;
	font-family: "Helvetica", sans-serif;
	margin: 0;
`;

export const UserStatus = styled.p`
	color: #ccc;
	margin: 6px 15px 0;
	font-size: 12px;
	font-style: italic;
	cursor: default;
`;
export const UserStatusIcon = styled.div`
	border-radius: 50%;
	min-height: 10px;
	min-width: 10px;
	background-color: ${({ status }) => (status === "online" ? "#32CD32" : "#8B0000")};
	border: solid 4px #202326;
`;

export const InputContainer = styled.div`
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: space-between;
	border-top: solid 1px black;
`;

export const InputField = styled.input`
	border-radius: 5px;
	border: solid 1px #333d40;
	width: 90%;
	margin: 20px 20px;
	height: 50px;
	font-size: 16px;
	background-color: #181a1c;
	padding: 0px 20px 0px;
	color: white;

	transition: 0.5s;

	&:focus {
		outline: none;
		border: solid 1px #7b8787;
	}
`;

export const InputSendButton = styled.button`
	border-radius: 50%;
	border: solid 1px #333d40;
	background-color: #181a1c;
	color: white;
	min-height: 48px;
	min-width: 48px;
	margin-right: 20px;
	display: flex;
	align-items: center;
	justify-content: center;
	box-shadow: 0 0 20px rgb(0, 0, 0, 0.3);

	transition: 0.3s;

	&:focus {
		outline: none;
	}

	&:hover {
		background-color: #202326;
		box-shadow: 0 0 8px rgb(0, 0, 0, 0.6);
	}

	&:active {
		background-color: white;
		color: black;
	}
`;

export const InputEmojiButton = styled.button`
	border-radius: 50%;
	border: solid 1px #333d40;
	background-color: #181a1c;
	color: white;
	min-height: 48px;
	min-width: 48px;
	margin-left: 20px;
	display: flex;
	align-items: center;
	justify-content: center;
	box-shadow: 0 0 20px rgb(0, 0, 0, 0.3);

	transition: 0.3s;

	&:focus {
		outline: none;
	}

	&:hover {
		background-color: #202326;
		box-shadow: 0 0 8px rgb(0, 0, 0, 0.6);
	}

	&:active {
		background-color: white;
		color: black;
	}
`;

export const CurrentUserContainer = styled.div`
	display: flex;
	align-items: center;
	padding: 2vh 2vw 2vh;
	border-bottom: solid 1px black;
`;

export const CurrentUserSubContainer = styled.div`
	display: flex;
	flex-direction: column;
`;

export const OwnText = styled.p`
	padding-left: 1vw;
	padding-right: 1vw;
	margin: 0;
	width: 95%;
	color: #ddd;
`;

export const OtherText = styled.p`
	padding-left: 1vw;
	padding-right: 1vw;
	margin: 0;
	width: 95%;
	color: #ddd;
`;

export const TextTime = styled.p`
	font-size: 0.7em;
	margin: 0;
	color: #aaa;
`;

export const OwnTextBox = styled(motion.div)`
	font-size: 14px;
	line-height: 1.6;
	color: white;
	padding: 1vh 0 1vh;
	background-color: #272b30;
	border-radius: 10px 10px 1px 10px;
	min-height: 2em;
	display: flex;
	align-items: center;
	margin: 0;
	overflow-wrap: break-word;
`;
export const OtherTextBox = styled(motion.div)`
	font-size: 14px;
	line-height: 1.6;
	color: white;
	padding: 1vh 0 1vh;
	background-color: #3f464a;
	border-radius: 10px 10px 10px 1px;
	min-height: 2em;
	display: flex;
	align-items: center;
	margin: 0;
	overflow-wrap: break-word;
`;

export const TextActionButton = styled.button`
	border: none;
	background-color: transparent;
	color: #aaa;
	display: flex;
	align-items: center;

	&:focus {
		outline: none;
	}
`;

export const Wrapper = styled.div``;

export const MiscOtherWrapper = styled.div`
	display: flex;
	align-items: center;
`;

export const MiscOwnWrapper = styled.div`
	display: flex;
	align-items: center;
	margin-left: auto;
`;

export const NotSelected = styled(motion.div)`
	background-color: #191b1f;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	text-align: center;
	color: white;
	width: 100%;
`;

export const WarnText = styled.p`
	color: white;
	font-size: 32px;
	margin-bottom: 10px;
`;

export const NotLoggedIn = styled(NotSelected)`
	height: 90vh;
`;

export const Loading = styled(NotSelected)`
	height: 90vh;
	position: absolute;
	z-index: 2;
	margin-top: 10vh;
`;

export const SmalLWarnText = styled.p`
	color: white;
	font-size: 20px;
	margin-top: 10px;
`;

export const IconWrapper = styled(motion.div)`
	display: flex;
	align-items: center;
	justify-content: center;
	width: 100%;
`;

export const Notification = styled.div`
	font-weight: 600;
	font-size: 14px;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	border-radius: 12px;
	min-width: 8px;
	height: 20px;
	padding-right: 6px;
	padding-left: 6px;
	background-color: #a2ce58;
	color: #131416;

	transition: 0.3s;
`;
