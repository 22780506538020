import styled from "styled-components/macro";
import garaj from "../../images/garaj.jpg";
import ipadGaraj from "../../images/ipadGaraj.jpg";
import { motion } from "framer-motion";

export const Container = styled.div`
	display: grid;
	grid-template-columns: auto 1fr;
	grid-template-rows: auto 1fr;
	height: 100%;
	max-width: 100vw;
	background-color: #191b1f;

	@media (max-height: 414px) {
		height: 100%;
		width: 100vw;
		grid-template-columns: auto;
		grid-template-rows: auto;
	}
`;
export const Carousel = styled.div`
	position: relative;
	overflow-y: hidden;
	background-image: url(${garaj});
	background-size: 100%;
	background-repeat: no-repeat;
	background-position: center;
	display: flex;
	max-height: 75vh;
	max-width: 75vw;
	justify-content: center;

	@media (max-width: 823px) {
		max-width: 100vw;
		max-height: 100vh;
	}

	@media (max-aspect-ratio: 1366 / 1024) {
		background-image: url(${ipadGaraj});
	}
`;
export const SideBar = styled(motion.div)`
	padding: 0 14px 0;
	background-color: #202326;

	z-index: 2;
	border-left: solid 3px #ffea42;
	box-shadow: 0 0 10px 4px #ff9933;
	box-sizing: border-box;
	max-width: 25vw;

	@media (max-width: 823px) {
		display: none;
		font-size: 14px;
	}
`;
export const BottomBar = styled.div`
	max-height: 25vh;
	max-width: 75vw;
	position: relative;
	background-color: #202326;
	border-top: solid 2px #ffea42;
	box-shadow: 0 0 10px #ffea42;
	align-self: center;
	z-index: 1;
	padding: 2vh 4vw 2vh;
	box-sizing: border-box;

	@media (max-width: 823px) {
		display: none;
	}
`;

export const EmptyBar = styled.div`
	background-color: #202326;
	border-left: solid 3px #ffea42;
	box-shadow: 0 15px 10px 4px #ff9933;
	z-index: 2;
`;

export const InformationBox = styled.div`
	display: flex;
	color: #bbb;
	font-weight: 200;

	width: 100%;
	font-family: "Titillium Web", sans-serif;
	font-size: 19px;
	margin: 10px 0 10px;

	@media (min-width: 2000px) {
		font-size: 32px;
	}

	@media (max-width: 1366px) {
		font-size: 16px;
	}
`;

export const DownChevron = styled(motion.div)`
	display: block;
	font-size: 46px;
	bottom: 0;
	position: sticky;
	color: rgba(255, 255, 255, 0.5);
	background-color: transparent;
	transition: 0.3s;
`;
