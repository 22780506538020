import React from "react";
import {
	Label,
	Input,
	Button,
	RadioButton,
	SmallLabel,
	ButtonWrapper,
	ReleaseWrapper,
	Wrapper,
	CloseButton,
	Loading,
	Success,
	Error,
} from "./styles";

export default function FormComponent({ children, ...restProps }) {
	return <Wrapper {...restProps}>{children}</Wrapper>;
}

FormComponent.Label = function FormComponentLabel({ children, ...restProps }) {
	return <Label {...restProps}>{children}</Label>;
};

FormComponent.SmallLabel = function FormComponentSmallLabel({ children, ...restProps }) {
	return <SmallLabel {...restProps}>{children}</SmallLabel>;
};

FormComponent.Input = function FormComponentInput({ children, ...restProps }) {
	return <Input {...restProps}>{children}</Input>;
};

FormComponent.Button = function FormComponentButton({ children, ...restProps }) {
	return <Button {...restProps}>{children}</Button>;
};

FormComponent.CloseButton = function FormComponentCloseButton({ children, ...restProps }) {
	return <CloseButton {...restProps}>{children}</CloseButton>;
};

FormComponent.RadioButton = function FormComponentRadioButton({ children, ...restProps }) {
	return <RadioButton {...restProps}>{children}</RadioButton>;
};

FormComponent.ReleaseWrapper = function FormComponentReleaseWrapper({ children, ...restProps }) {
	return <ReleaseWrapper {...restProps}>{children}</ReleaseWrapper>;
};

FormComponent.ButtonWrapper = function FormComponentButtonWrapper({ children, ...restProps }) {
	return <ButtonWrapper {...restProps}>{children}</ButtonWrapper>;
};

FormComponent.Loading = function FormComponentLoading({ children, ...restProps }) {
	return <Loading {...restProps}>{children}</Loading>;
};

FormComponent.Success = function FormComponentSuccess({ children, ...restProps }) {
	return <Success {...restProps}>{children}</Success>;
};

FormComponent.Error = function FormComponentError({ children, ...restProps }) {
	return <Error {...restProps}>{children}</Error>;
};
