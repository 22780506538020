import styled from "styled-components/macro";

export const Wrapper = styled.div`
	width: 100%;
	height: 110%;
`;

export const Container = styled.div`
	min-height: 100px;
	min-width: 300px;
	box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
	background: #2d3338;
	border-radius: 5px;
	padding: 2vh 2vw 2vh;
	margin: 10px 1vw 10px;
	font-family: Roboto;
	color: white;
`;

export const AddWrapper = styled.div`
	margin-top: 10px;
	width: 100%;
`;

export const Header = styled.h2`
	margin-top: 15px;
	margin-bottom: 25px;
`;
