import * as Yup from "yup";
import { changeLink, addUser, addFunding } from "../../../services/admin.service";

export const handleLinkSubmit = (data, { setSubmitting, setErrors }) => {
	setSubmitting(true);

	changeLink(data.bigbangLink, data.maviLink)
		.then((data) => {
			if (data.success) {
				// TODO(omer): popup message
			} else {
				setErrors(data);
			}
		})
		.catch((error) => {
			alert(error);
		});

	setSubmitting(false);
};

export const handleUserSignUp = (data, notifySuccess, notifyError, { setSubmitting, setErrors }) => {
	setSubmitting(true);
	addUser(
		data.name,
		data.email,
		data.password,
		data.startupName,
		data.startupEmail,
		data.phone,
		data.projectName,
		data.projectDesc,
		data.projectVideo,
		data.filepdf,
		data.filelogo,
		data.filestand,
		data.linkedIn,
		data.fileStandThumb
	)
		.then((data) => {
			if (data.ok) {
				notifySuccess();
			} else {
				setErrors(data);
				notifyError();
			}
		})
		.catch((error) => {
			notifyError();
		});

	setSubmitting(false);
};

export const handleFundEntrance = (data, { setSubmitting, setErrors }) => {
	setSubmitting(true);
	addFunding(data.funderName, data.startupBeingFunded, data.amount, data.typeOfFund)
		.then((data) => {
			if (data.success) {
				// TODO(omer): popup message
			} else {
				setErrors(data);
			}
		})
		.catch((error) => alert(error));
	setSubmitting(false);
};

export const NameSchema = Yup.object().shape({
	realName: Yup.string().min(3, "Ad veya soyad 2 karakterden az olamaz!").required("Devam etmek için isminizi giriniz!"),
});

export const PasswordSchema = Yup.object().shape({
	oldPassword: Yup.string(),
	newPassword: Yup.string()
		.required("Şifre girmeniz zorunludur.")
		.min(8, "Şifreniz çok kısa - şifreniz en az 8 karakterden oluşmalı."),
	confirmPassword: Yup.string().oneOf([Yup.ref("newPassword"), null], "Şifreniz eşleşmeli."),
});
