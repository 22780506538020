import { AnimatePresence, motion } from "framer-motion";
import React, { useContext, useState, useEffect, useRef } from "react";
import { HiOutlineDocumentDownload } from "react-icons/hi";
import { BiChevronsDown } from "react-icons/bi";
import styled from "styled-components/macro";
import { UserContext } from "../../context/UserContext";
import placeholderPDF from "../../images/placeholderPDF.png";
import { Carousel, Container, SideBar, BottomBar, InformationBox, DownChevron, EmptyBar } from "./style";

const SmallHeader = styled.div`
	min-width: 85px;
	color: transparent;
	text-shadow: 0px 0px 1px white, 0px 0px 1px white, 0px 0px 1px white, 0px 0px 1px white;
`;

const Header = styled.div`
	margin: 25px 0 25px;
	display: flex;
	justify-content: center;
	color: white;
	font-weight: 200;
	width: 100%;

	font-family: "Titillium Web", sans-serif;
	font-style: italic;
	font-size: 28px;

	@media (min-width: 2000px) {
		font-size: 38px;
	}
	@media (max-width: 1366px) {
		font-size: 20px;
	}
`;

const DownloadButton = styled.a`
	position: absolute;
	color: black;
	bottom: 10px;
	right: 10px;
	background-color: white;
	border-radius: 5px;
	border: 2px dashed black;
	display: flex;
	justify-content: center;
	transition: 0.3s;

	&:hover {
		transform: scale(1.1);
	}
`;

export default function Girisim({ children, ...restProps }) {
	return <Container {...restProps}>{children}</Container>;
}

Girisim.Carousel = function GirisimCaorusel({ children, ...restProps }) {
	return <Carousel {...restProps}>{children}</Carousel>;
};

Girisim.SideBar = function GirisimSideBar({ standInfo, ...restProps }) {
	const [indicatorVisibility, setIndicatorVisibility] = useState(true);
	const { logged } = useContext(UserContext);
	const divRef = useRef(null);
	const handleScroll = (e) => {
		if (!e.target) return;
		const bottom = e.target.scrollHeight - e.target.scrollTop - 50 <= e.target.clientHeight;
		if (bottom) {
			setIndicatorVisibility(false);
		}
	};

	useEffect(() => {
		if (divRef.current.scrollHeight <= divRef.current.clientHeight) {
			setIndicatorVisibility(false);
			return;
		}
		setIndicatorVisibility(true);
	}, [standInfo]);

	return (
		<>
			<AnimatePresence>
				{standInfo && (
					<SideBar {...restProps}>
						<div
							onScroll={handleScroll}
							ref={divRef}
							style={{
								overflowY: "scroll",
								backgroundColor: "rgb(23, 25, 28)",
								height: "65vh",
								borderRadius: "6px",
								marginTop: "10px",
								padding: "0 20px 0",
								boxShadow: "inset 0 0 10px #000",
								position: "relative",
							}}
						>
							<motion.div
								key={standInfo.id}
								initial={{ y: 5, opacity: 0 }}
								animate={{ y: 0, opacity: 1 }}
								exit={{ y: 5, opacity: 0 }}
								transition={{ duration: 0.3, ease: [0.43, 0.13, 0.23, 0.96] }}
							>
								<Header>Girişimci Bilgisi</Header>
								<InformationBox>
									<SmallHeader>Ad, Soyad:</SmallHeader> {standInfo.name}
								</InformationBox>
								{logged && (
									<>
										<InformationBox>
											<SmallHeader>Telefon:</SmallHeader> {standInfo.phoneNumber}
										</InformationBox>
										<InformationBox>
											<SmallHeader>E-posta:</SmallHeader> {standInfo.email}
										</InformationBox>
									</>
								)}
								<InformationBox>
									<SmallHeader>Web Site:</SmallHeader>{" "}
									<a
										style={{ color: "white", textDecoration: "none" }}
										href={standInfo.startupWebsite}
										target="_blank"
									>
										{standInfo.startupWebsite}
									</a>
								</InformationBox>
								<InformationBox>
									<SmallHeader>Girişim: </SmallHeader>
									{standInfo.projectName}
								</InformationBox>
								<InformationBox>
									<SmallHeader>Açıklama:</SmallHeader> {standInfo.projectDescription}
								</InformationBox>
								<Header>Doküman</Header>
								<div className="dokuman-wrapper">
									<div style={{ position: "relative" }}>
										<DownloadButton href={standInfo.projectDocument} target="_blank" download>
											<HiOutlineDocumentDownload size="2.5em" />
										</DownloadButton>
										<img src={standInfo.projectDocumentThumb} alt="dokuman" />
									</div>
								</div>

								<DownChevron
									style={{ opacity: indicatorVisibility ? 1 : 0 }}
									animate={{ y: [-5, 0, -5] }}
									transition={{ duration: 1, loop: Infinity, repeatDelay: 0.3 }}
								>
									<BiChevronsDown />
								</DownChevron>
							</motion.div>
						</div>
					</SideBar>
				)}
			</AnimatePresence>
		</>
	);
};

Girisim.BottomBar = function GirisimBottomBar({ children, ...restProps }) {
	return <BottomBar {...restProps}>{children}</BottomBar>;
};

Girisim.EmptyBar = function GirisimEmptyBar({ ...restProps }) {
	return <EmptyBar {...restProps} />;
};
