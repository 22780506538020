import styled from "styled-components/macro";

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    padding: 25px;
    color: white;
    z-index: 3;

    @media (max-height: 414px) {
        right: auto;
        top: auto;

        left: 10px;
        bottom: 5px;
    }
`;

export const MailGonder = styled.div`
    display: flex;
    align-items: center;
`;

export const SoruSor = styled.div`
    display: flex;
    align-items: center;
`;
