import React, { useRef, useEffect, useState } from "react";
import styled from "styled-components/macro";
import { motion } from "framer-motion";

const Video = styled(motion.video)`
	width: 100vw;
	height: 100vh;
	position: absolute;
	z-index: 999;
	background-color: black;
`;

export default function HomePageStartVideo({ setVideoEnd }) {
	const videoRef = useRef(null);

	const [currentTime, setCurrentTime] = useState(null);

	useEffect(() => {
		if (videoRef) {
		}
	}, []);

	return <Video exit={{ opacity: 0 }} ref={videoRef} onEnded={() => setVideoEnd(false)} autoPlay muted></Video>;
}
