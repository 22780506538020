import { useState, useEffect } from "react";

export const useMousePosition = () => {
	const [mousePosition, setMousePosition] = useState({ x: null, y: null });

	const updateMousePosition = (ev) => {
		setMousePosition({ x: ev.clientX + ev.screenX / 2, y: ev.clientY + ev.screenY / 2 });
	};

	useEffect(() => {
		window.addEventListener("mousemove", updateMousePosition);

		return () => window.removeEventListener("mousemove", updateMousePosition);
	}, []);

	return mousePosition;
};
