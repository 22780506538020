import React, { useEffect, useState } from "react";
import { NavBoxContainer, TableContainer } from "../containers";
import { useMediaQuery } from "react-responsive";

export default function Odul({ setCurrentNav, children, ...restProps }) {
	const isMobile = useMediaQuery({ query: "(max-width: 823px)" });
	return (
		<>
			{/* {!isMobile && <NavBoxContainer setCurrentNav={setCurrentNav} />} */}
			<TableContainer />
		</>
	);
}
