import React from "react";
import { Container, Label, Navbar, Button, SendButton, TextArea, Line, Input, Header } from "./styles";

export default function Mail({ children, ...restProps }) {
	return <Container {...restProps}>{children}</Container>;
}

Mail.Navbar = function MailNavbar({ children, ...restProps }) {
	return <Navbar {...restProps}>{children}</Navbar>;
};

Mail.Label = function MailLabel({ ...restProps }) {
	return <Label {...restProps} />;
};

Mail.Button = function MailButton({ children, ...restProps }) {
	return <Button {...restProps}>{children}</Button>;
};

Mail.SendButton = function MailSendButton({ children, ...restProps }) {
	return <SendButton {...restProps}>{children}</SendButton>;
};

Mail.TextArea = function MailTextArea({ ...restProps }) {
	return <TextArea {...restProps} />;
};

Mail.Line = function MailLine({ ...restProps }) {
	return <Line {...restProps} />;
};

Mail.Input = function MailInput({ ...restProps }) {
	return <Input {...restProps} />;
};

Mail.Header = function MailHeader({ children, ...restProps }) {
	return <Header {...restProps}>{children}</Header>;
};
