import React, { useContext } from "react";
import { UserContext } from "../../context/UserContext";
import { BsChatDots } from "react-icons/bs";
import { ImLinkedin } from "react-icons/im";
import { HiMail } from "react-icons/hi";
import { useHistory } from "react-router-dom";
import { SoruSor, Container, MailGonder } from "./styles";
import { TiBusinessCard } from "react-icons/ti";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import styled from "styled-components";
import { useMediaQuery } from "react-responsive";

const Button = styled.button`
    display: flex;
    align-items: center;
    background-color: transparent;
    color: white;
    border: none;
    transition: 0.3s;

    &:hover {
        transform: scale(1.12);
    }

    &:active {
        transform: scale(0.9);
    }
`;

const Link = styled.a`
    color: white;
    display: flex;
    align-items: center;
    text-decoration: none;
    padding: 0 6px 0;
    margin-bottom: 6px;
    transition: 0.3s;

    &:hover {
        transform: scale(1.12);
    }

    &:active {
        transform: scale(0.9);
    }
`;

export default function AbsoluteButtonContainer({
    setMailOpen,
    setKartOpen,
    children,
    setIsPopupActive,
    startupLinkedInUrl,
    startupEmail,
    handleEmailPopupClick,
    ...restProps
}) {
    const notifyError = () =>
        toast.error("Mesajlaşmadan önce kayıt olunuz!", {
            position: "bottom-center",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });

    const { logged } = useContext(UserContext);
    const history = useHistory();
    const isSmall = useMediaQuery({ query: "(max-width: 823px)" });

    const handleMailClick = () => {
        handleEmailPopupClick(startupEmail);
        setIsPopupActive(true);
        setMailOpen((prev) => !prev);
    };

    const handleKartClick = () => {
        handleEmailPopupClick(startupEmail);
        setIsPopupActive(true);
        setKartOpen((prev) => !prev);
    };

    return (
        <Container {...restProps}>
            {!isSmall && <></>}
            {startupLinkedInUrl && (
                <SoruSor>
                    <Link href={startupLinkedInUrl} target="_blank">
                        <ImLinkedin size="1.6em" style={{ marginRight: "9px" }} />
                        {!isSmall && "LinkedIn"}
                    </Link>
                </SoruSor>
            )}
            

            <ToastContainer
                position="bottom-center"
                autoClose={3000}
                hideProgressBar={false}
                newestOnTop
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
            />
        </Container>
    );
}
