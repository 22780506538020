import React from "react";
import { RowCenter, RightAlign, LeftAlign } from "./style";

export default function Layout({ children, ...restProps }) {
    return <div {...restProps}>{children}</div>;
}

Layout.RowCenter = function LayoutRowCenter({ children, ...restProps }) {
    return <RowCenter {...restProps}>{children}</RowCenter>;
};

Layout.LeftAlign = function LayoutLeftAlign({ children, ...restProps }) {
    return <LeftAlign {...restProps}> {children} </LeftAlign>;
};

Layout.RightAlign = function LayoutRightAlign({ children, ...restProps }) {
    return <RightAlign {...restProps}> {children} </RightAlign>;
};
