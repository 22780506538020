import React, { useState } from "react";
import styled from "styled-components/macro";
import { BsTrashFill } from "react-icons/bs";
import { RiEdit2Fill, RiCheckDoubleFill } from "react-icons/ri";
import { FaCheck } from "react-icons/fa";
import { motion, AnimatePresence } from "framer-motion";

const StyledSelect = styled.select`
    border: none;
    margin: 2px;
    border-radius: 3px;
`;

const DataRow = styled.div`
    pointer-events: ${({ isEnabled }) => (isEnabled ? "none" : "all")};
    position: relative;
    display: grid;
    width: auto;
    border: 1px solid #2d3338;
    background-color: ${({ idx }) => (idx % 2 === 0 ? "#4e5c61" : "#3d484f")};
    grid-template-columns: repeat(7, 1fr);
`;

const Item = styled.div`
    padding: 3px 0 3px;

    padding-left: 10px;
    display: flex;
    transition: color 0.3s;
    align-items: center;

    &:hover {
        color: white;
        cursor: default;
    }
`;

const InputItem = styled.input`
    border: none;
    background-color: transparent;
    padding-left: 10px;
    display: flex;
    transition: 0.3s;
    align-items: center;

    &:hover {
        cursor: default;
    }

    &:focus {
        outline: none;
        border: 1px solid white;
    }
`;

const Button = styled.button`
    border-radius: 4px;
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 24px;
    height: 24px;
    transition: 0.3s;
    box-shadow: 0 0 5px black;
    cursor: pointer;

    &:focus {
        outline: none;
    }

    &:hover {
        box-shadow: none;
        transform: scale(1.1);
        filter: brightness(1.5);
    }
`;

const Erase = styled(Button)`
    margin-right: 20px;
    border: 1px solid white;
    color: white;
    background-color: #c20013;
`;
const Edit = styled(Button)`
    background-color: white;
    transition: 0.3s;
`;
const Submit = styled(Button)`
    background-color: #47b510;
    border: 1px solid white;
    color: white;
    margin-left: auto;
    margin-right: 10px;
`;

const Blocker = styled(motion.div)`
    position: absolute;
    background-color: rgba(12, 12, 16, 0.8);
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.6);
    pointer-events: none;
    z-index: 10;
    display: flex;
    justify-content: center;
    align-items: center;

    width: 100%;
    height: 100%;
`;

const fundType = ["None", "Nakit", "Ödül", "Yatırım"];
const investmentTargetType = ["None", "Top20", "Fuaye", "Ardıl ", "İtü Çekirdek"];

export const DataItem = ({ donation, handleEdit, handleErase, handleSubmit, idx, setEditable }) => {
    const [amountValue, setAmountValue] = useState(donation.amount);
    const [fromValue, setFromValue] = useState(donation.investorName);
    const [toValue, setToValue] = useState(donation.startupName);
    const [fundTypeValue, setFundTypeValue] = useState(donation.investmentType);
    const [targetTypeValue, setTargetTypeValue] = useState(donation.investmentTarget);

    const SelectFundType = () => {
        return (
            <StyledSelect value={fundTypeValue} onChange={(e) => setFundTypeValue(Number(e.target.value))}>
                {fundType.map((type, idx) => (
                    <option key={idx} value={idx}>
                        {type}
                    </option>
                ))}
            </StyledSelect>
        );
    };

    const SelectTargetType = () => {
        return (
            <StyledSelect value={targetTypeValue} onChange={(e) => setTargetTypeValue(Number(e.target.value))}>
                {investmentTargetType.map((type, idx) => (
                    <option key={idx} value={idx}>
                        {type}
                    </option>
                ))}
            </StyledSelect>
        );
    };

    var formattedOutput = new Intl.NumberFormat("tr-TR", {
        style: "currency",
        currency: "TRY",
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
    });
    return (
        <>
            <DataRow idx={idx} isEnabled={donation.isEnabled}>
                <Item>{donation.id}</Item>
                {donation.isEditable ? (
                    <InputItem
                        style={{ color: "white" }}
                        value={fromValue}
                        placeholder={donation.investorName}
                        onChange={(e) => setFromValue(e.target.value)}
                    />
                ) : (
                    <Item>{donation.investorName}</Item>
                )}
                {donation.isEditable ? (
                    <InputItem
                        style={{ color: "white" }}
                        value={toValue}
                        placeholder={donation.startupName}
                        onChange={(e) => setToValue(e.target.value)}
                    />
                ) : (
                    <Item>{donation.startupName}</Item>
                )}
                {donation.isEditable ? (
                    <InputItem
                        style={{ color: "white" }}
                        value={amountValue}
                        placeholder={formattedOutput.format(donation.amount)}
                        onChange={(e) => setAmountValue(Number(e.target.value))}
                    />
                ) : (
                    <Item>{formattedOutput.format(donation.amount)}</Item>
                )}
                {donation.isEditable ? <SelectFundType /> : <Item>{fundType[donation.investmentType]}</Item>}
                {donation.isEditable ? (
                    <SelectTargetType />
                ) : (
                    <Item>{investmentTargetType[donation.investmentTarget]}</Item>
                )}
                <Item>
                    <Erase onClick={() => handleErase(donation.id)}>
                        <BsTrashFill style={{ transform: "scale(1.5)" }} />
                    </Erase>

                    <Edit
                        onClick={() =>
                            donation.isEditable
                                ? handleEdit(idx, toValue, fromValue, amountValue, fundTypeValue, targetTypeValue)
                                : setEditable(idx)
                        }
                    >
                        {donation.isEditable ? (
                            <RiCheckDoubleFill style={{ transform: "scale(1.5)" }} />
                        ) : (
                            <RiEdit2Fill style={{ transform: "scale(1.5)" }} />
                        )}
                    </Edit>

                    <Submit onClick={() => handleSubmit(idx)}>
                        <FaCheck style={{ transform: "scale(1.5)" }} />
                    </Submit>
                </Item>
                <AnimatePresence>
                    {donation.isEnabled && (
                        <Blocker initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }}>
                            <RiCheckDoubleFill size="2em" />
                        </Blocker>
                    )}
                </AnimatePresence>
            </DataRow>
        </>
    );
};
