import React, { useContext, useEffect, useRef, useState } from "react";
import { FormComponent } from "../components";
import { Formik, Field, Form } from "formik";
import { useHistory } from "react-router-dom";
import ReactLoading from "react-loading";
import { UserContext } from "../context/UserContext";
import close from "../images/close.png";

const initial = { opacity: 0, y: 5 };
const exit = { opacity: 0, y: 5 };
const animate = { opacity: 1, y: 0 };
const transition = { duration: 0.3, ease: [0.43, 0.13, 0.23, 0.96] };

export default function Signin({
	redirectTo,
	shouldChangeURL,
	setCurrentNav,
	setSignInClick,
	setSignUpClick,
	children,
	setPasswordResetClick,
	setIsPopupActive,
	...restProps
}) {
	const history = useHistory();
	const ref = useRef(null);
	const { login } = useContext(UserContext);
	const FORM_STATE = { SUCCESS: 0, ERROR: 1, HOLD: 2 };
	const [isSuccess, setIsSuccess] = useState(FORM_STATE.HOLD);

	useEffect(() => {
		setIsPopupActive(true);
	}, [setIsPopupActive]);

	useEffect(() => {
		function handleClickOutside(event) {
			if (event.target === ref.current) {
				if (shouldChangeURL) {
					setIsSuccess(FORM_STATE.HOLD);
					setCurrentNav("");
					history.push("/");
				}
				setSignUpClick(false);
				setSignInClick(false);
				setIsPopupActive(false);
			}
		}
		document.addEventListener("mousedown", handleClickOutside);
		return () => {
			document.removeEventListener("mousedown", handleClickOutside);
		};
	});

	function getForm() {
		return (
			<Formik
				initialValues={{ email: "", password: "" }}
				onSubmit={(formData, { setSubmitting, setErrors }) => {
					setSubmitting(true);

					login(formData.email, formData.password)
						.then((responseData) => {
							if (responseData.username) {
								setIsSuccess(FORM_STATE.SUCCESS);

								setSignInClick(false);
								setCurrentNav("");
								history.push(redirectTo);
							} else {
								setIsSuccess(FORM_STATE.ERROR);

								setErrors(responseData);
							}
							setSubmitting(false);
						})
						.catch((error) => {
							setSubmitting(false);
						});
				}}
			>
				{({ isSubmitting, errors }) => (
					<div
						ref={ref}
						style={{
							width: "100vw",
							height: "100vh",
							position: "absolute",
							display: "flex",
							justifyContent: "center",
							alignItems: "center",
							top: 0,
							zIndex: 11,
							backgroundColor: "rgba(0,0,0,0.8)",
						}}
					>
						<FormComponent initial={initial} animate={animate} exit={exit} transition={transition} type="signin">
							{!isSubmitting ? (
								<Form style={{ display: "flex", flexDirection: "column" }}>
									<div style={{ display: "flex", alignItems: "center", marginBottom: "10px" }}>
										<FormComponent.Label style={{ marginBottom: 0, width: "300px" }}>
											{" "}
											GİRİŞ YAP
										</FormComponent.Label>
										<div style={{ width: "100%" }}>
											<FormComponent.CloseButton
												type="button"
												onClick={() => {
													setSignInClick(false);
													setIsPopupActive(false);
													history.push(redirectTo);
													setCurrentNav("");
												}}
											>
												<img src={close} alt="close" style={{ width: "22px" }} />
											</FormComponent.CloseButton>
										</div>
									</div>
									<Field
										spellCheck="false"
										placeholder="Email..."
										name="email"
										type="email"
										as={FormComponent.Input}
									/>

									<Field
										spellCheck="false"
										placeholder="Şifre..."
										name="password"
										type="password"
										as={FormComponent.Input}
									/>
									{errors ? <div style={{ color: "red" }}>{errors.message}</div> : null}

									<div className="text-center">
										Kayıtlı değil misiniz?
										<span
											style={{
												color: "white",
												textShadow: "0 0 1px white, 0 0 1px white, 0 0 1px white",
												paddingLeft: "5px",
												paddingRight: "5px",
												cursor: "pointer",
											}}
											onClick={() => {
												setSignInClick(false);
												setSignUpClick(true);
											}}
										>
											Hemen kayıt olun!
										</span>
									</div>
									<div className="text-center">
										<span
											style={{
												color: "white",
												textShadow: "0 0 1px white, 0 0 1px white, 0 0 1px white",
												paddingLeft: "5px",
												paddingRight: "5px",
												cursor: "pointer",
											}}
											onClick={() => {
												setSignInClick(false);
												setPasswordResetClick(true);
											}}
										>
											Şifrenizi mi unuttunuz?
										</span>
									</div>

									<FormComponent.ButtonWrapper>
										<FormComponent.Button disabled={isSubmitting} type="submit" button={"giris"}>
											Giriş
										</FormComponent.Button>
									</FormComponent.ButtonWrapper>
								</Form>
							) : (
								<FormComponent.Loading>
									<div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
										<ReactLoading type="spin" color="white" height={64} width={64} />
										<FormComponent.Label style={{ marginTop: "20px", marginBottom: 0 }}>
											İşlem gerçekleştiriliyor...
										</FormComponent.Label>
									</div>
								</FormComponent.Loading>
							)}
						</FormComponent>
					</div>
				)}
			</Formik>
		);
	}

	function getBody() {
		switch (isSuccess) {
			case FORM_STATE.SUCCESS:
				return (
					<div
						ref={ref}
						style={{
							width: "100vw",
							height: "100vh",
							position: "absolute",
							display: "flex",
							justifyContent: "center",
							alignItems: "center",
							top: 0,
							zIndex: 11,
							backgroundColor: "rgba(0,0,0,0.8)",
						}}
					>
						<FormComponent style={{ width: "50vw", height: "200px" }} color="success">
							<FormComponent.Success>
								<FormComponent.Label style={{ marginBottom: 0 }}>
									Başarılı bir şekilde giriş yaptınız.
								</FormComponent.Label>
							</FormComponent.Success>
						</FormComponent>
					</div>
				);
			case FORM_STATE.HOLD:
				return getForm();
			default:
				return (
					<div
						ref={ref}
						style={{
							width: "100vw",
							height: "100vh",
							position: "absolute",
							display: "flex",
							justifyContent: "center",
							alignItems: "center",
							top: 0,
							zIndex: 11,
							backgroundColor: "rgba(0,0,0,0.8)",
						}}
					>
						<FormComponent style={{ width: "50vw", height: "200px" }} color="error">
							<FormComponent.Error>
								<FormComponent.Label>Giriş işlemi başarısız.</FormComponent.Label>
								<FormComponent.SmallLabel style={{ color: "inherit" }}>
									Girilen mail adresi ve şifrenin doğru olduğundan emin olunuz.
								</FormComponent.SmallLabel>
							</FormComponent.Error>
						</FormComponent>
					</div>
				);
		}
	}

	return getBody();
}
