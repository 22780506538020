import styled from "styled-components/macro";
import { motion } from "framer-motion";

export const Container = styled.div`
	display: flex;
	justify-content: center;
	position: relative;
	align-items: center;
	background-color: #292728;
	color: white;
	display: flex;
	z-index: 10;
	box-shadow: 0px 3px 10px rgba(219, 156, 31, 0.8);
	/* box-shadow: 0px 0px 10px black; */
`;

export const ChatContainer = styled.div`
	display: flex;
	justify-content: center;
	position: relative;
	align-items: center;
	background-color: #292728;
	color: white;
	display: flex;
	z-index: 10;
	height: 10vh;
	/* box-shadow: 0px 3px 10px rgba(219, 156, 31, 0.8); */
	box-shadow: 0px 0px 10px black;
`;

export const Logo = styled.img`
	position: absolute;
	left: 50px;
	width: 11.1vw;
	max-width: 280px;
	transition: 0.3s;
	cursor: pointer;

	&:hover {
		transform: scale(1.1) rotate(5deg);
	}
`;

export const ChatLogo = styled.img`
	width: 120px;
	margin: 10px 10px;
	margin-right: auto;
	margin-left: 50px;
	cursor: pointer;
	transition: 0.3s;

	&:hover {
		transform: scale(1.1);
	}
`;

export const ItemContainer = styled.div`
	font-family: "Teko", sans-serif;
	font-weight: 700;
	font-size: 26px;

	display: flex;
	margin-left: auto;
	margin-top: 2vh;
	margin-right: 14px;

	@media (max-width: 1366px) {
		font-size: 22px;
	}
`;

export const Label = styled(motion.div)`
	display: flex;
	align-items: center;
	justify-content: center;
	transform: skew(-15deg);
	cursor: pointer;
	min-width: 10vw;
	white-space: nowrap;

	-webkit-touch-callout: none;
	-webkit-user-select: none;
	-khtml-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;

	@media (max-width: 1366px) {
		font-size: 20px;
	}
`;

export const SmallContainer = styled.div``;

export const LogOut = styled.div`
	display: flex;
	align-items: center;
	color: white;
	margin-right: 40px;

	cursor: pointer;
	border-radius: 5px;
	padding: 4px 10px 4px;
	border: solid 2px white;

	transition: 0.2s;

	&:hover {
		box-shadow: 0 0 5px white;
	}

	&:active {
		box-shadow: none;
		border-color: #999;
		color: #999;
	}
`;
