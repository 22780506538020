export function authHeader() {
    const token = localStorage.getItem("access_token");
    if (token) {
        return { Authorization: `Bearer ${token}` };
    } else {
        return {};
    }
}

export function getToken() {
    return localStorage.getItem("access_token");
}
