import React from "react";
import { useHistory } from "react-router-dom";
import styled from "styled-components/macro";
import odul from "../images/fourpage_odul_bg.png";
import girisimci from "../images/fourpage/girisimci.PNG";
import { LiveStreamContainer } from "../containers";

const Wrapper = styled.div`
    width: 100vw;
    height: 87vh;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: minmax(43.5vh, 43.5vh) minmax(43.5vh, 43.5vh);
`;

const SmallBox = styled.div`
    width: 56px;
    height: 56px;
    margin-left: 4px;
    margin-top: 4px;

    background-color: rgba(255, 255, 255, 0.7);
    border-radius: 5px;
`;

const BorderBox = styled.div`
    position: absolute;
    top: 32px;
    left: 62px;
    width: 64px;
    height: 64px;
    border: solid 2px rgba(255, 255, 255, 0.7);
    border-radius: 10px;
    transition: 0.3s;

    &:hover {
        transform: scale(1.05);
    }
`;

const StyledDiv = styled.div`
    position: relative;
`;

export default function FourPage({ setMailOpen, setIsPopupActive, setCurrentNav }) {
    const history = useHistory();
    return (
        <Wrapper>
            <StyledDiv>
                <BorderBox onClick={() => history.push("/sahne")}>
                    <SmallBox />
                </BorderBox>
                <LiveStreamContainer stream="bigbangLink" />
            </StyledDiv>
            <StyledDiv>
                <BorderBox onClick={() => history.push("/mavi-mikrofon")}>
                    <SmallBox />
                </BorderBox>
                <LiveStreamContainer stream="maviLink" />
            </StyledDiv>
            <StyledDiv style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                <BorderBox onClick={() => history.push("/girisim/1")}>
                    <SmallBox />
                </BorderBox>
                <div
                    onClick={() => history.push("/girisim/1")}
                    style={{
                        boxShadow: "0 0 30px rgba(0,0,0,0.6)",
                        border: "solid 1px rgb(38, 41, 46)",
                        borderRadius: "5px",
                        width: "90%",
                        height: "90%",
                        display: "flex",
                        alignItems: "center",
                    }}
                >
                    {/* <LastGirisimci /> */}
                    <img style={{ width: "100%" }} src={girisimci} alt="odul" />
                </div>
            </StyledDiv>

            <StyledDiv style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                <BorderBox
                    onClick={() => {
                        history.push("/odul-ve-yatirimlar");
                        setCurrentNav("odul");
                    }}
                >
                    <SmallBox />
                </BorderBox>
                <div
                    onClick={() => {
                        history.push("/odul-ve-yatirimlar");
                        setCurrentNav("odul");
                    }}
                    style={{
                        boxShadow: "0 0 30px rgba(0,0,0,0.6)",
                        border: "solid 1px rgb(38, 41, 46)",
                        borderRadius: "5px",
                        maxWidth: "90%",
                        maxHeight: "90%",
                        display: "flex",
                    }}
                >
                    {/* <TableContainer /> */}
                    <img style={{ width: "100%" }} src={odul} alt="odul" />
                </div>
            </StyledDiv>
        </Wrapper>
    );
}
