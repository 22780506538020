import React from "react";
import styled from "styled-components/macro";

const Wrapper = styled.div`
	width: 100%;
	height: 90vh;
`;

export default function Kullanicilar() {
	return <Wrapper>kullanici</Wrapper>;
}
