import React, { useState, useEffect } from "react";
import { VideoContainer } from "../../components";
import styled from "styled-components/macro";

const Image = styled.img`
    width: 90%;
    height: 90%;
    border-radius: 5px;
    box-shadow: 0 0 10px black;
    border: 1px solid #777;
`;

export default function LiveStreamContainer({ stream }) {
    const [liveStreamData, setLiveStreamData] = useState({});

    const fetchData = () => {
        const request = {
            method: "GET",
            mode: "cors",
            headers: { "Content-Type": "application/json" },
        };
        fetch("https://bigbang2020-api.azurewebsites.net/livestream/all", request)
            .then((response) => response.json())
            .then((data) => {
                console.log(data["maviLink"]);
                setLiveStreamData(data);
            });
    };

    useEffect(() => {
        fetchData();
    }, []);

    return (
        <VideoContainer>
            {liveStreamData && liveStreamData[stream] && !liveStreamData[stream].includes("youtube") ? (
                <Image alt="livestream" src={liveStreamData[stream]} />
            ) : (
                <iframe
                    style={{
                        boxShadow: "0 0 30px rgba(0,0,0,0.6)",
                        border: "solid 1px rgb(38, 41, 46)",
                        borderRadius: "5px",
                    }}
                    width="90%"
                    height="90%"
                    src={liveStreamData[stream]}
                    frameBorder="0"
                    allowFullScreen
                    title="main"
                />
            )}
        </VideoContainer>
    );
}
