import React, { useEffect } from "react";
import { Countup } from "../../containers/TableContainer";
import { Wrapper, Top20, Item, AnimationItem } from "./style";

export default function Table({ children, ...restProps }) {
	return <Wrapper {...restProps}>{children}</Wrapper>;
}

Table.Top20 = function TableTop20({ lastInvestmentName, formattedOutput, tableData, ...restProps }) {
	return (
		<Top20 {...restProps}>
			{tableData.map(
				(item, idx) =>
					item.id && (
						<>
							{lastInvestmentName === item.name && (
								<AnimationItem
									key={item.key + 100}
									initial={{
										y: item.previousIndex,
										opacity: 1,
									}}
									animate={{
										y: [item.previousIndex, -1, 0, 0, 0],
										opacity: [1, 1, 1, 1, 0],
										scale: [1, 1.05, 1.05, 1, 1],
									}}
									exit={{ opacity: 0 }}
									transition={{ duration: 5.2, delay: 1, times: [0, 0.3, 0.4, 0.8, 1] }}
									odd={idx}
								>
									<p style={{ marginBottom: "1px", marginTop: "1px", paddingLeft: "20px" }}>{idx + 1}</p>
									<p style={{ marginBottom: "1px", marginTop: "1px" }}>{item.name}</p>
									<p
										style={{
											marginBottom: "1px",
											marginTop: "1px",
											display: "flex",
											justifyContent: "center",
										}}
									>
										<Countup formattedOutput={formattedOutput} endValue={item.odul} isTop20={true} />
									</p>
									<p
										style={{
											marginBottom: "1px",
											marginTop: "1px",
											display: "flex",
											justifyContent: "center",
										}}
									>
										<Countup formattedOutput={formattedOutput} endValue={item.nakit} isTop20={true} />
									</p>
									<p
										style={{
											marginBottom: "1px",
											marginTop: "1px",
											display: "flex",
											justifyContent: "center",
										}}
									>
										<Countup formattedOutput={formattedOutput} endValue={item.yatirim} isTop20={true} />
									</p>
								</AnimationItem>
							)}
							<Item
								key={item.id}
								initial={{
									y: item.previousIndex,
								}}
								animate={{ y: [item.previousIndex, -1, 0, 0, 0] }}
								exit={{ opacity: 0 }}
								transition={{ duration: 5.2, delay: 1, times: [0, 0.3, 0.4, 0.8, 1] }}
								odd={idx}
							>
								<p style={{ marginBottom: "1px", marginTop: "1px", paddingLeft: "20px" }}>{idx + 1}</p>
								<p style={{ marginBottom: "1px", marginTop: "1px" }}>{item.name}</p>
								<p style={{ marginBottom: "1px", marginTop: "1px", display: "flex", justifyContent: "center" }}>
									<Countup formattedOutput={formattedOutput} endValue={item.odul} isTop20={true} />
								</p>
								<p style={{ marginBottom: "1px", marginTop: "1px", display: "flex", justifyContent: "center" }}>
									<Countup formattedOutput={formattedOutput} endValue={item.nakit} isTop20={true} />
								</p>
								<p style={{ marginBottom: "1px", marginTop: "1px", display: "flex", justifyContent: "center" }}>
									<Countup formattedOutput={formattedOutput} endValue={item.yatirim} isTop20={true} />
								</p>
							</Item>
						</>
					)
			)}
		</Top20>
	);
};
