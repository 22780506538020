import React from "react";
import home from "../../videos/home.mp4";
import ipadHome from "../../videos/ipadHome.mp4";
import cover from "../../images/home_cover.png";
import styled from "styled-components/macro";

const Video = styled.video`
	width: 100%;
`;

export default function HomePageView({ imageRef, onImageLoaded, isIpad }) {
	return (
		<>
			<div style={{ width: "100%", overflow: "hidden", display: "flex", position: "relative" }}>
				<Video src={home} loop autoPlay muted playsInline style={{ pointerEvents: "none" }} />
				<div style={{ width: "100%", position: "absolute", overflow: "hidden", top: 0 }}>
					<img
						ref={imageRef}
						id="use"
						style={{ width: "100%" }}
						src={cover}
						alt="cover"
						useMap="#image-map"
						onLoad={() => onImageLoaded()}
					/>
				</div>
			</div>
		</>
	);
}
