import styled from "styled-components/macro";

export const Container = styled.div`
	width: 100%;
	height: 87vh;
	display: flex;
	align-items: center;
	justify-content: center;
	color: white;

	@media (max-width: 1024px) {
		height: auto;
	}
`;

export const MainWrapper = styled.div`
	width: 40%;
	border-radius: 7px;
	border: none;
	box-shadow: 0 0 10px black;
	background-color: rgb(37, 41, 46);

	@media (max-width: 1366px) {
		margin-top: 0;
		margin-bottom: 0;
		width: 60%;
	}

	@media (max-height: 769px) {
		margin-top: 15vh;
		margin-bottom: 15vh;
		width: 90%;
	}
`;

export const Label = styled.label`
	font-size: ${({ size }) => size};
	min-width: 100px;
`;

export const Navbar = styled.div`
	width: 100%;
	height: 10%;
	background-color: #455059;
	border-radius: 7px 7px 0 0;
	box-shadow: 0 1px 3px rgba(0, 0, 0, 0.5);
	display: flex;
	align-items: center;
	padding: 20px 0 20px;
`;

export const Logout = styled.div`
	border-radius: 4px;
	border: solid 2px white;
	padding: 5px 20px 5px;
	display: flex;
	font-size: 22px;
	align-items: center;
	transition: 0.3s;
	cursor: pointer;

	&:hover {
		box-shadow: 0 0 10px black;
	}

	&:active {
		box-shadow: none;
		color: grey;
		border-color: gray;
	}
`;

export const InfoWrapper = styled.div`
	width: 100%;
`;

export const InputField = styled.input`
	font-family: "Nunito", sans-serif;
	border: solid 1px #455059;
	border-radius: 3px;
	background-color: #25292e;
	font-size: 16px;
	color: white;
	padding: 10px 10px 10px;
	transition: 0.3s;
	width: 25vw;
	min-width: 300px;

	&:focus {
		outline: none;
		border: solid 1px white;
	}
`;

export const Button = styled.button`
	border: solid 1px #455059;
	background-color: #25292e;
	padding: 10px 20px 10px;
	border-radius: 2px;
	color: white;
	transition: 0.3s;
	margin-bottom: 30px;

	&:hover {
		color: black;
		background-color: white;
	}
`;
