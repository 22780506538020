import React, { useState, useEffect } from "react";

import styled from "styled-components/macro";
import { Account } from "../components";

import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import queryString from "query-string";
import { validateResetToken, resetPassword } from "../services/auth.service";
import { useHistory } from "react-router-dom";

const Container = styled.div`
	width: 50%;
	min-width: 200px;
	padding: 5vh 3% 5vh;
	border: solid 2px #fffe4b;
	border-radius: 2px;
	box-shadow: 0 0 20px rgb(0, 0, 0, 0.5);
	background-color: #202326;
	color: white;
	z-index: 12;
	overflow-y: scroll;

	@media (max-width: 1024px) {
		max-height: 80vh;
		width: 80%;
	}
`;

const Header = styled.label`
	width: 100%;
	display: flex;
	justify-content: center;
	color: white;
	font-size: 24px;
	margin-bottom: 30px;
`;

const TokenStatus = {
	Validating: "Validating",
	Valid: "Valid",
	Invalid: "Invalid",
};

export default function ResetPasswordRedirect({ setSuccessPopup }) {
	const history = useHistory();
	const [token, setToken] = useState(null);
	const [tokenStatus, setTokenStatus] = useState(TokenStatus.Validating);

	useEffect(() => {
		const { token } = queryString.parse(window.location.search);
		history.replace(window.location.pathname);
		validateResetToken(token)
			.then((response) => response.json())
			.then((data) => {
				setToken(token);
				setTokenStatus(TokenStatus.Valid);
			})
			.catch(() => {
				setTokenStatus(TokenStatus.Invalid);
			});
	}, []);

	function getForm() {
		const handlePasswordSubmit = ({ password, confirmPassword }, { setSubmitting, setErrors }) => {
			setSubmitting(true);
			resetPassword({ password, confirmPassword, token })
				.then((response) => response.json())
				.then((data) => {
					if (data.success) {
						history.push("/");
						setSuccessPopup(true);
						// TODO(omer): popup message
					} else {
						setErrors(data);
					}
				})
				.catch((error) => {
					alert(error);
				});

			setSubmitting(false);
		};
		const PasswordSchema = Yup.object().shape({
			password: Yup.string()
				.required("Şifre girmeniz zorunludur.")
				.min(8, "Şifreniz çok kısa - şifreniz en az 8 karakterden oluşmalı."),
			confirmPassword: Yup.string().oneOf([Yup.ref("password"), null], "Şifreniz eşleşmeli."),
		});
		return (
			<Container>
				<Header>Lütfen Şifrenizi yenileyiniz</Header>

				<Formik
					initialValues={{ password: "", confirmPassword: "" }}
					validationSchema={PasswordSchema}
					onSubmit={handlePasswordSubmit}
				>
					{({ isSubmitting, errors, touched }) => (
						<Form spellCheck={false}>
							<div className="row-wrapper">
								<Account.Label size="16px">Yeni Şifre:</Account.Label>
								<div
									style={{
										width: "100%",
										display: "flex",
										flexDirection: "column",
										alignItems: "flex-end",
									}}
								>
									<Field spellCheck="false" name="password" type="password" as={Account.InputField} />
									{errors.password && touched.password ? (
										<div style={{ color: "red", marginBottom: 0, marginTop: "20px" }}>{errors.password}</div>
									) : null}
								</div>
							</div>

							<div className="row-wrapper">
								<Account.Label size="16px">Yeni Şifre Tekrar:</Account.Label>
								<div
									style={{
										width: "100%",
										display: "flex",
										flexDirection: "column",
										alignItems: "flex-end",
									}}
								>
									<Field spellCheck="false" name="confirmPassword" type="password" as={Account.InputField} />
									{errors.confirmPassword && touched.confirmPassword ? (
										<div style={{ color: "red", marginBottom: 0, marginTop: "20px" }}>
											{errors.confirmPassword}
										</div>
									) : null}
								</div>
							</div>

							<div className="text-center" style={{ marginTop: 0 }}>
								<Account.Button
									type="submit"
									disabled={isSubmitting}
									button={"sifre-guncelle"}
									style={{ marginTop: "30px", marginBottom: 0 }}
								>
									Güncelle
								</Account.Button>
							</div>
						</Form>
					)}
				</Formik>
			</Container>
		);
	}

	function getBody() {
		switch (tokenStatus) {
			case TokenStatus.Valid:
				return getForm();

			case TokenStatus.Validating:
				return <div>Lutfen bekleyin</div>;
			default:
				return <div>Bir hata olustu</div>;
		}
	}

	return (
		<div style={{ width: "100vw", height: "100vh", display: "flex", alignItems: "center", justifyContent: "center" }}>
			{getBody()}
		</div>
	);
}
