import { Refresh } from "@material-ui/icons";
import { getIn } from "formik";
import { createContext, useState, useEffect } from "react";
import { post, postAuthorized, getAuthorized, getToken } from "../services/auth.service";

export const UserContext = createContext({ name: "", access_token: "" });

export const UserProvider = ({ children }) => {
    const [user, setUser] = useState({ name: "", realName: "", access_token: "" });
    const [logged, setLogged] = useState(false);

    const getInfo = () => {
        getAuthorized("info")
            .then((response) => {
                if (response.status === 200) {
                    return response.json();
                }
                return null;
            })
            .then((data) => {
                if (data) {
                    setUser({ realName: data.realName, name: data.name, access_token: getToken() });
                }
            });
    };

    useEffect(() => {
        getInfo();
    }, []);

    useEffect(() => {
        setLogged(user && user.access_token !== "");
    }, [user]);

    const refreshInfo = () => {
        getInfo();
    };

    const register = async (name, email, password) => {
        const response = await post(
            {
                name: name,
                email: email,
                password: password,
            },
            "signup"
        );
        const data = await response.json();
        if (data.access_token) {
            setUser({
                realName: data.realName,
                name: email,
                access_token: data.access_token,
            });
            localStorage.setItem("access_token", data.access_token);
        }
        return data;
    };

    const changePassword = async (oldPassword, newPassword) => {
        const response = await postAuthorized(
            {
                oldPassword: oldPassword,
                newPassword: newPassword,
            },
            "updatePassword"
        );
        return await response.json();
    };

    const changeRealName = async (realName) => {
        const response = await postAuthorized(
            {
                newName: realName,
            },
            "updateName"
        );
        return await response.json();
    };

    const login = async (email, password) => {
        const response = await postAuthorized(
            {
                email: email,
                password: password,
            },
            "signin"
        );
        const data = await response.json();
        // TODO: Check response status code and handle errors
        if (data.access_token) {
            localStorage.setItem("access_token", data.access_token);
            setUser({
                realName: data.realName,
                name: email,
                access_token: data.access_token,
            });
        }

        return data;
    };

    const logout = () => {
        setUser({ name: "", realName: "", access_token: "" });
        localStorage.removeItem("access_token");
    };

    return (
        <UserContext.Provider
            value={{
                user,
                login,
                logout,
                register,
                logged,
                changePassword,
                changeRealName,
                refreshInfo,
            }}
        >
            {children}
        </UserContext.Provider>
    );
};
