import React, { useState } from "react";
import {
    Container,
    ViewContainer,
    MainContainer,
    SideContainer,
    SearchContainer,
    SearchBar,
    SearchLabel,
    SearchButton,
    SearchBarContainer,
    UsersContainer,
    UserContainer,
    UserLogo,
    UserLogoContainer,
    UserName,
    UserLastTime,
    UserStatusIcon,
    InputContainer,
    InputField,
    InputSendButton,
    InputEmojiButton,
    CurrentUserContainer,
    CurrentUserSubContainer,
    UserStatus,
    TextContainer,
    OwnText,
    OtherText,
    TextTime,
    OtherTextBox,
    OwnTextBox,
    MiscOtherWrapper,
    MiscOwnWrapper,
    TextActionButton,
    Wrapper,
    NotSelected,
    NotLoggedIn,
    Loading,
    WarnText,
    SmalLWarnText,
    IconWrapper,
    Notification,
} from "./styles";
import { BsChatDots } from "react-icons/bs";
import { RiLock2Line } from "react-icons/ri";
import { AiOutlineLoading3Quarters } from "react-icons/ai";
import { AnimatePresence } from "framer-motion";
import { Signin, Signup } from "../../pages";

export default function Chat({ children, ...restProps }) {
    return <Container {...restProps}>{children}</Container>;
}

// =============== BIG CONTAINERS ===================

Chat.ViewContainer = function ChatViewContainer({ children, ...restProps }) {
    return <ViewContainer {...restProps}>{children}</ViewContainer>;
};

Chat.MainContainer = function ChatMainContainer({ children, ...restProps }) {
    return <MainContainer {...restProps}>{children}</MainContainer>;
};

Chat.SideContainer = function ChatSideContainer({ children, ...restProps }) {
    return <SideContainer {...restProps}>{children}</SideContainer>;
};

// =============== SEARCH BAR ===================

Chat.SearchContainer = function ChatSearchContainer({ children, ...restProps }) {
    return <SearchContainer {...restProps}>{children}</SearchContainer>;
};
Chat.SearchBar = function ChatSearchBar({ children, ...restProps }) {
    return <SearchBar {...restProps}>{children}</SearchBar>;
};
Chat.SearchLabel = function ChatSearchLabel({ children, ...restProps }) {
    return <SearchLabel {...restProps}>{children}</SearchLabel>;
};

Chat.SearchButton = function ChatSearchButton({ children, ...restProps }) {
    return <SearchButton {...restProps}>{children}</SearchButton>;
};

Chat.SearchBarContainer = function ChatSearchButtonContainer({ children, ...restProps }) {
    return <SearchBarContainer {...restProps}>{children}</SearchBarContainer>;
};

// =============== USERS ===================

Chat.UsersContainer = function ChatUsersContainer({ children, ...restProps }) {
    return <UsersContainer {...restProps}>{children}</UsersContainer>;
};

Chat.UserContainer = function ChatUserContainer({ children, ...restProps }) {
    return <UserContainer {...restProps}>{children}</UserContainer>;
};

Chat.UserLogo = function ChatUserLogo({ children, ...restProps }) {
    return <UserLogo {...restProps}>{children}</UserLogo>;
};

Chat.UserLogoContainer = function ChatUserLogoContainer({ children, ...restProps }) {
    return <UserLogoContainer {...restProps}>{children}</UserLogoContainer>;
};

Chat.UserStatusIcon = function ChatUserStatusIcon({ children, ...restProps }) {
    return <UserStatusIcon {...restProps}>{children}</UserStatusIcon>;
};

Chat.UserName = function ChatUserName({ children, ...restProps }) {
    return <UserName {...restProps}>{children}</UserName>;
};

Chat.UserLastTime = function ChatUserLastTime({ children, ...restProps }) {
    return <UserLastTime {...restProps}>{children}</UserLastTime>;
};

Chat.CurrentUserSubContainer = function ChatCurrentUserSubContainer({ children, ...restProps }) {
    return <CurrentUserSubContainer {...restProps}>{children}</CurrentUserSubContainer>;
};

// =============== INPUT ===================

Chat.InputContainer = function ChatInputContainer({ children, ...restProps }) {
    return <InputContainer {...restProps}>{children}</InputContainer>;
};

Chat.InputField = function ChatInputField({ children, ...restProps }) {
    return <InputField {...restProps}>{children}</InputField>;
};

Chat.InputSendButton = function ChatInputSendButton({ children, ...restProps }) {
    return <InputSendButton {...restProps}>{children}</InputSendButton>;
};

Chat.InputEmojiButton = function ChatInputEmojiButton({ children, ...restProps }) {
    return <InputEmojiButton {...restProps}>{children}</InputEmojiButton>;
};

// =============== VIEW ===================

Chat.ViewContainer = function ChatViewContainer({ children, ...restProps }) {
    return <ViewContainer {...restProps}>{children}</ViewContainer>;
};

Chat.CurrentUserContainer = function ChatCurrentUserContainer({ children, ...restProps }) {
    return <CurrentUserContainer {...restProps}>{children}</CurrentUserContainer>;
};

Chat.UserStatus = function ChatUserStatus({ children, ...restProps }) {
    return <UserStatus {...restProps}>{children}</UserStatus>;
};

Chat.TextContainer = function ChatTextContainer({ children, ...restProps }) {
    return <TextContainer {...restProps}>{children}</TextContainer>;
};

Chat.OwnText = function ChatOwnText({ children, ...restProps }) {
    return <OwnText {...restProps}>{children}</OwnText>;
};

Chat.OtherText = function ChatOtherText({ children, ...restProps }) {
    return <OtherText {...restProps}>{children}</OtherText>;
};

Chat.TextTime = function ChatTextTime({ children, ...restProps }) {
    return <TextTime {...restProps}>{children}</TextTime>;
};

Chat.OwnTextBox = function ChatOwnTextBox({ children, ...restProps }) {
    return <OwnTextBox {...restProps}>{children}</OwnTextBox>;
};

Chat.OtherTextBox = function ChatOtherTextBox({ children, ...restProps }) {
    return <OtherTextBox {...restProps}>{children}</OtherTextBox>;
};

Chat.TextActionButton = function ChatTextActionButton({ children, ...restProps }) {
    return <TextActionButton {...restProps}>{children}</TextActionButton>;
};

// ================= MISC ==================

Chat.Wrapper = function ChatWrapper({ children, ...restProps }) {
    return <Wrapper {...restProps}>{children}</Wrapper>;
};

Chat.NotSelected = function ChatNotSelected({ ...restProps }) {
    return (
        <NotSelected {...restProps}>
            <BsChatDots size="4rem" />
            <WarnText>Mesajlaşmaya başlamak için bir kullanıcı seçin.</WarnText>
        </NotSelected>
    );
};

Chat.MiscOwnWrapper = function ChatMiscOwnWrapper({ children, ...restProps }) {
    return <MiscOwnWrapper {...restProps}>{children}</MiscOwnWrapper>;
};
Chat.MiscOtherWrapper = function ChatMiscOtherWrapper({ children, ...restProps }) {
    return <MiscOtherWrapper {...restProps}>{children}</MiscOtherWrapper>;
};

Chat.NotLoggedIn = function ChatNotLoggedIn({
    setCurrentNav,
    setIsPopupActive,
    resetPasswordClick,
    setResetPasswordClick,
    ...restProps
}) {
    const [signInClick, setSignInClick] = useState(false);
    const [signUpClick, setSignUpClick] = useState(false);

    return (
        <>
            <NotLoggedIn {...restProps}>
                <RiLock2Line size="4rem" />
                <WarnText>
                    Mesajlaşmak için lütfen{" "}
                    <span
                        style={{
                            cursor: "pointer",
                            textShadow: "0 0 1px white,0 0 1px white,0 0 1px white",
                            textDecoration: "underline",
                        }}
                        onClick={() => setSignUpClick(true)}
                    >
                        üye
                    </span>{" "}
                    olun.
                </WarnText>
                <SmalLWarnText>
                    Zaten üye misiniz?{" "}
                    <span
                        style={{
                            cursor: "pointer",
                            textShadow: "0 0 1px white,0 0 1px white,0 0 1px white",
                            textDecoration: "underline",
                        }}
                        onClick={() => setSignInClick(true)}
                    >
                        Giriş
                    </span>{" "}
                    yapın!
                </SmalLWarnText>
            </NotLoggedIn>
            <AnimatePresence exitBeforeEnter>
                {signInClick && (
                    <Signin
                        redirectTo="/chat"
                        shouldChangeURL={false}
                        setIsPopupActive={setIsPopupActive}
                        setPasswordResetClick={resetPasswordClick}
                        setCurrentNav={setCurrentNav}
                        setSignUpClick={setSignUpClick}
                        setSignInClick={setSignInClick}
                    />
                )}
                {signUpClick && (
                    <Signup
                        redirectTo="/chat"
                        shouldChangeURL={false}
                        setIsPopupActive={setIsPopupActive}
                        setPasswordResetClick={setResetPasswordClick}
                        setCurrentNav={setCurrentNav}
                        setSignUpClick={setSignUpClick}
                        setSignInClick={setSignInClick}
                    />
                )}
            </AnimatePresence>
        </>
    );
};

Chat.Loading = function ChatLoading({ ...restProps }) {
    return (
        <Loading>
            <IconWrapper
                animate={{ rotate: 360 }}
                transition={{ repeat: Infinity, duration: 2, ease: [0.43, 0.13, 0.23, 0.96] }}
            >
                <AiOutlineLoading3Quarters size="6rem" />
            </IconWrapper>
            <WarnText>Bağlanılıyor...</WarnText>
        </Loading>
    );
};

Chat.Notification = function ChatNotification({ children, ...restProps }) {
    return <Notification {...restProps}>{children}</Notification>;
};
