import React, { useState, useRef, useEffect } from "react";
import styled from "styled-components/macro";
import { Girisim, AbsoluteButtonContainer } from "../components";
import ReactLoading from "react-loading";
import {
  BrowserRouter as Router,
  Switch,
  Link,
  useParams,
  useHistory,
} from "react-router-dom";

import Slider from "react-slick";
import { motion, AnimatePresence } from "framer-motion";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./style.css";

import { useMediaQuery } from "react-responsive";

import firebase from "../helpers/firebase";

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
`;

const Loading = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  position: absolute;
  z-index: 99;
  background-color: #17191c;

  width: 100%;
  height: 100%;
`;

const VideoContainer = styled(motion.div)`
  display: flex;

  width: 60%;
  height: 60%;
  justify-content: center;
  align-items: center;

  @media (max-width: 823px) {
    width: 90%;
    height: 90%;
  }
`;

const AbsoluteCenter = styled.div`
  position: absolute;
  top: 40%;
  height: 50%;
  z-index: 4;
`;

const ImprovedSlider = styled(Slider)`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 800px;
  padding: 0 40px 0;

  @media (max-width: 1024px) {
    width: 650px;
  }

  @media (max-width: 823px) {
    width: 400px;
  }

  @media (max-width: 568px) {
    width: 300px;
  }
`;

const Chevron = styled.div`
  transform: scale(3);
  transition: 0.3s;

  &:hover {
    transform: scale(4);
  }

  &:active {
    transform: scale(3.5);
  }
`;

const SmallChevron = styled.div`
  transform: scale(2);
  transition: 0.3s;

  &:hover {
    transform: scale(2.5);
  }

  &:active {
    transform: scale(2.2);
  }
`;

function StyledArrow({
  className,
  style,
  onClick,
  smallSlickRef,
  type,
  isSmall,
}) {
  return (
    <Chevron
      className={className}
      style={style}
      onClick={() => {
        !isSmall
          ? type === "next"
            ? smallSlickRef.current.slickNext()
            : smallSlickRef.current.slickPrev()
          : console.log("small");
        onClick();
      }}
    />
  );
}

function StyledSmallArrow({ className, style, onClick }) {
  return <SmallChevron className={className} style={style} onClick={onClick} />;
}

export default function GirisimciContainer({
  setMailOpen,
  setKartOpen,
  setIsPopupActive,
  popupVideo,
  setPopupVideo,
  handleEmailPopupClick,
}) {
  const { id } = useParams();
  const bigSlickRef = useRef(null);
  const smallSlickRef = useRef(null);
  const ref = useRef(null);
  const history = useHistory();
  const [standInfos, setStandInfo] = useState([]);
  const [isReadyToShow, setIsReadyToShow] = useState();

  const [sliderKey, setSliderKey] = useState(0);
  const [hasLoadedAllImages, setHasLoadedAllImages] = useState(false);
  const isSmall = useMediaQuery({ query: "(max-width: 823px)" });

  useEffect(() => {
    function handleClickOutside(event) {
      if (event.target === ref.current) {
        setPopupVideo(false);
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [setPopupVideo, popupVideo]);

  const isStartupInfosValid = () => {
    return standInfos && Array.isArray(standInfos) && standInfos.length;
  };

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    swipe: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: (
      <StyledArrow
        smallSlickRef={smallSlickRef}
        type="next"
        isSmall={isSmall}
      />
    ),
    prevArrow: (
      <StyledArrow
        smallSlickRef={smallSlickRef}
        type="prev"
        isSmall={isSmall}
      />
    ),
  };

  const smallSettings = {
    dots: false,
    centerMode: true,
    infinite: true,
    speed: 500,
    slidesToShow: 5,
    focusOnSelect: true,
    swipeToSlide: true,
    arrows: false,

    responsive: [
      {
        breakpoint: 800,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 2,
          infite: true,
          dots: false,
        },
      },
    ],
  };

  useEffect(() => {
    setTimeout(() => setIsReadyToShow(true), 5000);
  }, [hasLoadedAllImages]);

  const onImageLoad = (idx) => {
    standInfos[idx].isLoaded = true;
    if (id === idx.toString() && !hasLoadedAllImages) {
      setSliderKey((prev) => prev + 1);
      setHasLoadedAllImages(true);
    }
    setStandInfo([...standInfos]);
  };

  useEffect(() => {
    if (bigSlickRef.current) bigSlickRef.current.slickGoTo(id, true);
    if (smallSlickRef.current) smallSlickRef.current.slickGoTo(id, true);
  }, [sliderKey, bigSlickRef]);

  useEffect(() => {
    firebase
      .firestore()
      .collection("startups")
      .orderBy("sort", "asc")
      .get()
      .then((startups) => {
        let startup = startups.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setStandInfo(startup);
      });
    // const fetchData = () => {
    //   const request = {
    //     method: "GET",
    //     mode: "cors",
    //     headers: {
    //       "Content-Type": "application/json",
    //       Authorization: `Bearer ${localStorage.getItem("access_token")}`,
    //     },
    //   };

    //   fetch("https://bigbang2020-api.azurewebsites.net/startup/all", request)
    //     .then((response) => response.json())
    //     .then((data) => {
    //       setStandInfo(data);
    //       //data.map((x) => setFirebase(x));
    //     });
    // };

    // fetchData();
  }, [id]);

  return (
    <>
      {!isReadyToShow && (
        <Loading>
          <ReactLoading type="balls" color="white" width={100} height={100} />
          <h1 style={{ color: "white" }}>Girişimciler yükleniyor...</h1>
        </Loading>
      )}
      <Wrapper>
        <Girisim>
          <Girisim.Carousel>
            {!hasLoadedAllImages && (
              <AbsoluteCenter>
                <ReactLoading
                  type="spin"
                  color="white"
                  width={100}
                  height={100}
                />
              </AbsoluteCenter>
            )}

            {isStartupInfosValid() && (
              <ImprovedSlider
                key={sliderKey}
                ref={bigSlickRef}
                afterChange={(e) => {
                  history.push(`/girisim/${e}`);
                }}
                {...settings}
                style={{ cursor: "pointer" }}
              >
                {standInfos.map((stand, index) => (
                  <img
                    style={{
                      display: stand.isLoaded ? "block" : "none",
                      cursor: "pointer",
                    }}
                    key={stand.projectName}
                    onLoad={() => onImageLoad(index)}
                    src={stand.startupStand}
                    alt={stand.projectName}
                    onClick={() => {
                      setPopupVideo(true);
                    }}
                  />
                ))}
              </ImprovedSlider>
            )}
          </Girisim.Carousel>
          {!isSmall && isStartupInfosValid() && (
            <Girisim.SideBar standInfo={standInfos[id]} />
          )}
          {!isSmall && isStartupInfosValid() && (
            <Girisim.BottomBar>
              <Slider
                ref={smallSlickRef}
                {...smallSettings}
                afterChange={(e) => {
                  bigSlickRef.current.slickGoTo(e);
                }}
                style={{
                  borderRadius: "10px",
                  padding: "0 80px 0",
                }}
              >
                {standInfos.map((stand, i) => (
                  <div className="noneImage" key={i}>
                    <img
                      key={stand.projectName}
                      src={stand.projectStandThumb}
                      alt={stand.projectName}
                    />
                  </div>
                ))}
              </Slider>
            </Girisim.BottomBar>
          )}
          {!isSmall && (
            <Girisim.EmptyBar>
              {isStartupInfosValid() && (
                <AbsoluteButtonContainer
                  setKartOpen={setKartOpen}
                  setMailOpen={setMailOpen}
                  setIsPopupActive={setIsPopupActive}
                  startupEmail={standInfos[id].email}
                  startupLinkedInUrl={standInfos[id].linkedin}
                  handleEmailPopupClick={handleEmailPopupClick}
                />
              )}
            </Girisim.EmptyBar>
          )}
        </Girisim>
      </Wrapper>
      <AnimatePresence exitBeforeEnter>
        {popupVideo && (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            ref={ref}
            style={{
              width: "100vw",
              height: "100vh",
              position: "absolute",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              top: 0,
              zIndex: 100,
              backgroundColor: "rgba(0,0,0,0.8)",
            }}
          >
            <VideoContainer
              initial={{ scale: 0.5 }}
              animate={{ scale: 1 }}
              exit={{ scale: 0.5 }}
              transition={{ ease: "linear" }}
            >
              <motion.iframe
                style={{
                  boxShadow: "0 0 30px rgba(0,0,0,0.6)",
                  border: "solid 1px rgb(38, 41, 46)",
                  borderRadius: "5px",
                }}
                width="90%"
                height="90%"
                src={standInfos[id].projectVideo}
                frameBorder="0"
                allowFullScreen
                title="main"
              />
            </VideoContainer>
          </motion.div>
        )}
      </AnimatePresence>
    </>
  );
}
