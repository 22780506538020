import React, { useEffect } from "react";
import { motion } from "framer-motion";
import effect from "../../videos/invesment_video_6sec.mp4";
import styled from "styled-components/macro";
import arrow from "../../images/arrow.png";
import kese from "../../images/kese.png";
import yildiz from "../../images/yildiz.png";
import kagit from "../../images/kagit.png";

const Wrapper = styled(motion.div)`
	height: 100vh;
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	position: absolute;
	top: 0;
	z-index: 999;
	color: white;
	overflow: hidden;
	-webkit-user-select: none; /* Safari */
	-ms-user-select: none; /* IE 10 and IE 11 */
	user-select: none; /* Standard syntax */
`;

const Video = styled.video`
	position: absolute;
	z-index: -1;
	height: 100vh;
	object-fit: cover;
	filter: brightness(0.5);

	@media (max-aspect-ratio: 812/375) {
		width: 100vw;
		height: auto;
	}
`;

const Image = styled(motion.img)`
	width: 200px;
	margin-bottom: 30px;

	@media (max-width: 1366px) {
		width: 150px;
	}

	@media (max-height: 414px) {
		width: 125px;
	}

	@media (max-height: 320px) {
		width: 80px;
	}
`;

const Type = styled(motion.h1)`
	font-size: 100px;
	display: flex;
	flex-direction: column;
	align-items: center;
	padding: 1vh 2vw 1vh;

	@media (max-width: 1366px) {
		font-size: 80px;
	}

	@media (max-width: 1024px) {
		font-size: 60px;
	}

	@media (max-height: 414px) {
		font-size: 30px;
	}
`;

const StyledDiv = styled(motion.div)`
	display: flex;
	max-width: 100vw;
	width: 100%;
	align-items: center;
	justify-content: center;
`;

const Text = styled(motion.div)`
	-webkit-text-stroke-width: 1px;
	-webkit-text-stroke-color: white;
	text-shadow: 0 1px 20px black;
	display: flex;
	align-items: center;
	justify-content: center;
	text-align: center;
	width: 100%;
	font-size: 40px;

	@media (max-width: 1366px) {
		font-size: 35px;
	}

	@media (max-width: 1366px) {
		font-size: 60px;
	}

	@media (max-width: 1024px) {
		font-size: 30px;
	}

	@media (max-height: 414px) {
		font-size: 22px;
	}
`;

const Arrow = styled(motion.div)`
	display: flex;
	justify-content: center;
	width: 300px;
	align-items: center;
	height: 100px;

	@media (max-height: 414px) {
		height: 50px;
		width: 150px;
	}

	@media (max-height: 320px) {
		height: 25px;
		width: 75px;
	}
`;

const Box = styled(motion.div)`
	width: 60%;
	overflow: hidden;
	font-size: 23px;
	text-shadow: 0 0 5px black;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;

	@media (max-width: 1366px) {
		font-size: 20px;
	}

	@media (max-width: 1024px) {
		font-size: 16px;
	}

	@media (max-height: 414px) {
		font-size: 8px;
	}
`;

const ImageWrapper = styled(motion.div)`
	width: 250px;
	height: 250px;
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;

	@media (max-width: 1366px) {
		width: 175px;
		height: 175px;
	}

	@media (max-height: 414px) {
		width: 125px;
		height: 125px;
	}
`;

const TextType = styled.div`
	color: rgba(242, 150, 0, 1);
	font-size: 36px;

	@media (max-width: 1366px) {
		font-size: 28px;
	}

	@media (max-height: 414px) {
		font-size: 21px;
	}
`;

export default function Donation({ handleAnimationEnd, from, to, type, amount, formattedOutput, ...restProps }) {
	const logoImageList = ["", yildiz, kagit, kese];
	const logoTextList = ["", "Nakit", "Ödül", "Yatırım"];

	return (
		<Wrapper {...restProps}>
			<Video src={effect} autoPlay muted onEnded={handleAnimationEnd} />
			<Box
				initial={{ opacity: 0, scale: 0.5 }}
				animate={{ opacity: 1, scale: 1 }}
				exit={{ y: 50 }}
				transition={{ staggerChildren: 0.5, duration: 2 }}
			>
				<ImageWrapper
					animate={{ scale: [0.9, 1, 0.9] }}
					transition={{
						duration: 2,
						ease: "easeInOut",
						times: [0, 0.5, 1],
						loop: Infinity,
					}}
				>
					<Image initial={{ scale: 0.8 }} animation={{ scale: 1 }} src={logoImageList[type]} alt="type-logo" />
				</ImageWrapper>
				<TextType>{logoTextList[type]}</TextType>

				<Type
					style={{
						fontFamily: "Teko, sans-serif",
						margin: 0,
					}}
					animate={{ scale: [0.9, 1, 0.9] }}
					transition={{
						duration: 2,
						ease: "easeInOut",
						times: [0, 0.5, 1],
						loop: Infinity,
					}}
				>
					{formattedOutput.format(amount)}
				</Type>

				<StyledDiv>
					<Text
						initial={{ x: -100, opacity: 0 }}
						animate={{ x: 0, opacity: 1 }}
						transition={{ delay: 2, duration: 2 }}
						style={{ color: "#edb313" }}
					>
						{from}
					</Text>
					{to && (
						<>
							<Arrow
								animate={{ x: [-20, 20, -20] }}
								transition={{
									duration: 2,
									ease: "easeInOut",
									times: [0, 0.5, 1],
									loop: Infinity,
								}}
							>
								<Image style={{ marginBottom: "0" }} src={arrow} />
							</Arrow>
							<Text
								initial={{ x: 100, opacity: 0 }}
								animate={{ x: 0, opacity: 1 }}
								transition={{ delay: 2, duration: 2 }}
								style={{ color: "#bd5a04" }}
							>
								{to}
							</Text>
						</>
					)}
				</StyledDiv>
			</Box>
		</Wrapper>
	);
}
