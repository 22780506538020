import React, { useState } from "react";
import { Header } from "./styles";
import { Formik, Field, Form } from "formik";
import { handleUserSignUp } from "./FormHelper";
import { toast, ToastContainer } from "react-toastify";
import styled from "styled-components/macro";
import {
    Button,
    FormGroup,
    FormControl,
    InputLabel,
    Input,
    FormHelperText,
    FormLabel,
    RadioGroup,
    FormControlLabel,
    Radio,
} from "@material-ui/core";
import { FaPlus } from "react-icons/fa";
import "./styles.css";

const FileInput = styled.input``;

export const UyelikGiris = () => {
    const [value, setValue] = useState();
    const [isStartUp, setIsStartUp] = useState(false);

    const notifySuccess = () =>
        toast.success("İşlem başarılı!", {
            position: "bottom-center",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });

    const notifyError = () =>
        toast.error("Bir hata oluştu!", {
            position: "bottom-center",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });

    return (
        <>
            <Header>Üyelik giriş formu</Header>

            <FormGroup>
                <Formik
                    initialValues={{
                        name: "",
                        email: "",
                        password: "",
                        startupName: "",
                        startupEmail: "",
                        phone: "",
                        projectName: "",
                        projectDesc: "",
                        linkedIn: "",
                        pdf: "",
                        logo: "",
                        stand: "",
                        standThumb: "",
                        projectVideo: "",
                    }}
                    onSubmit={(data, { setSubmitting, setErrors, resetForm }) => {
                        resetForm({
                            name: "",
                            email: "",
                            password: "",
                            startupName: "",
                            startupEmail: "",
                            phone: "",
                            projectName: "",
                            projectDesc: "",
                            linkedIn: "",
                            pdf: "",
                            logo: "",
                            standThumb: "",
                            stand: "",
                            projectVideo: "",
                        });
                        handleUserSignUp(data, notifySuccess, notifyError, { setSubmitting, setErrors });
                    }}
                >
                    {({ isSubmitting, errors, touched, setFieldValue }) => (
                        <Form spellCheck={false} style={{ display: "flex", flexDirection: "column" }}>
                            <FormControl>
                                <InputLabel htmlFor="name">Ad, Soyad</InputLabel>
                                <Field
                                    style={{ width: "100%", marginBottom: "10px" }}
                                    type="name"
                                    label="Ad, Soyad"
                                    name="name"
                                    as={Input}
                                />
                            </FormControl>
                            <FormControl>
                                <InputLabel htmlFor="email">Email</InputLabel>
                                <Field
                                    style={{ width: "100%", marginBottom: "10px" }}
                                    type="email"
                                    label="Email"
                                    name="email"
                                    as={Input}
                                />
                            </FormControl>
                            <FormControl>
                                <InputLabel htmlFor="password">Password</InputLabel>
                                <Field
                                    style={{ width: "100%", marginBottom: "10px" }}
                                    type="password"
                                    label="Sifre"
                                    name="password"
                                    as={Input}
                                />
                            </FormControl>

                            <FormControl id="radio-control" component="fieldset" style={{ marginTop: "40px" }}>
                                <FormLabel component="legend">Üye Tipi</FormLabel>
                                <RadioGroup
                                    id="radio-group"
                                    aria-label="uyetip"
                                    name="uyetip"
                                    value={value}
                                    onChange={(e) =>
                                        e.target.value === "startup" ? setIsStartUp(true) : setIsStartUp(false)
                                    }
                                >
                                    <FormControlLabel value="startup" control={<Radio />} label="Start Up" />
                                    <FormControlLabel value="yatirimci" control={<Radio />} label="Yatırımcı" />
                                </RadioGroup>
                            </FormControl>
                            {isStartUp && (
                                <>
                                    <FormControl>
                                        <InputLabel htmlFor="startupName">Startup Isim</InputLabel>
                                        <Field
                                            style={{ width: "100%", marginBottom: "10px" }}
                                            type="name"
                                            label="Startup Isim"
                                            name="startupName"
                                            as={Input}
                                        />
                                    </FormControl>
                                    <FormControl>
                                        <InputLabel htmlFor="startupEmail">Startup Email</InputLabel>
                                        <Field
                                            style={{ width: "100%", marginBottom: "10px" }}
                                            type="email"
                                            label="Startup Email"
                                            name="startupEmail"
                                            as={Input}
                                        />
                                    </FormControl>
                                    <FormControl>
                                        <InputLabel htmlFor="phone">Telefon Numara</InputLabel>
                                        <Field
                                            style={{ width: "100%", marginBottom: "10px" }}
                                            type="tel"
                                            label="Telefon Numara"
                                            name="phone"
                                            as={Input}
                                        />
                                    </FormControl>
                                    <FormControl>
                                        <InputLabel htmlFor="projectName">Proje Isim</InputLabel>
                                        <Field
                                            style={{ width: "100%", marginBottom: "10px" }}
                                            type="name"
                                            label="Project Isim"
                                            name="projectName"
                                            as={Input}
                                        />
                                    </FormControl>
                                    <FormControl>
                                        <InputLabel htmlFor="projectDesc">Proje Aciklama</InputLabel>
                                        <Field
                                            style={{ width: "100%", marginBottom: "10px" }}
                                            type="text"
                                            label="Proje Aciklama"
                                            name="projectDesc"
                                            as={Input}
                                        />
                                    </FormControl>
                                    <FormControl>
                                        <InputLabel htmlFor="linkedIn">LinkedIn Url</InputLabel>
                                        <Field
                                            style={{ width: "100%", marginBottom: "10px" }}
                                            type="text"
                                            label="LinkedIn Url"
                                            name="linkedIn"
                                            as={Input}
                                        />
                                    </FormControl>
                                    <FormControl>
                                        <InputLabel htmlFor="projectVideo">Proje Video URL</InputLabel>
                                        <Field
                                            style={{ width: "100%", marginBottom: "10px" }}
                                            type="text"
                                            label="Proje Video URL"
                                            name="projectVideo"
                                            as={Input}
                                        />
                                    </FormControl>
                                    <FormControl style={{ marginTop: "20px" }}>
                                        <InputLabel htmlFor="pdf">Proje PDF</InputLabel>
                                        <Field
                                            style={{ width: "100%", marginBottom: "10px" }}
                                            type="file"
                                            label="Proje PDF"
                                            name="pdf"
                                            as={FileInput}
                                            onChange={(event) => {
                                                setFieldValue("filepdf", event.currentTarget.files[0]);
                                            }}
                                            accept=".pdf"
                                        />
                                    </FormControl>
                                    <FormControl style={{ marginTop: "20px" }}>
                                        <InputLabel htmlFor="standThumb">Stand thumbnail</InputLabel>
                                        <Field
                                            style={{ width: "100%", marginBottom: "10px" }}
                                            type="file"
                                            label="Stand Thumbnail"
                                            name="standThumb"
                                            onChange={(event) => {
                                                setFieldValue("fileStandThumb", event.currentTarget.files[0]);
                                            }}
                                            as={FileInput}
                                            accept="image/*"
                                        />
                                    </FormControl>
                                    <FormControl style={{ marginTop: "20px" }}>
                                        <InputLabel htmlFor="logo">Proje Logo</InputLabel>
                                        <Field
                                            style={{ width: "100%", marginBottom: "10px" }}
                                            type="file"
                                            label="Proje Logo"
                                            name="logo"
                                            as={FileInput}
                                            onChange={(event) => {
                                                setFieldValue("filelogo", event.currentTarget.files[0]);
                                            }}
                                            accept="image/*"
                                        />
                                    </FormControl>
                                    <FormControl style={{ marginTop: "20px" }}>
                                        <InputLabel htmlFor="stand">Stand</InputLabel>
                                        <Field
                                            style={{ width: "100%", marginBottom: "10px" }}
                                            type="file"
                                            label="Stand"
                                            name="stand"
                                            as={FileInput}
                                            onChange={(event) => {
                                                setFieldValue("filestand", event.currentTarget.files[0]);
                                            }}
                                            accept="image/*"
                                        />
                                    </FormControl>
                                </>
                            )}

                            <div style={{ width: "100%", display: "flex", justifyContent: "center" }}>
                                <Button
                                    type="submit"
                                    disabled={isSubmitting}
                                    button="add-user"
                                    variant="contained"
                                    color="primary"
                                    style={{ marginTop: "20px", width: "10%" }}
                                >
                                    <FaPlus />
                                    &nbsp; Ekle
                                </Button>
                            </div>
                            <ToastContainer
                                position="bottom-center"
                                autoClose={3000}
                                hideProgressBar={false}
                                newestOnTop
                                closeOnClick
                                rtl={false}
                                pauseOnFocusLoss
                                draggable
                                pauseOnHover
                            />
                        </Form>
                    )}
                </Formik>
            </FormGroup>
        </>
    );
};
