import React from "react";
import { useHistory } from "react-router-dom";
import styled from "styled-components/macro";

const NavigationBox = styled.div`
	border-radius: 10px;
	border: solid 5px rgba(255, 255, 255, 0.7);
	background-color: transparent;
	position: absolute;
	top: 15vh;
	left: 1vw;
	z-index: 99;
	display: grid;
	grid-template-columns: 1fr 1fr;
	grid-template-rows: 1fr 1fr;
	justify-content: center;
	transition: 0.3s;
	cursor: pointer;

	&:hover {
		transform: scale(1.05);
	}
`;

const BoxItem = styled.div`
	width: 60px;
	height: 60px;

	margin: 4px;
	background-color: rgba(255, 255, 255, 0.7);
	border-radius: 5px;
`;

export default function NavBoxContainer({ setCurrentNav }) {
	const history = useHistory();

	return (
		<NavigationBox
			onClick={() => {
				setCurrentNav("four");
				history.push("/four-page");
			}}
		>
			<BoxItem />
			<BoxItem />
			<BoxItem />
			<BoxItem />
		</NavigationBox>
	);
}
