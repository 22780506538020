import React, { useRef, useEffect, useContext, useState } from "react";
import { CgCloseO } from "react-icons/cg";
import { Formik, Field, Form } from "formik";
import { Mail } from "..";
import ReactLoading from "react-loading";
import { FormComponent } from "..";
import styled from "styled-components/macro";
import { sendCard } from "../../services/investment.service";

const initial = { opacity: 0, y: 5 };
const exit = { opacity: 0, y: 5 };
const animate = { opacity: 1, y: 0 };
const transition = { duration: 0.3, ease: [0.43, 0.13, 0.23, 0.96] };

const InputWrapper = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
    justify-content: space-between;

    & > * {
        margin-bottom: 5px;
        height: 40px;
    }
`;

export default function KartPopup({ setKartOpen, setIsPopupActive, targetMail }) {
    const ref = useRef(null);
    const FORM_STATE = { SUCCESS: 0, ERROR: 1, HOLD: 2 };
    const [isSuccess, setIsSuccess] = useState(FORM_STATE.HOLD);

    useEffect(() => {
        setIsPopupActive(true);
    }, [setIsPopupActive]);

    useEffect(() => {
        function handleClickOutside(event) {
            if (event.target === ref.current) {
                setKartOpen(false);
                setIsPopupActive(false);
            }
        }
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    });

    function getForm() {
        return (
            <Formik
                initialValues={{ name: "", unvan: "", phone: "", mail: "" }}
                onSubmit={(formData, { setSubmitting, setErrors }) => {
                    setSubmitting(true);

                    sendCard(targetMail, formData.name, formData.unvan, formData.phone, formData.mail)
                        .then((responseData) => {
                            if (responseData.success) {
                                setIsSuccess(FORM_STATE.SUCCESS);
                                setKartOpen(false);
                            } else {
                                setIsSuccess(FORM_STATE.ERROR);
                                setKartOpen(false);
                                setErrors(responseData);
                            }
                            setSubmitting(false);
                        })
                        .catch((error) => {
                            setSubmitting(false);
                        });
                }}
            >
                {({ isSubmitting, errors }) => (
                    <Form style={{ display: "flex", flexDirection: "column" }}>
                        <div
                            ref={ref}
                            style={{
                                width: "100vw",
                                height: "100vh",
                                position: "absolute",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                top: 0,
                                zIndex: 10,
                                backgroundColor: "rgba(0,0,0,0.8)",
                            }}
                        >
                            {!isSubmitting ? (
                                <Mail
                                    style={{ height: "50%", width: "50%" }}
                                    initial={initial}
                                    animate={animate}
                                    exit={exit}
                                    transition={transition}
                                >
                                    <Mail.Navbar>
                                        <Mail.Header>Kartvizitini Paylaş</Mail.Header>
                                        <Mail.Button
                                            whileHover={{ scale: 1.05 }}
                                            whileTap={{ scale: 0.95 }}
                                            onClick={() => {
                                                setKartOpen(false);
                                                setIsPopupActive(false);
                                            }}
                                        >
                                            <CgCloseO size="2.5em" />
                                        </Mail.Button>
                                    </Mail.Navbar>
                                    <div
                                        style={{
                                            display: "flex",
                                            justifyContent: "center",
                                            flexDirection: "column",
                                            alignItems: "center",
                                            height: "85%",
                                        }}
                                    >
                                        <InputWrapper>
                                            <Field
                                                spellCheck="false"
                                                placeholder="Adınız ve soyadınız"
                                                name="name"
                                                type="text"
                                                as={Mail.Input}
                                            />
                                            <Field
                                                spellCheck="false"
                                                placeholder="Ünvanınız"
                                                name="unvan"
                                                type="text"
                                                as={Mail.Input}
                                            />
                                            <Field
                                                spellCheck="false"
                                                placeholder="Telefonunuz"
                                                name="phone"
                                                type="text"
                                                as={Mail.Input}
                                            />
                                            <Field
                                                spellCheck="false"
                                                placeholder="E-posta adresiniz"
                                                name="mail"
                                                type="text"
                                                as={Mail.Input}
                                            />
                                        </InputWrapper>

                                        <Mail.SendButton>Gönder</Mail.SendButton>
                                    </div>
                                </Mail>
                            ) : (
                                <Mail>
                                    <Mail.Navbar>
                                        <Mail.Header>Email gönder...</Mail.Header>
                                        <Mail.Button
                                            whileHover={{ scale: 1.05 }}
                                            whileTap={{ scale: 0.95 }}
                                            onClick={() => {
                                                setKartOpen(false);
                                                setIsPopupActive(false);
                                            }}
                                        >
                                            <CgCloseO size="2.5em" />
                                        </Mail.Button>
                                    </Mail.Navbar>
                                    <div style={{ height: "100%" }}>
                                        <FormComponent.Loading
                                            style={{ height: "85%", minHeight: "auto", padding: "0", color: "white" }}
                                        >
                                            <div
                                                style={{
                                                    display: "flex",
                                                    flexDirection: "column",
                                                    alignItems: "center",
                                                }}
                                            >
                                                <ReactLoading type="spin" color="white" height={64} width={64} />
                                                <FormComponent.Label style={{ marginTop: "20px", marginBottom: 0 }}>
                                                    İşlem gerçekleştiriliyor...
                                                </FormComponent.Label>
                                            </div>
                                        </FormComponent.Loading>
                                    </div>
                                </Mail>
                            )}
                        </div>
                    </Form>
                )}
            </Formik>
        );
    }

    function getBody() {
        switch (isSuccess) {
            case FORM_STATE.SUCCESS:
                return (
                    <div
                        ref={ref}
                        style={{
                            width: "100vw",
                            height: "100vh",
                            position: "absolute",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            top: 0,
                            zIndex: 11,
                            backgroundColor: "rgba(0,0,0,0.8)",
                        }}
                    >
                        <FormComponent style={{ width: "50vw", height: "200px" }} color="success">
                            <FormComponent.Success>
                                <FormComponent.Label>İletiniz başarılı bir şekilde gönderilmiştir.</FormComponent.Label>
                            </FormComponent.Success>
                        </FormComponent>
                    </div>
                );
            case FORM_STATE.HOLD:
                return getForm();
            default:
                return (
                    <div
                        ref={ref}
                        style={{
                            width: "100vw",
                            height: "100vh",
                            position: "absolute",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            top: 0,
                            zIndex: 11,
                            backgroundColor: "rgba(0,0,0,0.8)",
                        }}
                    >
                        <FormComponent style={{ width: "50vw", height: "200px" }} color="error">
                            <FormComponent.Error>
                                <FormComponent.Label>İletinizi gönderirken bir hata oluştu.</FormComponent.Label>
                                <FormComponent.SmallLabel style={{ color: "inherit" }}>
                                    Lütfen daha sonra tekrar deneyiniz...
                                </FormComponent.SmallLabel>
                            </FormComponent.Error>
                        </FormComponent>
                    </div>
                );
        }
    }

    return getBody();
}
