import React from "react";
import { motion, AnimatePresence } from "framer-motion";
import styled from "styled-components/macro";

const PopupBox = styled(motion.div)`
	width: 50%;
	height: 50%;
	border-radius: 10px;
	box-shadow: 0 0 10px black;
	text-align: center;
	display: flex;
	justify-content: center;
	align-items: center;
	border: solid 1px #666;
	background-color: #26292e;
	box-sizing: border-box;
	padding: 50px;

	@media (max-width: 1224px) {
		width: 90%;
		height: 80%;
	}
`;

const InformationMessage = styled.p`
	color: white;
	font-size: 46px;

	@media (max-width: 1224px) {
		font-size: 32px;
	}
	@media (max-width: 731px) {
		font-size: 24px;
	}
`;

const StyledDiv = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100%;
	height: 87vh;
	-webkit-user-select: none; /* Safari */
	-moz-user-select: none; /* Firefox */
	-ms-user-select: none; /* IE10+/Edge */
	user-select: none; /* Standard */
`;

export default function Placeholder({ time }) {
	return (
		<StyledDiv>
			<PopupBox initial={{ opacity: 0.5, y: 20 }} animate={{ opacity: 1, y: 0 }}>
				<InformationMessage>Bu bölüm 3 Aralık 2020 Perşembe, saat 16:45’te aktif hale gelecektir.</InformationMessage>
			</PopupBox>
		</StyledDiv>
	);
}
