// export const API_URL = "https://localhost:6001/auth/";
export const API_URL = "https://api.bigbang2021.prod.cmosteknoloji.com/auth/";

export const postAuthorized = async (payload, uri) => {
    const request = {
        method: "POST",
        mode: "cors",
        headers: { "Content-Type": "application/json", Authorization: `Bearer ${getToken()}` },
        body: JSON.stringify(payload),
    };
    return await fetch(API_URL + uri, request);
};

export const post = async (payload, uri) => {
    const request = {
        method: "POST",
        mode: "cors",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(payload),
    };
    return await fetch(API_URL + uri, request);
};

export const getAuthorized = async (uri) => {
    const request = {
        method: "GET",
        mode: "cors",
        headers: { "Content-Type": "application/json", Authorization: `Bearer ${getToken()}` },
    };
    return await fetch(API_URL + uri, request);
};

export const get = async (uri) => {
    const request = {
        method: "GET",
        mode: "cors",
        headers: { "Content-Type": "application/json", Authorization: `Bearer ${getToken()}` },
    };
    return await fetch(API_URL + uri, request);
};

export const getToken = () => {
    return localStorage.getItem("access_token");
};

export const validateResetToken = (token) => {
    return post({ token }, "validateResetToken");
};

export const resetPassword = ({ token, password, confirmPassword }) => {
    return post({ token, password, confirmPassword }, "resetPassword");
};

export const forgotPassword = (email) => {
    return post({ email }, "forgotPassword");
};
