import firebase from 'firebase/app';
import 'firebase/firestore';

const config = {
  apiKey: "AIzaSyCusBLbMPQdiy-MdiG5zqjfl8jRZQbEw7g",
  authDomain: "itu-bigbang-ba67d.firebaseapp.com",
  projectId: "itu-bigbang-ba67d",
  storageBucket: "itu-bigbang-ba67d.appspot.com",
  messagingSenderId: "799792353057",
  appId: "1:799792353057:web:6e317886f44b96c2c5b860",
  measurementId: "G-8FNWMB226P",
};

firebase.initializeApp(config);

export default firebase;
