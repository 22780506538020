import React, { useLayoutEffect, useRef, useState, useEffect } from "react";
import "./style.css";
import { useMediaQuery } from "react-responsive";
import styled from "styled-components/macro";
import { AnimatePresence, motion } from "framer-motion";
import { useHistory } from "react-router-dom";
import { Footer, HomePageView } from "../components";
import {
	bbSahnePopup,
	danismaPopup,
	girisimcilerPopup,
	maviMikrofonPopup,
	odulYatirimlarPopup,
	kayitMasasiPopup,
} from "../images/popups";

const Wrapper = styled.div`
	height: 100%;
	display: flex;
	align-items: center;
`;

const mappingList = [
	{
		image: bbSahnePopup,
		alt: "map-door-bigbang",
		url: "sahne",
		nav: "canli",
		title: "BIG BANG SAHNE",
		coordinates: [946, 102, 1190, 102, 1194, 406, 954, 401],
		ipadCoordinates: [900, 594, 1168, 594, 1170, 933, 905, 933],
		status: false,
	},
	{
		image: odulYatirimlarPopup,
		alt: "map-door-odul",
		url: "odul",
		nav: "odul",
		title: "ÖDÜL ve YATIRIMLAR",
		coordinates: [1830, 101, 2079, 101, 2075, 409, 1830, 413],
		ipadCoordinates: [1857, 593, 2125, 596, 2119, 930, 1854, 931],
		status: false,
	},
	{
		image: girisimcilerPopup,
		alt: "map-door-girisim",
		url: "girisimler/standlar",
		nav: "girisim",
		title: "GİRİŞİMCİLER",
		coordinates: [958, 484, 962, 796, 1203, 796, 1198, 484],
		ipadCoordinates: [900, 1007, 1170, 1005, 1176, 1352, 908, 1352],
		status: false,
	},
	{
		image: maviMikrofonPopup,
		alt: "map-door-mavi",
		url: "mavi-mikrofon",
		nav: "kirmizi",
		title: "MAVİ MİKROFON",
		coordinates: [1822, 483, 2066, 483, 2066, 799, 1822, 799],
		ipadCoordinates: [1854, 1009, 1854, 1348, 2114, 1343, 2122, 1009],
		status: false,
	},
	{
		image: danismaPopup,
		alt: "map-danisim",
		url: "/test/5",
		nav: "danisma",
		title: "DANIŞMA",
		coordinates: [903, 870, 903, 1233, 1034, 1317, 1375, 1191, 1354, 1144, 1278, 1081, 1262, 815],
		ipadCoordinates: [739, 1524, 739, 1994, 882, 2158, 1356, 1921, 1306, 1839, 1223, 1759, 1218, 1426],
		status: false,
	},
	{
		image: kayitMasasiPopup,
		alt: "map-kayit",
		url: "/test/6",
		nav: "signup",
		title: "KAYIT MASASI",
		coordinates: [1670, 1198, 2054, 1316, 2155, 1219, 2159, 865, 1776, 815, 1759, 1105, 1700, 1143],
		ipadCoordinates: [1735, 1929, 2276, 2138, 2355, 1974, 2360, 1505, 1852, 1428, 1849, 1759, 1761, 1865],
		status: true,
	},
];

const popupCoordinates = {
	canli: { x: 440, y: -50 },
	odul: { x: 2080, y: -50 },
	girisim: { x: 440, y: 350 },
	kirmizi: { x: 2080, y: 350 },
	danisma: { x: 1265, y: 150 },
	signup: { x: 1262, y: 400 },
};

export default function Anasayfa({ setSignUpClick, setCurrentNav, children, ...restProps }) {
	const [mapState, setMapState] = useState(mappingList);
	const [isImageLoaded, setIsImageLoaded] = useState(false);
	const imageRef = useRef(null);
	const history = useHistory();
	const [popup, setPopup] = useState({ state: false, image: "", name: "" });
	const isTabletOrMobile = useMediaQuery({ query: "(min-width: 1367px)" });
	const isIpad = useMediaQuery({ query: "(max-aspect-ratio: 1366/1024)" });
	const [scaledPopupCoords, setScaledPopupCoords] = useState({});

	const onImageLoaded = () => {
		setIsImageLoaded(true);
		const naturalWidth = imageRef.current.naturalWidth * 2;
		const naturalHeight = imageRef.current.naturalHeight * 2;
		const xRatio = window.innerWidth / naturalWidth;
		const yRatio = imageRef.current.clientHeight / naturalHeight;
		const yOffset = (window.innerHeight - imageRef.current.clientHeight) / 2;

		setScaledPopupCoords({
			canli: { x: popupCoordinates.canli.x * xRatio, y: popupCoordinates.canli.y * yRatio + yOffset, scale: 1 },
			odul: { x: popupCoordinates.odul.x * xRatio, y: popupCoordinates.odul.y * yRatio + yOffset, scale: 1 },
			girisim: {
				x: popupCoordinates.girisim.x * xRatio,
				y: popupCoordinates.girisim.y * yRatio + yOffset,
				scale: 1,
			},
			kirmizi: {
				x: popupCoordinates.kirmizi.x * xRatio,
				y: popupCoordinates.kirmizi.y * yRatio + yOffset,
				scale: 1,
			},
			danisma: {
				x: popupCoordinates.danisma.x * xRatio,
				y: popupCoordinates.danisma.y * yRatio + yOffset,
				scale: 1.3,
			},
			signup: {
				x: popupCoordinates.signup.x * xRatio,
				y: popupCoordinates.signup.y * yRatio + yOffset,
				scale: 1,
			},
		});

		const newMappingList = mappingList.map((item) => ({
			...item,
			coordinates: item.coordinates.map((element) => element * xRatio),
		}));
		setMapState(newMappingList);
	};

	useLayoutEffect(() => {
		const naturalWidth = imageRef.current.naturalWidth * 2;
		const xRatio = imageRef.current.width / naturalWidth;

		const newMappingList = mappingList.map((item) => ({
			...item,
			coordinates: item.coordinates.map((element) => element * xRatio),
		}));
		setMapState(newMappingList);
	}, []);

	return (
		<div style={{ position: "relative", overflow: "hidden", alignItems: "center" }}>
			<Wrapper>
				<HomePageView isIpad={isIpad} imageRef={imageRef} onImageLoaded={onImageLoaded} />
			</Wrapper>
			<map name="image-map">
				{mapState.map((item, index) => (
					<area
						key={index}
						alt={item.alt}
						onClick={() => {
							setSignUpClick(item.status);
							setCurrentNav(item.nav);
							item.nav !== "danisma" ? history.push(`/${item.url}`) : history.push();
						}}
						onMouseEnter={(e) => setPopup({ state: true, image: item.image, name: item.nav })}
						onMouseLeave={(e) => setPopup({ state: false, image: item.image, name: item.nav })}
						style={{ cursor: "pointer" }}
						coords={item.coordinates.map(String)}
						shape="poly"
					/>
				))}
			</map>

			<AnimatePresence exitBeforeEnter>
				{popup.state && scaledPopupCoords[popup.name] ? (
					<motion.img
						initial={{ opacity: 0, y: 15, scale: 0.9 }}
						animate={{ opacity: 1, y: 0, scale: scaledPopupCoords[popup.name].scale }}
						exit={{ opacity: 0, y: 15, scale: 0.9 }}
						transition={{ duration: 0.3, ease: [0.43, 0.13, 0.23, 0.96] }}
						style={{
							width: `${window.innerWidth / 6}px`,
							position: "absolute",
							top: `${scaledPopupCoords[popup.name].y}px`,
							left: `${scaledPopupCoords[popup.name].x}px`,
							pointerEvents: "none",
						}}
						src={popup.image}
					/>
				) : null}
			</AnimatePresence>
			<Footer />
		</div>
	);
}
