import React from "react";
import styled from "styled-components/macro";
import { NavBoxContainer } from "../containers";
import { LiveStreamContainer } from "../containers";
import { useMediaQuery } from "react-responsive";
import "./style.css";

const StyledDiv = styled.div`
	width: 100%;
	height: 87vh;
`;

export default function KirmiziOda({ setCurrentNav, children, ...restProps }) {
	const isMobile = useMediaQuery({ query: "(max-width: 823px)" });
	return (
		<>
			{!isMobile && <NavBoxContainer setCurrentNav={setCurrentNav} />}
			<StyledDiv>
				<LiveStreamContainer stream="maviLink" />
			</StyledDiv>
		</>
	);
}
