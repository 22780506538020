import { useState, useEffect, useRef } from "react";
import { getAllInvestmentsTimed } from "../services/investment.service";

const InvestmentTarget = { None: 0, Top20: 1, Fuaye: 2, Ardil: 3, ItuCekirdek: 4 };
const InvestmentType = { None: 0, Nakit: 1, Odul: 2, Yatirim: 3 };

// TODO(selim): Very inefficient implementation. Should only loop once for all startups instead of
// looping for each startup
const getTotalsForStartup = (investments, startupName) => {
	const totals = { odul: 0, nakit: 0, yatirim: 0, total: 0 };
	investments.forEach((investment) => {
		if (investment.startupName === startupName) {
			totals.total += investment.amount;
			switch (investment.investmentType) {
				case InvestmentType.Odul:
					totals.odul += investment.amount;
					break;
				case InvestmentType.Yatirim:
					totals.yatirim += investment.amount;
					break;
				case InvestmentType.Nakit:
					totals.nakit += investment.amount;
					break;
				default:
					break;
			}
		}
	});
	return totals;
};

const generateIndexMapFromTop20 = (top20) => {
	const indexMap = {};
	top20.forEach((x, idx) => (indexMap[x.id] = idx));
	return indexMap;
};

const getTop20FromInvestments = (investments, top20 = null) => {
	const newTop20 = {};
	investments
		.filter((x) => x.investmentTarget === InvestmentTarget.Top20)
		.forEach((x) => {
			if (!newTop20[x.startupName.toUpperCase()]) {
				const totals = getTotalsForStartup(investments, x.startupName);
				newTop20[x.startupName.toUpperCase()] = {
					id: x.id,
					name: x.startupName.toUpperCase(),
					total: totals.total,
					odul: totals.odul,
					nakit: totals.nakit,
					yatirim: totals.yatirim,
				};
			}
		});

	const sortedTop20 = Object.values(newTop20)
		.sort((a, b) => (a.total > b.total ? -1 : 1))
		.slice(0, 20);

	const previousIndexMap = generateIndexMapFromTop20(top20 ? top20 : sortedTop20);
	return sortedTop20.map((x) => {
		return { previousIndex: previousIndexMap[x.id], ...x };
	});
};

export default function useTop20(pollingIntervalDuration, onInitialLoad) {
	// Top20 Structure {
	//      id: int
	//		previousIndex: int
	//      name: string
	//      odul: int
	//      nakit: int
	//      yatirim: int
	// }
	const [top20, setTop20] = useState([]);

	// Investment Structure {
	//      id: int
	//      Amount: long
	//      CurrentEpoch: long
	//      EnableEpoch: long
	//      EnableDate: DateTime
	//      InvestmentTarget: enum(InvestmentTargetType) : int
	//      InvestmentType: enum(InvestmentType) : int
	//      InvestorName: string
	//      StartupName: string
	//      isEnabled: true
	// }
	const [investmentData, setInvestmentData] = useState([]);
	const [lastCalculationTime, setLastCalculationTime] = useState(0);
	const [lastInvestment, setLastInvestment] = useState([]);
	const [shouldPlayAnimation, setShouldPlayAnimation] = useState(false);
	// NOTE(selim): @selim Mixing turkish and english... kinda cringe bro
	const [takipYatirim, setTakipYatirim] = useState(0);
	const [genelTotal, setGenelTotal] = useState(0);
	const [totalNakit, setTotalNakit] = useState(0);
	const [totalOdul, setTotalOdul] = useState(0);
	const [totalYatirim, setTotalYatirim] = useState(0);
	const [fuayeNakit, setFuayeNakit] = useState(0);
	const [fuayeOdul, setFuayeOdul] = useState(0);
	const [fuayeYatirim, setFuayeYatirim] = useState(0);
	const [isSetupComplete, setIsSetupComplete] = useState(false);
	const setupRef = useRef(null);
	setupRef.current = isSetupComplete;

	const onInterval = () => {
		// At each interval record the time (to prevent old animations from playing over and over)
		// and set top20 data
		const isSetupComplete = setupRef.current;
		getAllInvestmentsTimed(true).then((data) => {
			console.log(data);
			if (!isSetupComplete) {
				setIsSetupComplete(true);
				setLastCalculationTime(data.time);
				const initialTop20 = getTop20FromInvestments(data.investments);
				onInitialLoad(initialTop20);
			}
			setInvestmentData(data);
		});
	};

	// Startup Effect
	useEffect(() => {
		const pollingInterval = setInterval(() => onInterval(), pollingIntervalDuration);
		return () => clearInterval(pollingInterval);
	}, [pollingIntervalDuration]);

	// On Every Investments Change (Every Interval)
	useEffect(() => {
		const investments = investmentData.investments;
		const currentTime = investmentData.time;
		if (!investments) return;
		let maxEnableEpoch = 0;
		investments.forEach((x) => {
			// If one of the new investments time is newer than our
			// last calculation, it means there is a new investment
			// that should trigger animation
			Math.max(maxEnableEpoch, x.enableEpoch);
			if (x.enableEpoch > lastCalculationTime && isSetupComplete) {
				setShouldPlayAnimation(true);
				setLastInvestment({
					from: x.investorName,
					to: x.startupName,
					amount: x.amount,
					type: x.investmentType,
				});
			}
		});
		const newTop20 = {};
		investments
			.filter((x) => x.investmentTarget === InvestmentTarget.Top20)
			.forEach((x) => {
				if (!newTop20[x.startupName.toUpperCase()]) {
					const totals = getTotalsForStartup(investments, x.startupName);
					newTop20[x.startupName.toUpperCase()] = {
						id: x.id,
						name: x.startupName.toUpperCase(),
						total: totals.total,
						odul: totals.odul,
						nakit: totals.nakit,
						yatirim: totals.yatirim,
					};
				}
			});

		const fuayeTotals = investments
			.filter((x) => x.investmentTarget === InvestmentTarget.Fuaye)
			.map((x) => {
				return {
					odul: x.investmentType === InvestmentType.Odul ? x.amount : 0,
					nakit: x.investmentType === InvestmentType.Nakit ? x.amount : 0,
					yatirim: x.investmentType === InvestmentType.Yatirim ? x.amount : 0,
				};
			})
			.reduce(
				(prev, { odul, nakit, yatirim }) => {
					prev.odul += odul;
					prev.nakit += nakit;
					prev.yatirim += yatirim;
					return prev;
				},
				{ nakit: 0, odul: 0, yatirim: 0 }
			);

		setFuayeNakit(fuayeTotals.nakit);
		setFuayeOdul(fuayeTotals.odul);
		setFuayeYatirim(fuayeTotals.yatirim);

		const newTakipTotal = investments
			.filter((x) => x.investmentTarget === InvestmentTarget.Ardil)
			.reduce((prev, x) => prev + x.amount, 0);
		setTakipYatirim(newTakipTotal);

		const indexMap = generateIndexMapFromTop20(top20);
		const sortedTop20 = Object.values(newTop20)
			.sort((a, b) => (a.total > b.total ? -1 : 1))
			.map((x) => {
				return { previousIndex: indexMap[x.id], ...x };
			})
			.slice(0, 20);
		setTop20(sortedTop20);
		setLastCalculationTime(currentTime);
	}, [investmentData]);

	useEffect(() => {
		const totals = top20.reduce(
			(prev, { odul, nakit, yatirim }) => {
				prev.odul += odul;
				prev.nakit += nakit;
				prev.yatirim += yatirim;
				return prev;
			},
			{ nakit: 0, odul: 0, yatirim: 0 }
		);
		setTotalNakit(totals.nakit);
		setTotalOdul(totals.odul);
		setTotalYatirim(totals.yatirim);
	}, [top20]);

	useEffect(() => {
		setGenelTotal(totalNakit + totalOdul + totalYatirim + fuayeNakit + fuayeOdul + fuayeYatirim + takipYatirim);
	}, [totalNakit, totalOdul, totalYatirim, fuayeNakit, fuayeOdul, fuayeYatirim, takipYatirim]);

	return {
		top20,
		lastInvestment,
		shouldPlayAnimation,
		setShouldPlayAnimation,
		totalNakit,
		totalOdul,
		totalYatirim,
		fuayeNakit,
		fuayeOdul,
		fuayeYatirim,
		takipYatirim,
		genelTotal,
	};
}
