import styled from "styled-components/macro";

export const Container = styled.div`
	height: 100vh;
	display: grid;
	grid-template-rows: 1fr auto 1fr;

	@media (max-width: 1024px) {
		grid-template-rows: auto;
	}
`;
