import React from "react";
import styled from "styled-components/macro";
import ReactLoading from "react-loading";
import { motion, AnimatePresence } from "framer-motion";

const Wrapper = styled(motion.div)`
	grid-column: 1 / 6;
	overflow: visible;
	min-height: 480px;
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	height: 100%;
	background-color: rgba(0, 0, 0, 0.4);

	@media (max-height: 768px) {
		min-height: 300px;
		overflow-y: scroll;
	}

	@media (max-height: 414px) {
		min-height: 100px;
	}
`;

const SmallWrapper = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
`;

export default function Loading() {
	return (
		<AnimatePresence exitBeforeEnter>
			<Wrapper initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }}>
				<SmallWrapper>
					<ReactLoading type="spin" color="white" width={100} height={100} />
					<h2 style={{ marginTop: "10px" }}>Girişimci verileri yükleniyor...</h2>
				</SmallWrapper>
			</Wrapper>
		</AnimatePresence>
	);
}
