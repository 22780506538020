import React, { useContext } from "react";
import styled from "styled-components/macro";
import footerLogo from "../../images/footerLogo.png";
import { motion } from "framer-motion";
import { RiWechatLine } from "react-icons/ri";
import { UserContext } from "../../context/UserContext";
import { useMediaQuery } from "react-responsive";
import { useHistory } from "react-router-dom";

const Container = styled.div`
    position: absolute;
    z-index: 2;
    width: 100vw;
    background-color: rgba(25, 25, 25, 0.4);
    bottom: 0;
    color: white;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    align-items: center;
`;
const FooterLogo = styled.img`
    height: 42px;
    @media (max-height: 823px) {
        height: 36px;
        margin-right: 40px;
    }
`;

const SocialMedia = styled.div`
    margin-left: auto;
    font-size: 28px;
    display: flex;

    & > * {
        margin-right: 12px;
    }
`;

const LogoA = styled.a`
    color: white;
    display: flex;
    flex-direction: column;
`;

const ChatButton = styled(motion.button)`
    font-size: 18px;
    background-color: transparent;
    border: 2px solid white;
    color: white;
    border-radius: 5px;
    cursor: pointer;
    white-space: nowrap;
    background-color: #223;

    @media (max-width: 823px) {
        font-size: 14px;
    }
`;

export default function Footer({ ...restProps }) {
    const { logged } = useContext(UserContext);
    const history = useHistory();
    return (
        <Container {...restProps}>
            <FooterLogo src={footerLogo} />
            <a
                style={{ color: "white", textDecoration: "none", marginLeft: "auto", marginRight: "auto" }}
                href="https://itucekirdek.com/"
                target="_"
            >
                www.itucekirdek.com
            </a>
            <SocialMedia>
                {/* {logged && (
					<ChatButton whileHover={{ y: -6 }} onClick={() => history.push("/chat/support@bigbang2020.com")}>
						<div style={{ display: "flex", alignItems: "center" }}>
							Sohbet <RiWechatLine size="1.1em" style={{ marginLeft: "5px" }} />
						</div>
					</ChatButton>
				)} */}
                <LogoA href="https://twitter.com/itucekirdek" target="_blank">
                    <motion.svg
                        href="https://twitter.com/itucekirdek"
                        target="_blank"
                        whileHover={{ y: -5 }}
                        fill="currentColor"
                        viewBox="0 0 24 24"
                        height="1em"
                        width="1em"
                    >
                        <path d="M18.89 7.012c.808-.496 1.343-1.173 1.605-2.034-.786.417-1.569.703-2.351.861-.703-.756-1.593-1.14-2.66-1.14-1.043 0-1.924.366-2.643 1.078-.715.717-1.076 1.588-1.076 2.605 0 .309.039.585.117.819-3.076-.105-5.622-1.381-7.628-3.837-.34.601-.51 1.213-.51 1.846 0 1.301.549 2.332 1.645 3.089-.625-.053-1.176-.211-1.645-.47 0 .929.273 1.705.82 2.388.549.676 1.254 1.107 2.115 1.291-.312.08-.641.118-.979.118-.312 0-.533-.026-.664-.083.23.757.664 1.371 1.291 1.841.625.472 1.344.721 2.152.743-1.332 1.045-2.855 1.562-4.578 1.562-.422 0-.721-.006-.902-.038 1.697 1.102 3.586 1.649 5.676 1.649 2.139 0 4.029-.542 5.674-1.626 1.645-1.078 2.859-2.408 3.639-3.974.784-1.564 1.172-3.192 1.172-4.892v-.468c.758-.57 1.371-1.212 1.84-1.921-.68.293-1.383.492-2.11.593z"></path>
                    </motion.svg>
                </LogoA>
                <LogoA href="https://www.facebook.com/itucekirdek" target="_blank">
                    <motion.svg whileHover={{ y: -5 }} fill="currentColor" viewBox="0 0 24 24" height="1em" width="1em">
                        <path d="M13 10h3v3h-3v7h-3v-7h-3v-3h3v-1.255c0-1.189.374-2.691 1.118-3.512.744-.823 1.673-1.233 2.786-1.233h2.096v3h-2.1c-.498 0-.9.402-.9.899v2.101z"></path>
                    </motion.svg>
                </LogoA>
                <LogoA href="https://www.instagram.com/itucekirdek" target="_blank">
                    <motion.svg whileHover={{ y: -5 }} fill="currentColor" viewBox="0 0 24 24" height="1em" width="1em">
                        <path d="M18 3h-12c-1.7 0-3 1.3-3 3v12c0 1.7 1.3 3 3 3h12c1.7 0 3-1.3 3-3v-12c0-1.7-1.3-3-3-3zm-6 6c1.7 0 3 1.3 3 3s-1.3 3-3 3-3-1.3-3-3 1.3-3 3-3zm3.8-2c0-.7.6-1.2 1.2-1.2s1.2.6 1.2 1.2-.5 1.2-1.2 1.2-1.2-.5-1.2-1.2zm2.2 12h-12c-.6 0-1-.4-1-1v-6h2c0 2.8 2.2 5 5 5s5-2.2 5-5h2v6c0 .6-.4 1-1 1z"></path>
                    </motion.svg>
                </LogoA>
                <LogoA href="https://www.linkedin.com/company/itucekirdek/" target="_blank">
                    <motion.svg whileHover={{ y: -5 }} fill="currentColor" viewBox="0 0 24 24" height="1em" width="1em">
                        <path d="M8 19h-3v-10h3v10zm11 0h-3v-5.342c0-1.392-.496-2.085-1.479-2.085-.779 0-1.273.388-1.521 1.165v6.262h-3s.04-9 0-10h2.368l.183 2h.062c.615-1 1.598-1.678 2.946-1.678 1.025 0 1.854.285 2.487 1.001.637.717.954 1.679.954 3.03v5.647z"></path>
                        <ellipse cx="6.5" cy="6.5" rx="1.55" ry="1.5"></ellipse>
                    </motion.svg>
                </LogoA>
                <LogoA href="https://www.youtube.com/itucekirdek" target="_blank">
                    <motion.svg whileHover={{ y: -5 }} fill="currentColor" viewBox="0 0 24 24" height="1em" width="1em">
                        <path d="M22.8 8.6c-.2-1.5-.4-2.6-1-3-.6-.5-5.8-.6-9.8-.6s-9.2.1-9.8.6c-.6.4-.8 1.5-1 3s-.2 2.4-.2 3.4 0 1.9.2 3.4.4 2.6 1 3c.6.5 5.8.6 9.8.6 4 0 9.2-.1 9.8-.6.6-.4.8-1.5 1-3s.2-2.4.2-3.4 0-1.9-.2-3.4zm-12.8 7v-7.2l6 3.6-6 3.6z"></path>
                    </motion.svg>
                </LogoA>
            </SocialMedia>
        </Container>
    );
}
