import React, { useContext } from "react";
import { UserContext } from "../context/UserContext";
import { useHistory } from "react-router-dom";
import styled from "styled-components/macro";

const variants = {
  closed: {
    background:
      "linear-gradient(180deg, rgb(41, 39, 40) 100%, rgb(235, 57, 38) 101%)",
    width: "150px",
    height: "70px",
    padding: "10px",
  },
  open: {
    background:
      "linear-gradient(180deg, rgb(41, 39, 40) -5%, rgb(235, 57, 38) 0%)",
    width: "150px",
    height: "70px",
    padding: "10px",
  },
};

const transition = { duration: 0.4, ease: [0.43, 0.13, 0.23, 0.96] };

const Container = styled.div`
  display: flex;
  justify-content: center;
  position: relative;
  align-items: center;
  background-color: #292728;
  color: white;
  display: flex;
  z-index: 10;
  height: 13vh;
  /* box-shadow: 0px 3px 10px rgba(219, 156, 31, 0.8); */
  box-shadow: 0px 0px 10px black;
`;

const ItemContainer = styled.div`
  font-family: "Teko", sans-serif;
  font-weight: 700;
  font-size: 2.7vw;
  padding: 1vw;
  display: flex;
  margin-top: 10px;
`;

const Label = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
`;

export default function SlickNavBar({
  setCurrentNav,
  currentNav,
  setSignInClick,
  setSignUpClick,
}) {
  const history = useHistory();
  const { logged } = useContext(UserContext);
  return (
    <Container>
      <ItemContainer>
        <Label
          onClick={() => {
            setSignUpClick(false);
            setSignInClick(false);
            history.push("/");
            setCurrentNav("");
          }}
        >
          GİRİŞİMLER
        </Label>
      </ItemContainer>
      {/* <ItemContainer>
				<Label
					onClick={() => {
						setSignUpClick(false);
						setSignInClick(false);
						history.push("/sahne");
						setCurrentNav("canli");
					}}
				>
					BIG BANG SAHNE
				</Label>
			</ItemContainer>
			<ItemContainer>
				<Label
					onClick={() => {
						setSignUpClick(false);
						setSignInClick(false);
						history.push("/girisimler/standlar");
						setCurrentNav("girisim");
					}}
				>
					GİRİŞİMLER
				</Label>
			</ItemContainer>

			<ItemContainer>
				<Label
					onClick={() => {
						setSignUpClick(false);
						setSignInClick(false);
						history.push("/mavi-mikrofon");
						setCurrentNav("kirmizi");
					}}
				>
					MAVİ MİKROFON
				</Label>
			</ItemContainer>
			<ItemContainer>
				<Label
					onClick={() => {
						setSignUpClick(false);
						setSignInClick(false);
						history.push("/odul-ve-yatirimlar");
						setCurrentNav("odul");
					}}
				>
					ÖDÜL EKRANI
				</Label>
			</ItemContainer>

			<ItemContainer>
				<Label
					onClick={() => {
						logged ? history.push("/my-account") : setSignUpClick(true);
						setCurrentNav("signup");
					}}
				>
					{logged ? "HESABIM" : "KAYIT"}
				</Label>
			</ItemContainer>
			{!logged && (
				<ItemContainer>
					<Label
						onClick={() => {
							logged ? history.push("/my-account") : setSignInClick(true);
							setCurrentNav("signın");
						}}
					>
						{logged ? "HESABIM" : "GİRİŞ"}
					</Label>
				</ItemContainer> */}
    </Container>
    // <button
    // 	style={{
    // 		position: "absolute",
    // 		display: "flex",
    // 		justifyContent: "center",
    // 		alignItems: "center",
    // 		zIndex: "5",
    // 		color: "white",
    // 		backgroundColor: "#E9483F",
    // 		top: "10px",
    // 		left: "10px",
    // 		borderRadius: "50%",
    // 		border: "none",
    // 		padding: "10px",
    // 		boxShadow: "0 0 5px rgba(0,0,0,0.9)",
    // 	}}
    // >
    // 	<HiMenu size="2em" />
    // </button>
  );
}
