import React, { useEffect, useState } from "react";
import { Table, Loading } from "../components";
import { useCountUp } from "react-countup";

import { Donation } from "../components";
import {
    Wrapper,
    Grid,
    FrameOne,
    FrameTwo,
    FrameThree,
    Header,
    Toplam,
    Footer,
    FooterHeaderWrapper,
    BigText,
    SmallText,
    Logo,
    Center,
    ImageContainer,
    Image,
} from "../components/Table/style";
import kese from "../images/kese.png";
import yildiz from "../images/yildiz.png";
import kagit from "../images/kagit.png";
import table from "../images/odul_bg2020.png";
import { useMediaQuery } from "react-responsive";
import { AnimatePresence, motion, useTransform } from "framer-motion";
import useTop20 from "../hooks/useTop20";

export const Countup = ({ formattedOutput, endValue, isTop20 }) => {
    const { countUp, start, pauseResume, reset, update } = useCountUp({
        start: 0,
        end: 0,
        delay: 0,
        duration: 3,
    });
    useEffect(() => {
        update(endValue);
    }, [endValue]);
    return <div>{formattedOutput.format(countUp)}</div>;
};

export default function TableContainer() {
    const ituValue = 30768000;
    const [tableData, setTableData] = useState([]);

    const onInitialLoad = (top20) => {
        console.log(tableData);
        setTableData([...top20]);
    };
    const {
        top20,
        lastInvestment,
        shouldPlayAnimation,
        setShouldPlayAnimation,
        totalNakit,
        totalOdul,
        totalYatirim,
        fuayeNakit,
        fuayeOdul,
        fuayeYatirim,
        takipYatirim,
        genelTotal,
    } = useTop20(1000, onInitialLoad);
    const isMobile = useMediaQuery({ query: "(max-height: 414px)" });

    const formattedOutput = new Intl.NumberFormat("tr-TR", {
        style: "currency",
        currency: "TRY",
        minimumFractionDigits: 0,
    });

    const [customStyle, setCustomStyle] = useState({
        border: "none",
        shadow: "none",
        background:
            "linear-gradient(90deg, rgba(242, 120, 0, 0.5) 0%, rgba(255, 180, 0, 0.5) 80%, rgba(242, 120, 0, 0.5) 100%)",
        color: "inherit",
    });

    useEffect(() => {
        console.log(lastInvestment);
    }, []);

    const handleAnimationEnd = () => {
        setTableData([...top20]);
        setShouldPlayAnimation(false);
    };

    return (
        <>
            <AnimatePresence exitBeforeEnter>
                {shouldPlayAnimation && (
                    <Donation
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        exit={{ opacity: 0 }}
                        transition={{ staggerChildren: 0.5 }}
                        handleAnimationEnd={handleAnimationEnd}
                        from={lastInvestment.from}
                        to={lastInvestment.to}
                        type={lastInvestment.type}
                        amount={lastInvestment.amount}
                        formattedOutput={formattedOutput}
                    />
                )}
            </AnimatePresence>
            <Wrapper>
                <ImageContainer>
                    <Image
                        animate={{
                            x: [-20, 20, 20, -20, -20],
                            y: [-20, -20, 20, 20, -20],
                            scale: [1.05, 1.1, 1.15, 1.1, 1.05],
                        }}
                        transition={{ duration: 30, loop: Infinity }}
                        src={table}
                    />
                </ImageContainer>
                <Grid>
                    <FrameOne />
                    <FrameTwo />
                    <FrameThree />
                    <div></div>
                    <div></div>
                    {!isMobile ? (
                        <>
                            <Center>
                                <Logo src={kagit} alt="kagit-logo" />
                            </Center>
                            <Center>
                                <Logo src={yildiz} alt="yildiz-logo" />
                            </Center>
                            <Center>
                                <Logo src={kese} alt="kese-logo" />
                            </Center>
                        </>
                    ) : (
                        <>
                            <div></div>
                            <div></div>
                            <div></div>
                        </>
                    )}

                    <Header style={{ display: "flex", alignItems: "center" }}>SIRA</Header>
                    <Header style={{ display: "flex", alignItems: "center" }}>BIG BANG TOP 20</Header>
                    <Center>
                        <Header style={{ padding: 0 }}>ÖDÜL</Header>
                    </Center>
                    <Center>
                        <Header style={{ padding: 0 }}>NAKİT</Header>
                    </Center>

                    <Center>
                        <Header style={{ padding: 0 }}>YATIRIM</Header>
                    </Center>
                    {!Array.isArray(tableData) || !tableData.length ? (
                        <Loading />
                    ) : (
                        <Table.Top20
                            lastInvestmentName={lastInvestment.to}
                            customStyle={customStyle}
                            formattedOutput={formattedOutput}
                            tableData={tableData}
                        />
                    )}

                    {!isMobile ? (
                        <>
                            <Toplam style={{ fontSize: "15px" }}>
                                <div>TOPLAM</div>
                                <div
                                    style={{
                                        fontFamily: "Poppins, sans-serif",
                                        display: "flex",
                                        justifyContent: "center",
                                        marginLeft: "-20px",
                                    }}
                                >
                                    <Countup formattedOutput={formattedOutput} endValue={totalOdul} isTop20={false} />
                                </div>
                                <div
                                    style={{
                                        fontFamily: "Poppins, sans-serif",
                                        display: "flex",
                                        justifyContent: "center",
                                        marginLeft: "-20px",
                                    }}
                                >
                                    <Countup formattedOutput={formattedOutput} endValue={totalNakit} isTop20={false} />
                                </div>
                                <div
                                    style={{
                                        fontFamily: "Poppins, sans-serif",
                                        display: "flex",
                                        justifyContent: "center",
                                        marginLeft: "-20px",
                                    }}
                                >
                                    <Countup
                                        formattedOutput={formattedOutput}
                                        endValue={totalYatirim}
                                        isTop20={false}
                                    />
                                </div>
                            </Toplam>

                            <Footer>
                                <FooterHeaderWrapper>
                                    <BigText style={{ justifyContent: "flex-start" }}>FUAYE ÖDÜL</BigText>
                                    <SmallText style={{ justifyContent: "flex-start" }}>
                                        BIG BANG 2020 FUAYEDEKİ GİRİŞİMLER
                                    </SmallText>
                                </FooterHeaderWrapper>
                                <FooterHeaderWrapper>
                                    <BigText>ÖDÜL</BigText>
                                    <SmallText>
                                        <Countup
                                            formattedOutput={formattedOutput}
                                            endValue={fuayeOdul}
                                            isTop20={false}
                                        />
                                    </SmallText>
                                </FooterHeaderWrapper>
                                <FooterHeaderWrapper>
                                    <BigText>NAKİT</BigText>
                                    <SmallText>
                                        <Countup
                                            formattedOutput={formattedOutput}
                                            endValue={fuayeNakit}
                                            isTop20={false}
                                        />
                                    </SmallText>
                                </FooterHeaderWrapper>
                                <FooterHeaderWrapper>
                                    <BigText>YATIRIM</BigText>
                                    <SmallText>
                                        <Countup
                                            formattedOutput={formattedOutput}
                                            endValue={fuayeYatirim}
                                            isTop20={false}
                                        />
                                    </SmallText>
                                </FooterHeaderWrapper>
                            </Footer>
                            <Footer>
                                <FooterHeaderWrapper>
                                    <BigText style={{ justifyContent: "flex-start" }}>TAKİP YATIRIM</BigText>
                                    <SmallText style={{ justifyContent: "flex-start" }}>
                                        ÖNCEKİ YILLARDA BIG BANG'TE YER ALAN GİRİŞİMLER
                                    </SmallText>
                                </FooterHeaderWrapper>
                                <div></div>
                                <div></div>
                                <FooterHeaderWrapper>
                                    <BigText>YATIRIM</BigText>
                                    <SmallText>
                                        <Countup
                                            formattedOutput={formattedOutput}
                                            endValue={takipYatirim}
                                            isTop20={false}
                                        />
                                    </SmallText>
                                </FooterHeaderWrapper>
                            </Footer>
                            <Toplam style={{ fontSize: "15px" }}>
                                <div>ANA TOPLAM</div>
                                <div
                                    style={{
                                        fontFamily: "Poppins, sans-serif",
                                        display: "flex",
                                        justifyContent: "center",
                                        marginLeft: "-20px",
                                    }}
                                >
                                    <Countup
                                        formattedOutput={formattedOutput}
                                        endValue={totalOdul + fuayeOdul}
                                        isTop20={false}
                                    />
                                </div>
                                <div
                                    style={{
                                        fontFamily: "Poppins, sans-serif",
                                        display: "flex",
                                        justifyContent: "center",
                                        marginLeft: "-20px",
                                    }}
                                >
                                    <Countup
                                        formattedOutput={formattedOutput}
                                        endValue={totalNakit + fuayeNakit}
                                        isTop20={false}
                                    />
                                </div>
                                <div
                                    style={{
                                        fontFamily: "Poppins, sans-serif",
                                        display: "flex",
                                        justifyContent: "center",
                                        marginLeft: "-20px",
                                    }}
                                >
                                    <Countup
                                        formattedOutput={formattedOutput}
                                        endValue={totalYatirim + fuayeYatirim + takipYatirim}
                                        isTop20={false}
                                    />
                                </div>
                            </Toplam>

                            <Footer>
                                <FooterHeaderWrapper>
                                    <SmallText style={{ justifyContent: "flex-start" }}>
                                        İTÜ ÇEKİRDEK 2020 GİRİŞİMLERİ VE MEZUNLARININ YIL İÇİNDE AÇIKLANAN YATIRIMLARI
                                    </SmallText>
                                </FooterHeaderWrapper>
                                <div></div>
                                <div></div>
                                <FooterHeaderWrapper>
                                    <BigText>YATIRIM</BigText>
                                    <SmallText>
                                        <Countup
                                            formattedOutput={formattedOutput}
                                            endValue={ituValue}
                                            isTop20={false}
                                        />
                                    </SmallText>
                                </FooterHeaderWrapper>
                            </Footer>
                        </>
                    ) : (
                        <>
                            <div></div>
                            <div></div>
                            <div></div>
                            <div></div>
                            <div></div>
                        </>
                    )}

                    <Toplam
                        onClick={() => {
                            setCustomStyle({
                                border: "2px solid white",
                                shadow: "2px 1px 150px #edb313, 2px 0 60px #edb313, 2px 0 5px #edb313",
                                background: "rgba(237, 179, 19)",
                                color: "white",
                            });
                        }}
                        style={{ fontSize: "18px" }}
                    >
                        <div>GENEL TOPLAM</div>
                        <div></div>
                        <div></div>
                        <div
                            style={{
                                fontFamily: "Poppins, sans-serif",
                                display: "flex",
                                justifyContent: "center",
                                marginLeft: "-20px",
                            }}
                        >
                            <Countup
                                formattedOutput={formattedOutput}
                                endValue={genelTotal + ituValue}
                                isTop20={false}
                            />
                        </div>
                    </Toplam>
                </Grid>
            </Wrapper>
        </>
    );
}
