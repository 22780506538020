import React, { useState } from "react";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  useParams,
} from "react-router-dom";
import { MailPopup, KartPopup } from "./components";
import { NavBarContainer, SlickNavBar } from "./containers";
import { ProtectedRoute, IsUserRedirect } from "./helpers/routers";
import {
  Anasayfa,
  ChatPage,
  KirmiziOda,
  Odul,
  CanliYayin,
  Admin,
  Girisimciler,
  Girisimci,
  MyAccount,
  Signin,
  Signup,
  Placeholder,
  PasswordResetPage,
  ResetPasswordRedirect,
  FourPage,
  Main
} from "./pages";
import { UserProvider } from "./context/UserContext";
import { useMediaQuery } from "react-responsive";
import { SideWaysWarnMessage } from "./components/SideWaysWarnMessage";
import { FiRotateCw } from "react-icons/fi";
import { AnimatePresence, motion } from "framer-motion";
import { useHistory } from "react-router-dom";

export default function App() {
  const isTabletOrMobile = useMediaQuery({ query: "(min-width: 1224px)" });
  const isPortrait = useMediaQuery({ query: "(orientation: portrait)" });
  const history = useHistory();

  const [targetMail, setTargetMail] = useState();

  let current = window.location.href.split("/")[3];
  const [currentNav, setCurrentNav] = useState(current);
  const [mailOpen, setMailOpen] = useState(false);
  const [kartOpen, setKartOpen] = useState(false);

  const [signInClick, setSignInClick] = useState(false);
  const [signUpClick, setSignUpClick] = useState(false);
  const [isPopupActive, setIsPopupActive] = useState(false);
  const [popupVideo, setPopupVideo] = useState(false);

  const [passwordResetClick, setPasswordResetClick] = useState(false);
  const [successPopup, setSuccessPopup] = useState(false);
  const [videoEnd, setVideoEnd] = useState(true);
  const [isAdmin, setIsAdmin] = useState(false);

  const handleEmailPopupClick = (email) => {
    setTargetMail(email);
  };

  return (
    <>
      <motion.div
        initial={{
          opacity: 1,
        }}
        animate={{
          opacity: 0,
        }}
        exit={{
          opacity: 0,
        }}
        transition={{
          duration: 1,
        }}
        style={{
          position: "absolute",
          width: "100vw",
          height: "100vh",
          backgroundColor: "black",
          zIndex: 9,
          pointerEvents: "none",
        }}
      />
      <UserProvider>
        {/* <AnimatePresence exitBeforeEnter>{videoEnd && <HomePageStartVideo setVideoEnd={setVideoEnd} />}</AnimatePresence> */}
        {isPortrait ? (
          <SideWaysWarnMessage>
            <FiRotateCw size="2em" style={{ marginBottom: "40px" }} />
            Daha kaliteli bir görsel tecrübe için cihazınızı yan çevirin...
          </SideWaysWarnMessage>
        ) : (
          <Router>
            <Switch>
              {/* <Route path="/admin">
                                <Admin setIsAdmin={setIsAdmin} />
                            </Route> */}
              <Route path="/reset-password">
                <ResetPasswordRedirect
                  setSuccessPopup={setSuccessPopup}
                  setIsPopupActive={setIsPopupActive}
                />
              </Route>
              {/* <ProtectedRoute path="/chat/:id">
                                <Placeholder />
                                <ChatPage />
                            </ProtectedRoute>
                            <ProtectedRoute path="/chat">
                                <Placeholder />
                                <ChatPage />
                            </ProtectedRoute> */}
              <Route path="*">
                <div
                  style={{
                    display: "grid",
                    gridTemplateRows: "auto 1fr",
                    height: "100vh",
                    filter: `${isPopupActive ? "blur(5px)" : "blur(0)"}`,
                  }}
                >
                  {isTabletOrMobile ? (
                    <NavBarContainer
                      currentNav={currentNav}
                      setCurrentNav={setCurrentNav}
                      setSignInClick={setSignInClick}
                      setSignUpClick={setSignUpClick}
                    />
                  ) : (
                    <SlickNavBar
                      setSignInClick={setSignInClick}
                      setSignUpClick={setSignUpClick}
                      currentNav={currentNav}
                      setCurrentNav={setCurrentNav}
                    />
                  )}
                  <Switch>
                    <Route path={"/four-page"}>
                      <FourPage
                        setMailOpen={setMailOpen}
                        setIsPopupActive={setIsPopupActive}
                        setCurrentNav={setCurrentNav}
                      />
                    </Route>
                    <Route path={"/mavi-mikrofon"}>
                      {/* <Placeholder /> */}
                      <KirmiziOda setCurrentNav={setCurrentNav} />
                    </Route>
                    <Route path={"/sahne"}>
                      {/* <Placeholder /> */}
                      <CanliYayin setCurrentNav={setCurrentNav} />
                    </Route>
                    <Route path={"/odul-ve-yatirimlar"}>
                      {/* <Placeholder /> */}
                      <Odul setCurrentNav={setCurrentNav} />
                    </Route>
                    <ProtectedRoute path={"/my-account"}>
                      <MyAccount
                        setCurrentNav={setCurrentNav}
                        setIsPopupActive={setIsPopupActive}
                      />
                    </ProtectedRoute>
                    <Route exact path={"/girisimler/:id"}>
                      {/* <Placeholder /> */}
                      <Girisimciler />
                    </Route>
                    <Route exact path={"/girisim/:id"}>
                      {/* <Placeholder /> */}
                      <Girisimci
                        handleEmailPopupClick={handleEmailPopupClick}
                        setIsPopupActive={setIsPopupActive}
                        setMailOpen={setMailOpen}
                        setKartOpen={setKartOpen}
                        setCurrentNav={setCurrentNav}
                        setPopupVideo={setPopupVideo}
                        popupVideo={popupVideo}
                      />
                    </Route>
                    <Route path="*">
                      <Main
                        setSignUpClick={setSignUpClick}
                        setCurrentNav={setCurrentNav}
                      />
                    </Route>
                  </Switch>
                </div>
              </Route>
            </Switch>
            <AnimatePresence exitBeforeEnter>
              {signInClick && (
                <Signin
                  redirectTo="/"
                  shouldChangeURL={true}
                  setIsPopupActive={setIsPopupActive}
                  setCurrentNav={setCurrentNav}
                  setSignUpClick={setSignUpClick}
                  setPasswordResetClick={setPasswordResetClick}
                  setSignInClick={setSignInClick}
                />
              )}
              {signUpClick && (
                <Signup
                  redirectTo="/"
                  shouldChangeURL={true}
                  setIsPopupActive={setIsPopupActive}
                  setCurrentNav={setCurrentNav}
                  setSignUpClick={setSignUpClick}
                  setSignInClick={setSignInClick}
                />
              )}
              {passwordResetClick && (
                <PasswordResetPage
                  redirectTo="/"
                  shouldChangeURL={true}
                  setIsPopupActive={setIsPopupActive}
                  setCurrentNav={setCurrentNav}
                  setSignUpClick={setSignUpClick}
                  setSignInClick={setSignInClick}
                  setPasswordResetClick={setPasswordResetClick}
                />
              )}
              {mailOpen && (
                <MailPopup
                  setMailOpen={setMailOpen}
                  setIsPopupActive={setIsPopupActive}
                  targetMail={targetMail}
                />
              )}
              {kartOpen && (
                <KartPopup
                  setKartOpen={setKartOpen}
                  setIsPopupActive={setIsPopupActive}
                  targetMail={targetMail}
                />
              )}
            </AnimatePresence>
          </Router>
        )}
      </UserProvider>
    </>
  );
}
