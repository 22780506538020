import React from "react";
import { FormComponent } from "../components";

export default function CheckBoxContainer() {
	return (
		<>
			<FormComponent.ReleaseWrapper>
				<FormComponent.RadioButton type="checkbox" required={true} />
				<FormComponent.SmallLabel>
					<a
						style={{
							color: "white",
							cursor: "pointer",
							textShadow: "0 0 1px white, 0 0 1px white",
						}}
						href="https://bigbang.itucekirdek.com/big-bang-etkinlik-katilimcisi-acik-riza-metni/"
						target="_blank"
					>
						Açık Rıza Metni
					</a>
					'ni kabul ediyorum.
				</FormComponent.SmallLabel>
			</FormComponent.ReleaseWrapper>
			<FormComponent.ReleaseWrapper>
				<FormComponent.RadioButton type="checkbox" required={true} />

				<FormComponent.SmallLabel>
					<a
						style={{
							color: "white",
							cursor: "pointer",
							textShadow: "0 0 1px white, 0 0 1px white",
						}}
						href="https://bigbang.itucekirdek.com/big-bang-etkinlik-katilimcisi-aydinlatma-metni/"
						target="_blank"
					>
						Katılımcı Aydınlatma Metni
					</a>
					'ni kabul ediyorum.
				</FormComponent.SmallLabel>
			</FormComponent.ReleaseWrapper>
		</>
	);
}
