import styled from "styled-components/macro";
import { motion } from "framer-motion";

export const Wrapper = styled.div`
    width: 100%;
    height: 87vh;
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgb(69, 0, 242);
    background: linear-gradient(
        90deg,
        rgba(69, 0, 242, 0.21332282913165268) 0%,
        rgba(11, 13, 20, 1) 17%,
        rgba(11, 13, 20, 1) 100%
    );
`;

export const Grid = styled.div`
    position: relative;
    width: 60%;
    height: 95%;
    display: grid;
    color: white;
    text-shadow: 2px 0 2px black;
    grid-template-columns: 1fr 2fr 1fr 1fr 1fr;
    grid-template-rows: 40px 40px auto 0.5fr 0.7fr 0.7fr 0.5fr 1fr;
    z-index: 1;

    @media (max-width: 1024px) {
        width: 90%;
    }
`;

export const FrameOne = styled.div`
    position: absolute;
    width: 99%;
    height: 100%;
    border: 2px solid #fac601;
    border-radius: 3px;
    background-color: transparent;
    grid-column: 3 / 4;
    grid-row: 2 / 4;
    z-index: -1;
`;
export const FrameTwo = styled.div`
    position: absolute;
    width: 99%;
    height: 100%;
    border: 2px solid #fac601;
    border-radius: 3px;
    background-color: transparent;
    grid-column: 4 / 5;
    grid-row: 2 / 4;
    z-index: -1;
`;
export const FrameThree = styled.div`
    position: absolute;
    width: 98%;
    height: 100%;
    border: 2px solid #fac601;
    border-radius: 3px;
    background-color: transparent;
    grid-column: 5 / 6;
    grid-row: 2 / 4;
    z-index: -1;
`;

export const Top20 = styled(motion.div)`
    position: relative;
    grid-column: 1 / 6;
    overflow: visible;
    min-height: 400px;
    height: 100%;

    @media (max-height: 768px) {
        min-height: 300px;
        overflow-y: scroll;
    }

    @media (max-height: 414px) {
        min-height: 100px;
    }
`;

export const Item = styled(motion.div)`
    background: ${({ odd }) =>
        odd % 2 === 0
            ? "linear-gradient(90deg, rgba(242, 120, 0, 0.5) 0%, rgba(255, 180, 0, 0.5) 80%, rgba(242, 120, 0, 0.5) 100%)"
            : "rgba(250, 170, 1, 0.2)"};

    margin-bottom: 2px;
    display: grid;
    grid-template-columns: 1fr 2fr 1fr 1fr 1fr;
    white-space: nowrap;
    box-sizing: border-box;
    height: 20px;

    -webkit-user-select: none; /* Safari */
    -ms-user-select: none; /* IE 10 and IE 11 */
    user-select: none; /* Standard syntax */

    transition: color 0.5s, box-shadow 0.3s, text-shadow 0.6s, border 0.1s;

    &:hover {
        border: 2px solid #ffee8c;
        text-shadow: none;
        background: #ffc524;
        color: white;
        box-shadow: 2px 1px 150px #edb313, 2px 0 60px #edb313, 2px 0 5px #edb313;
    }
`;

export const AnimationItem = styled(motion.div)`
    background-color: #edb313;
    color: white;
    border: none;
    position: absolute;
    box-sizing: border-box;
    z-index: 5;
    pointer-events: none;
    display: grid;
    border: 2px solid white;
    box-shadow: 2px 1px 150px #edb313, 2px 0 60px #edb313, 2px 0 5px #edb313;
    width: 100%;
    grid-template-columns: 1fr 2fr 1fr 1fr 1fr;
    height: 20px;
`;

export const Header = styled.h3`
    margin-bottom: 6px;
    margin-top: 10px;
    padding-left: 20px;
`;

export const Toplam = styled.div`
    padding: 5px 0 5px;
    color: #0b0d14;
    text-shadow: none;
    padding-left: 20px;
    background: rgb(242, 150, 0);
    background: linear-gradient(90deg, rgba(242, 150, 0, 1) 0%, rgba(255, 231, 0, 1) 80%, rgba(242, 150, 0, 1) 100%);
    display: grid;
    grid-template-columns: 3fr 1fr 1fr 1fr;
    align-items: center;
    grid-column: 1 / 6;
`;

export const Footer = styled.div`
    margin: 5px 0 5px;
    display: grid;
    grid-template-columns: 3fr 1fr 1fr 1fr;
    padding-left: 20px;
    grid-column: 1 / 6;
`;

export const FooterHeaderWrapper = styled.div`
    display: flex;
    justify-content: center;
    flex-direction: column;
`;

export const BigText = styled.div`
    display: flex;
	justify-content: center;
	18px;
`;

export const SmallText = styled.div`
    display: flex;
    justify-content: center;
    font-size: 14px;
`;

export const Logo = styled.img`
    width: 36px;
    margin-bottom: 10px;
`;

export const Center = styled.div`
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
`;

export const ImageContainer = styled.div`
    position: absolute;
    height: 87vh;
    width: 100vw;
    overflow: hidden;
`;

export const Image = styled(motion.img)`
    overflow: hidden;
`;
