import React from "react";
import { Container, MainWrapper, Label, Navbar, Logout, InfoWrapper, InputField, Button } from "./styles";

export default function Account({ children, ...restProps }) {
	return <Container {...restProps}>{children}</Container>;
}

Account.MainWrapper = function AccountMainWrapper({ children, ...restProps }) {
	return <MainWrapper {...restProps}>{children}</MainWrapper>;
};

Account.Navbar = function AccountNavbar({ children, ...restProps }) {
	return <Navbar {...restProps}>{children}</Navbar>;
};

Account.Label = function AccountLabel({ children, ...restProps }) {
	return <Label {...restProps}>{children}</Label>;
};

Account.Logout = function AccountLogout({ children, ...restProps }) {
	return <Logout {...restProps}>{children}</Logout>;
};

Account.InfoWrapper = function AccountInfoWrapper({ children, ...restProps }) {
	return <InfoWrapper {...restProps}>{children}</InfoWrapper>;
};

Account.InputField = function AccountInputField({ ...restProps }) {
	return <InputField {...restProps} />;
};

Account.Button = function AccountButton({ children, ...restProps }) {
	return <Button {...restProps}>{children}</Button>;
};
