import React, { useState } from "react";
import styled from "styled-components/macro";
import { HiMenu } from "react-icons/hi";
import { CgClose } from "react-icons/cg";
import { FaHome } from "react-icons/fa";
import SideContainer from "../../containers/Chat/SideContainer";
import { motion, AnimatePresence } from "framer-motion";
import { useHistory } from "react-router-dom";

const MenuButton = styled.button`
	display: flex;
	justify-content: center;
	align-items: center;

	width: 60px;
	height: 60px;
	border-radius: 50%;
	border: none;
	padding: 10px;
	background-color: #e9483f;
	color: white;

	position: absolute;
	top: 10px;
	right: 10px;
	z-index: 100;

	&:focus {
		outline: none;
	}

	&:active {
		background-color: #942637;
		color: #999;
	}
`;

const HomeButton = styled.button`
	display: flex;
	justify-content: center;
	align-items: center;

	width: 60px;
	height: 60px;
	border-radius: 50%;
	border: none;
	padding: 10px;
	background-color: #bbb;
	color: black;

	position: absolute;
	top: 85px;
	right: 10px;
	z-index: 100;

	&:focus {
		outline: none;
	}

	&:active {
		background-color: #444;
		color: #bbb;
	}
`;

export default function MobileSideContainer({ haveLogo, conversations, setActiveConversationName, activeConversation }) {
	const [sidebar, setSidebar] = useState(false);
	const history = useHistory();
	const handleSideBar = () => {
		setSidebar((prev) => !prev);
	};

	return (
		<div>
			<MenuButton onClick={handleSideBar}>{sidebar ? <CgClose size="2em" /> : <HiMenu size="2em" />}</MenuButton>
			<HomeButton
				onClick={() => {
					history.push("/");
				}}
			>
				<FaHome size="2em" />
			</HomeButton>
			<AnimatePresence exitBeforeEnter>
				{sidebar && (
					<motion.div
						initial={{ x: "-100%" }}
						animate={{ x: 0 }}
						exit={{ x: "-100%" }}
						transition={{ ease: [0.43, 0.13, 0.23, 0.96], duration: 0.6 }}
						style={{ position: "absolute", zIndex: "5" }}
					>
						<SideContainer
							handleSideBar={handleSideBar}
							conversations={conversations}
							setActiveConversation={setActiveConversationName}
							activeConversation={activeConversation}
							haveLogo={haveLogo}
						/>
					</motion.div>
				)}
			</AnimatePresence>
		</div>
	);
}
