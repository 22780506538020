import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components/macro";
import stand1 from "../images/girisimciler/first/stands.png";
import stand2 from "../images/girisimciler/second/stands.png";
import stand3 from "../images/girisimciler/third/stands.png";
import stand4 from "../images/girisimciler/fourth/stands.png";
import bg from "../images/girisimciler/girisim_background.png";
import city from "../images/girisimciler/city.jpg";
import { motion } from "framer-motion";
import chevron from "../images/ileri.png";
import ImageMap from "image-map";
import { useHistory, useParams } from "react-router-dom";
import { useMousePosition } from "../hooks/useMousePosition";

const BackgroundImage = styled.img`
	transform: translateX(${({ x, scale }) => `${x / (10 * scale)}px`}) translateY(${({ y, scale }) => `${-y / scale}px`})
		perspective(300px) rotateY(${({ x, scale }) => `${x / (20 * scale)}deg`})
		rotateX(${({ y, scale }) => `${y / (100 * scale)}deg`});
	width: 100%;
	position: absolute;
	z-index: 2;
`;

const LeftButton = styled.button`
	background-color: transparent;

	position: absolute;
	top: 50%;
	margin-top: -100px;
	z-index: 666;

	color: red;
	border: none;
	transition: 0.3s;
	left: 20px;

	&:hover {
		transform: scale(1.1);
	}
	&:focus {
		outline: none;
	}
	&:active {
		transform: translateX(-5px);
	}
`;

const RightButton = styled.button`
	background-color: transparent;

	position: absolute;
	top: 50%;
	margin-top: -100px;
	z-index: 666;
	right: 20px;

	color: red;
	border: none;
	transition: 0.3s;

	&:hover {
		transform: scale(1.1);
	}
	&:focus {
		outline: none;
	}
	&:active {
		transform: translateX(5px);
	}
`;

const StandImage = styled(motion.img)`
	width: 100%;
	z-index: 3;
	position: absolute;
`;

const CityImage = styled.img`
	transform: translateX(${({ x, scale }) => `${(x * -2) / (2 * scale)}px`}) translateY(${({ y, scale }) => `${-y / scale}px`})
		perspective(300px) rotateY(${({ x, scale }) => `${x / (20 * scale)}deg`})
		rotateX(${({ y, scale }) => `${y / (100 * scale)}deg`});
	width: 100%;
`;

const Wrapper = styled.div`
	position: relative;
	width: 100%;
	height: 87vh;
	align-items: center;
`;

const StyledDiv = styled.div`
	position: absolute;
	top: 13vh;
	width: 100%;
	height: 87vh;
`;

const Chevron = styled.img`
	width: 120px;

	@media (max-width: 823px) {
		width: 60px;
	}
`;

const mappingList = [
	{ url: 0, coordinates: [264, 143, 261, 233, 468, 265, 476, 169] },
	{ url: 1, coordinates: [680, 237, 839, 253, 839, 333, 680, 319] },
	{ url: 2, coordinates: [1006, 289, 1004, 363, 1136, 369, 1139, 300] },
	{ url: 3, coordinates: [1295, 312, 1295, 381, 1414, 381, 1417, 312] },
	{ url: 4, coordinates: [1587, 317, 1589, 382, 1711, 378, 1711, 311] },
	{ url: 5, coordinates: [1862, 296, 1865, 364, 1997, 356, 1995, 288] },
	{ url: 6, coordinates: [2172, 255, 2175, 332, 2331, 316, 2331, 234] },
	{ url: 7, coordinates: [2509, 178, 2514, 268, 2718, 242, 2718, 146] },
	{ url: 14, coordinates: [2413, 462, 2416, 563, 2631, 550, 2631, 446] },
	{ url: 13, coordinates: [2080, 499, 2080, 584, 2249, 579, 2249, 489] },
	{ url: 12, coordinates: [1751, 515, 1754, 595, 1894, 589, 1894, 510] },
	{ url: 11, coordinates: [1433, 521, 1430, 597, 1565, 600, 1565, 521] },
	{ url: 10, coordinates: [1107, 511, 1107, 593, 1253, 593, 1253, 516] },
	{ url: 9, coordinates: [770, 491, 768, 578, 935, 581, 937, 496] },
	{ url: 8, coordinates: [373, 447, 370, 553, 585, 561, 585, 463] },
	{ url: 15, coordinates: [235, 792, 230, 935, 534, 919, 529, 787] },
	{ url: 16, coordinates: [701, 879, 908, 873, 911, 768, 701, 770] },
	{ url: 17, coordinates: [1086, 760, 1086, 856, 1253, 856, 1253, 760] },
	{ url: 18, coordinates: [1422, 843, 1581, 848, 1581, 758, 1422, 755] },
	{ url: 19, coordinates: [1751, 760, 1751, 852, 1918, 858, 1923, 762] },
	{ url: 20, coordinates: [2090, 768, 2090, 872, 2292, 877, 2289, 766] },
	{ url: 21, coordinates: [2469, 916, 2769, 934, 2763, 791, 2466, 783] },
];

const oddMappingList = [
	{ url: 0, coordinates: [368, 266, 562, 291, 558, 207, 372, 178] },
	{ url: 1, coordinates: [752, 253, 903, 270, 903, 350, 756, 333] },
	{ url: 2, coordinates: [1101, 300, 1106, 367, 1232, 375, 1232, 308] },
	{ url: 3, coordinates: [1447, 321, 1447, 384, 1565, 384, 1561, 321] },
	{ url: 4, coordinates: [1771, 304, 1767, 380, 1898, 367, 1898, 300] },
	{ url: 5, coordinates: [2087, 274, 2092, 346, 2243, 333, 2235, 258] },
	{ url: 6, coordinates: [2450, 199, 2454, 287, 2644, 266, 2639, 169] },
	{ url: 7, coordinates: [372, 447, 368, 556, 587, 561, 587, 464] },
	{ url: 8, coordinates: [764, 493, 764, 582, 933, 586, 929, 502] },
	{ url: 9, coordinates: [1110, 519, 1110, 594, 1253, 599, 1249, 514] },
	{ url: 10, coordinates: [1434, 523, 1434, 603, 1569, 599, 1565, 523] },
	{ url: 11, coordinates: [1754, 519, 1754, 594, 1894, 594, 1889, 514] },
	{ url: 12, coordinates: [2079, 498, 2079, 582, 2252, 578, 2243, 489] },
	{ url: 13, coordinates: [2412, 464, 2420, 561, 2639, 552, 2631, 451] },
	{ url: 14, coordinates: [229, 792, 225, 940, 528, 927, 528, 784] },
	{ url: 15, coordinates: [701, 775, 701, 881, 908, 872, 908, 771] },
	{ url: 16, coordinates: [1085, 763, 1089, 855, 1257, 860, 1253, 763] },
	{ url: 17, coordinates: [1426, 759, 1426, 847, 1586, 851, 1582, 759] },
	{ url: 18, coordinates: [1754, 759, 1754, 851, 1923, 860, 1923, 767] },
	{ url: 19, coordinates: [2096, 771, 2096, 872, 2302, 881, 2298, 771] },
	{ url: 20, coordinates: [2467, 788, 2475, 923, 2766, 935, 2766, 792] },
];

export default function Girisimciler({url}) {
	let { id } = useParams();
	const homepageUrlVariable = "standlar";

	if (id === homepageUrlVariable) id = "0";

	const [isImageLoaded, setIsImageLoaded] = useState(false);
	const [mapState, setMapState] = useState(mappingList);
	const history = useHistory();
	const { x, y } = useMousePosition();
	const imageRef = useRef(null);

	const onImageLoaded = () => {
		setIsImageLoaded(true);
		const naturalWidth = imageRef.current.naturalWidth * 2;
		const xRatio = (imageRef.current.width / naturalWidth) * 2;
		const mappingListToUse = id === "3" ? oddMappingList : mappingList;
		const newMappingList = mappingListToUse.map((item) => ({
			...item,
			coordinates: item.coordinates.map((element) => element * xRatio),
		}));

		setMapState(newMappingList);
	};

	const standsList = [stand1, stand2, stand3, stand4];

	return (
		<>
			<StyledDiv>
				<Wrapper>
					<RightButton onClick={() => history.push(`/girisimler/${id === "3" ? 0 : Number(id) + 1}`)}>
						<Chevron style={{ transform: "scaleX(-1)" }} src={chevron} alt="chevron-right" />
					</RightButton>

					<LeftButton onClick={() => history.push(`/girisimler/${id === "0" ? 3 : Number(id) - 1}`)}>
						<Chevron src={chevron} alt="chevron-left" />
					</LeftButton>
					<div style={{ overflow: "hidden", display: "flex" }}>
						<StandImage
							src={standsList[id]}
							alt="background"
							useMap="#image-map"
							ref={imageRef}
							onLoad={onImageLoaded}
						/>
						<map name="image-map">
							{mapState.map((item, index) => (
								<area
									key={index}
									style={{ cursor: "pointer" }}
									target=""
									alt={`map_${index + 22 * id}`}
									onClick={() => history.push(`/girisim/${item.url + 22 * id}`)}
									coords={item.coordinates.map(String)}
									shape="poly"
								/>
							))}
						</map>
					</div>
					<div style={{ width: "100%", height: "87vh", overflow: "hidden", position: "absolute" }}>
						<BackgroundImage src={bg} alt="background" x={x} y={y} scale="1500" />
					</div>
					<div style={{ width: "100%", height: "87vh", display: "flex", alignItems: "center", overflow: "hidden" }}>
						<CityImage src={city} alt="background-city" x={x} y={y} scale="500" />
					</div>
				</Wrapper>
			</StyledDiv>
		</>
	);
}
