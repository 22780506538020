import React from "react";
import styled from "styled-components/macro";

const Container = styled.div`
	width: 100%;
`;

export default function AdminWrapper({ children, ...restProps }) {
	return <Container {...restProps}>{children}</Container>;
}
