import styled from "styled-components/macro";
import { motion } from "framer-motion";
import { StepLabel } from "@material-ui/core";

export const Wrapper = styled(motion.div)`
	width: 50%;
	min-width: 200px;
	padding: 5vh 3% 5vh;
	border: solid 2px
		${({ color }) => {
			if (color === "success") return "#39b81a";
			else if (color === "error") return "#b50505";
			else return "#fffe4b";
		}};
	border-radius: 2px;
	box-shadow: 0 0 20px rgb(0, 0, 0, 0.5);
	background-color: #202326;
	color: white;
	z-index: 12;
	overflow-y: scroll;

	@media (max-width: 1024px) {
		max-height: 80vh;
		width: 80%;
	}
`;

export const Label = styled.label`
	font-size: 26px;
	margin-bottom: 2vh;
`;

export const SmallLabel = styled.label`
	color: #ddd;
	font-size: 16px;
`;

export const Input = styled.input`
	padding: 1vh 1vw 1vh;
	border: solid 1px #333d40;
	border-radius: 5px;
	height: 2em;
	background-color: #202326;
	color: white;
	transition: 0.3s;
	font-size: 16px;
	margin: 1vh 0 1vh;

	&:focus {
		outline: none;
		border: solid 1px #7b8787;
	}
`;

export const ReleaseWrapper = styled.div`
	margin: 2vh 0 2vh;
`;

export const ButtonWrapper = styled.div`
	margin-top: 1vh;
	display: flex;
	justify-content: center;
`;

export const Button = styled.button`
	color: white;
	background-color: ${({ button }) => (button === "giris" ? "rgba(0,0,0,0)" : "red")};
	border: ${({ button }) => (button === "giris" ? "solid 2px white" : "none")};
	min-width: 6vw;
	width: 20vw;
	height: 5vh;
	border-radius: 3px;
	box-shadow: 0 0 20px rgba(0, 0, 0, 0.7);
	transition: 0.3s;
	margin-top: 1vh;

	&:hover {
		box-shadow: 0 0 10px rgba(0, 0, 0, 1);
	}
	@media (max-width: 1024px) {
		width: 60%;
	}
	@media (max-height: 667px) {
		width: 60%;
		margin-top: 20px;
		font-size: 24px;
		height: 10vh;
	}
`;

export const RadioButton = styled.input`
	margin-right: 0.7vw;
	transform: scale(1.5);
`;

export const CloseButton = styled.button`
	margin-left: auto;
	display: flex;
	justify-content: center;
	border: solid 2px white;
	border-radius: 50%;
	height: 36px;
	width: 36px;
	align-items: center;
	background-color: #202326;
	color: white;
	transition: 0.3s;
	&:hover {
		transform: scale(1.05);
	}
	&:active {
		transform: scale(0.9);
	}
	&:focus {
		outline: none;
	}
`;

export const Loading = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	min-height: 100%;
	padding: 10%;
	pointer-events: none;
`;

export const Success = styled.div`
	color: #39b81a;
	display: flex;
	justify-content: center;
	flex-direction: column;
	align-items: center;
	height: 100%;
	text-align: center;
`;

export const Error = styled.div`
	color: #b50505;
	display: flex;
	justify-content: center;
	flex-direction: column;
	align-items: center;
	height: 100%;
`;
