import React from "react";
import { useMediaQuery } from "react-responsive";
import { NavBoxContainer, GirisimciContainer } from "../containers";

export default function Girisimci({
	setCurrentNav,
	setIsPopupActive,
	setPopupVideo,
	popupVideo,
	setMailOpen,
	setKartOpen,
	handleEmailPopupClick,
}) {
	const isMobile = useMediaQuery({ query: "(max-width: 823px)" });
	return (
		<>
			{!isMobile && <NavBoxContainer setCurrentNav={setCurrentNav} />}
			<GirisimciContainer
				handleEmailPopupClick={handleEmailPopupClick}
				setMailOpen={setMailOpen}
				setKartOpen={setKartOpen}
				setIsPopupActive={setIsPopupActive}
				popupVideo={popupVideo}
				setPopupVideo={setPopupVideo}
			/>
		</>
	);
}
