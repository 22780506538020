import React, { useContext, useEffect, useRef, useState } from "react";
import { FormComponent } from "../components";
import { Formik, Field, Form } from "formik";
import { UserContext } from "../context/UserContext";
import ReactLoading from "react-loading";
import { useHistory } from "react-router-dom";
import { CheckBoxContainer } from "../containers";
import * as Yup from "yup";
import close from "../images/close.png";

const initial = { opacity: 0, y: 5 };
const exit = { opacity: 0, y: 5 };
const animate = { opacity: 1, y: 0 };
const transition = { duration: 0.3, ease: [0.43, 0.13, 0.23, 0.96] };

export default function Signup({
    redirectTo,
    shouldChangeURL,
    setCurrentNav,
    setSignUpClick,
    setSignInClick,
    setIsPopupActive,
    children,
    ...restProps
}) {
    const history = useHistory();
    const { register } = useContext(UserContext);
    const ref = useRef(null);
    const FORM_STATE = { SUCCESS: 0, ERROR: 1, HOLD: 2 };
    const [isSuccess, setIsSuccess] = useState(FORM_STATE.HOLD);

    useEffect(() => {
        setIsPopupActive(true);
    }, [setIsPopupActive]);

    useEffect(() => {
        function handleClickOutside(event) {
            if (event.target === ref.current) {
                if (shouldChangeURL) {
                    setIsSuccess(FORM_STATE.HOLD);
                    setCurrentNav("");
                    history.push(redirectTo);
                }
                setSignUpClick(false);
                setSignInClick(false);
                setIsPopupActive(false);
            }
        }
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    });

    const handleSubmit = (data, { setSubmitting, setErrors }) => {
        setSubmitting(true);
        register(data.name, data.email, data.password)
            .then((data) => {
                if (data.username) {
                    setIsSuccess(FORM_STATE.SUCCESS);
                    setSignUpClick(false);
                    setIsPopupActive(false);
                    setCurrentNav("");
                    history.push("/");
                } else {
                    setIsSuccess(FORM_STATE.ERROR);
                    setErrors(data);
                }
                setSubmitting(false);
            })
            .catch((error) => {
                alert(error);
                setSubmitting(false);
            });
    };

    const SignupSchema = Yup.object().shape({
        name: Yup.string()
            .min(3, "Ad veya soyad 2 karakterden az olamaz!")
            .required("Devam etmek için isminizi giriniz!"),
        email: Yup.string().email("Geçersiz email!").required("Devam etmek için bir mail adresi giriniz!"),
        password: Yup.string()
            .required("Şifre girmeniz zorunludur.")
            .min(8, "Şifreniz çok kısa - şifreniz en az 8 karakterden oluşmalı."),
        confirmPassword: Yup.string().oneOf([Yup.ref("password"), null], "Şifreniz eşleşmeli."),
    });

    function getForm() {
        return (
            <Formik
                initialValues={{ name: "", email: "", password: "", confirmPassword: "" }}
                validationSchema={SignupSchema}
                onSubmit={handleSubmit}
            >
                {({ isSubmitting, errors, touched }) => (
                    <div
                        ref={ref}
                        style={{
                            width: "100vw",
                            height: "100vh",
                            position: "absolute",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            top: 0,
                            zIndex: 11,
                            backgroundColor: "rgba(0,0,0,0.8)",
                        }}
                    >
                        <FormComponent
                            initial={initial}
                            animate={animate}
                            exit={exit}
                            transition={transition}
                            type="signup"
                        >
                            {!isSubmitting ? (
                                <Form style={{ display: "flex", flexDirection: "column" }}>
                                    <div style={{ display: "flex", alignItems: "center", marginBottom: "10px" }}>
                                        <FormComponent.Label style={{ marginBottom: 0, width: "300px" }}>
                                            KAYIT OL
                                        </FormComponent.Label>
                                        <div style={{ width: "100%" }}>
                                            <FormComponent.CloseButton
                                                type="button"
                                                onClick={() => {
                                                    setSignUpClick(false);
                                                    setIsPopupActive(false);
                                                    history.push(redirectTo);
                                                    setCurrentNav("");
                                                }}
                                            >
                                                <img src={close} alt="close" style={{ width: "22px" }} />
                                            </FormComponent.CloseButton>
                                        </div>
                                    </div>
                                    <Field
                                        spellCheck="false"
                                        placeholder="Ad ve Soyad..."
                                        name="name"
                                        type="text"
                                        as={FormComponent.Input}
                                    />
                                    {errors.name && touched.name ? (
                                        <div style={{ color: "red" }}>{errors.name}</div>
                                    ) : null}
                                    <Field
                                        spellCheck="false"
                                        placeholder="Email..."
                                        name="email"
                                        type="email"
                                        as={FormComponent.Input}
                                    />
                                    {errors.email && touched.email ? (
                                        <div style={{ color: "red" }}>{errors.email}</div>
                                    ) : null}
                                    {errors.message ? <div style={{ color: "red" }}>{errors.message}</div> : null}

                                    <Field
                                        spellCheck="false"
                                        placeholder="Şifre..."
                                        name="password"
                                        type="password"
                                        as={FormComponent.Input}
                                    />
                                    {errors.password && touched.password ? (
                                        <div style={{ color: "red" }}>{errors.password}</div>
                                    ) : null}

                                    <Field
                                        spellCheck="false"
                                        placeholder="Şifre Onay..."
                                        name="confirmPassword"
                                        type="password"
                                        as={FormComponent.Input}
                                    />
                                    {errors.confirmPassword && touched.confirmPassword ? (
                                        <div style={{ color: "red" }}>{errors.confirmPassword}</div>
                                    ) : null}

                                    <CheckBoxContainer />

                                    <div className="text-center" style={{ color: "#ddd" }}>
                                        Zaten kayıtlı mısınız?
                                        <span
                                            style={{
                                                color: "white",
                                                textShadow: "0 0 1px white, 0 0 1px white, 0 0 1px white",
                                                paddingLeft: "5px",
                                                paddingRight: "5px",
                                                cursor: "pointer",
                                            }}
                                            onClick={() => {
                                                setSignInClick(true);
                                                setIsPopupActive(true);
                                                setSignUpClick(false);
                                            }}
                                        >
                                            Giriş
                                        </span>
                                        yapın.
                                    </div>
                                    <FormComponent.ButtonWrapper>
                                        <FormComponent.Button disabled={isSubmitting} type="submit" button={"uyeol"}>
                                            Kayıt ol
                                        </FormComponent.Button>
                                    </FormComponent.ButtonWrapper>
                                </Form>
                            ) : (
                                <FormComponent.Loading>
                                    <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                                        <ReactLoading type="spin" color="white" height={64} width={64} />
                                        <FormComponent.Label style={{ marginTop: "20px", marginBottom: 0 }}>
                                            İşlem gerçekleştiriliyor...
                                        </FormComponent.Label>
                                    </div>
                                </FormComponent.Loading>
                            )}
                        </FormComponent>
                    </div>
                )}
            </Formik>
        );
    }

    function getBody() {
        switch (isSuccess) {
            case FORM_STATE.SUCCESS:
                return (
                    <div
                        ref={ref}
                        style={{
                            width: "100vw",
                            height: "100vh",
                            position: "absolute",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            top: 0,
                            zIndex: 11,
                            backgroundColor: "rgba(0,0,0,0.8)",
                        }}
                    >
                        <FormComponent style={{ width: "50vw", height: "200px" }} color="success">
                            <FormComponent.Success>
                                <FormComponent.Label style={{ marginBottom: 0 }}>
                                    Kayıt olma işlemi başarılı.
                                </FormComponent.Label>
                            </FormComponent.Success>
                        </FormComponent>
                    </div>
                );
            case FORM_STATE.HOLD:
                return getForm();
            default:
                return (
                    <div
                        ref={ref}
                        style={{
                            width: "100vw",
                            height: "100vh",
                            position: "absolute",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            top: 0,
                            zIndex: 11,
                            backgroundColor: "rgba(0,0,0,0.8)",
                        }}
                    >
                        <FormComponent style={{ width: "50vw", height: "200px" }} color="error">
                            <FormComponent.Error>
                                <FormComponent.Label>İşlem sırasında bir hata oluştu.</FormComponent.Label>
                                <FormComponent.SmallLabel style={{ color: "inherit" }}>
                                    Lütfen tekrar deneyiniz.
                                </FormComponent.SmallLabel>
                            </FormComponent.Error>
                        </FormComponent>
                    </div>
                );
        }
    }

    return getBody();
}
