import React, { useState, useEffect } from "react";
import styled from "styled-components/macro";
import { BsTrashFill } from "react-icons/bs";
import { RiEdit2Fill, RiCheckDoubleFill } from "react-icons/ri";
import { FaCheck } from "react-icons/fa";
import { motion, AnimatePresence } from "framer-motion";
import {
    getPotentialInvestments,
    enableInvestment,
    editInvestment,
    deleteInvestment,
} from "../../../services/investment.service";
import { DataItem } from "./DataItem";

const Wrapper = styled.div`
    width: 100%;
    height: 90vh;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #bbb;
`;

const Table = styled.div`
    width: 90%;
    height: 90%;
    box-sizing: border-box;
    border-radius: 10px;
    background-color: #2d3338;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
`;

const HeaderItem = styled.div`
    display: grid;
    grid-template-columns: repeat(7, 1fr);
`;

const Header = styled.h3`
    padding-left: 10px;
`;

export default function Yatirimlar() {
    const [donationDataState, setDonationDataState] = useState([]);
    const [refreshIndex, setRefreshIndex] = useState(0);

    useEffect(() => {
        getPotentialInvestments().then((data) => {
            setDonationDataState(data);
            console.log(data);
        });
    }, [refreshIndex]);

    const handleSubmit = (idx) => {
        const updatedItem = donationDataState[idx];
        updatedItem.isEnabled = true;
        donationDataState[idx] = updatedItem;
        setDonationDataState([...donationDataState]);
        enableInvestment(donationDataState[idx].id);
    };

    const handleErase = (idx) => {
        deleteInvestment(idx).then((data) => {
            setRefreshIndex((prev) => prev + 1);
        });
    };

    const setEditable = (idx) => {
        console.log(`Set Editable ${idx}`);
        const updatedItem = donationDataState[idx];
        updatedItem.isEditable = !updatedItem.isEditable;
        setDonationDataState([...donationDataState]);
    };

    const handleEdit = (idx, startupName, investorName, amount, investmentType, investmentTarget) => {
        console.log(`Handle Edit ${idx}`);
        const updatedItem = donationDataState[idx];
        updatedItem.isEditable = !updatedItem.isEditable;
        setDonationDataState([...donationDataState]);
        editInvestment(updatedItem.id, { startupName, investorName, amount, investmentType, investmentTarget }).then(
            (data) => {
                setRefreshIndex((prev) => prev + 1);
            }
        );
    };

    return (
        <Wrapper>
            <Table>
                <HeaderItem>
                    <Header>ID</Header>
                    <Header>From</Header>
                    <Header>To</Header>
                    <Header>Miktar</Header>
                    <Header>Yatırım Tipi</Header>
                    <Header>Hedef Tipi</Header>
                    <Header>Actions</Header>
                </HeaderItem>
                {donationDataState.map((donation, idx) =>
                    donation.investorName === "_" ? null : (
                        <DataItem
                            handleSubmit={handleSubmit}
                            handleEdit={handleEdit}
                            handleErase={handleErase}
                            setEditable={setEditable}
                            donation={donation}
                            idx={idx}
                        />
                    )
                )}
            </Table>
        </Wrapper>
    );
}
