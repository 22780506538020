import styled from "styled-components/macro";

export const InvitationDiv = styled.div`
	font-family: "Teko", sans-serif;
	display: flex;
	justify-content: center;
	align-items: center;
	height: 1vh;
	font-size: 25px;
	color: #888;
	background-color: #f2f2f2;

	padding: 1vh;
`;
