import styled from "styled-components/macro";

export const NavbarContainer = styled.div`
	width: 100%;
	height: 10vh;
	background-color: #7d1f14;
	display: flex;
	align-items: center;
	box-shadow: 0 0 5px black;
`;

export const Logo = styled.img`
	width: 120px;
	margin: 10px 0 10px 30px;
`;

export const LogOut = styled.p`
	display: flex;
	align-items: center;
	color: white;
	margin-left: 40px;
	cursor: pointer;
	border-radius: 5px;
	padding: 4px 10px 4px;
	border: solid 2px white;

	transition: 0.2s;

	&:hover {
		box-shadow: 0 0 5px white;
	}

	&:active {
		box-shadow: none;
		border-color: #999;
		color: #999;
	}
`;

export const Item = styled.div`
	display: flex;
	align-items: center;
	margin-left: 40px;
	color: white;
	cursor: pointer;

	transition: 0.3s;

	&:hover {
		text-shadow: 0 0 1px white, 0 0 1px white;
	}

	&:active {
		text-shadow: none;
		color: #999;
	}
`;

export const RightAlign = styled.div`
	margin-left: auto;
	margin-right: 30px;
	display: flex;
	align-items: center;
`;
