import { getAuthorized, postAuthorized, get, deleteAuthorized, postAuthorizedForm } from "../helpers/fetch.helpers";

export const getPotentialInvestments = () => {
    return getAuthorized("investment/all").then((response) => response.json());
};

export const addInvestment = async ({ startupName, investorName, amount, investmentType, investmentTarget }) => {
    return postAuthorized(
        { startupName, investorName, amount, investmentType, investmentTarget },
        "investment/add"
    ).then((response) => response.json());
};

export const enableInvestment = async (id) => {
    return postAuthorized({}, `investment/enable?id=${id}`).then((response) => response.json);
};

export const editInvestment = async (id, { startupName, investorName, amount, investmentType, investmentTarget }) => {
    console.log(`Id: ${id}, ${startupName} ${investorName} ${amount} ${investmentType} ${investmentTarget}`);
    return postAuthorized(
        { startupName, investorName, amount, investmentType, investmentTarget },
        `investment/edit?id=${id}`
    ).then((response) => response.json());
};

export const deleteInvestment = async (id) => {
    return deleteAuthorized(`investment/delete?id=${id}`).then((response) => response.json());
};

export const getAllInvestments = async (isEnabled) => {
    return get(`investment/all?isEnabled=${isEnabled}`).then((response) => (response.ok ? response.json() : {}));
};

export const getAllInvestmentsTimed = async (isEnabled) => {
    return get(`investment/allTimed?isEnabled=${isEnabled}`).then((response) => (response.ok ? response.json() : {}));
};

export const sendMail = async (to, subject, message) => {
    const test = {
        Receiver: to,
        Title: subject,
        Content: message,
    };
    const response = await postAuthorized(test, "startup/sendMail");
    return await response.json();
};

export const sendCard = async (receiver, name, unvan, phone, mail) => {
    const testData = {
        Receiver: receiver,
        Title: name,
        CardTitle: unvan,
        CardPhone: phone,
        CardMail: mail,
    };
    const response = await postAuthorized(testData, "startup/sendCard");
    return await response.json();
};
