import React, { useContext } from "react";
import { Redirect, Route, useHistory } from "react-router-dom";
import { UserContext } from "../context/UserContext";

export function IsUserRedirect({ loggedInPath, children, ...restProps }) {
	const { logged } = useContext(UserContext);

	return (
		<Route
			{...restProps}
			render={() => {
				if (!logged) {
					return children;
				} else {
					return <Redirect to={{ pathname: loggedInPath }} />;
				}
			}}
		/>
	);
}

export function ProtectedRoute({ children, ...restProps }) {
	const { logged } = useContext(UserContext);
	const history = useHistory();
	return (
		<Route
			{...restProps}
			render={({ location }) => {
				if (logged) {
					return children;
				} else {
					// figure out hwhy redirect to isnt working
					history.push("/");
				}
			}}
		/>
	);
}
