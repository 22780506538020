import styled from "styled-components/macro";

export const SideWaysWarnMessage = styled.div`
	height: 100%;
	color: white;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	font-size: 56px;
	text-align: center;
	padding-right: 5vw;
	padding-left: 5vw;

	@media (max-width: 411px) {
		font-size: 36px;
	}
`;
