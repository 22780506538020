import React from "react";
import { Container, Logo, ItemContainer, Label, SmallContainer, ChatLogo, LogOut, ChatContainer } from "./styles";
import bigLogo from "../../images/bigbang_vektorel_logo.png";
import smallLogo from "../../images/bigbang_logo.png";

export default function NavBar({ children, ...restProps }) {
	return <Container {...restProps}>{children}</Container>;
}

NavBar.ChatContainer = function NavBarChatContainer({ children, ...restProps }) {
	return <ChatContainer {...restProps}>{children}</ChatContainer>;
};

NavBar.ItemContainer = function NavBarItemContainer({ children, ...restProps }) {
	return <ItemContainer {...restProps}>{children}</ItemContainer>;
};

NavBar.SmallContainer = function NavBarSmallContainer({ children, ...restProps }) {
	return <SmallContainer {...restProps}>{children}</SmallContainer>;
};

NavBar.Logo = function NavBarLogo({ ...restProps }) {
	return <Logo src={bigLogo} {...restProps} />;
};

NavBar.ChatLogo = function NavBarChatLogo({ children, ...restProps }) {
	return <ChatLogo src={bigLogo} {...restProps} />;
};

NavBar.Label = function NavBarLabel({ children, ...restProps }) {
	return <Label {...restProps}>{children}</Label>;
};

NavBar.LogOut = function NavBarLogOut({ children, ...restProps }) {
	return <LogOut {...restProps}>{children}</LogOut>;
};
